<template>
    <div :style="style">
        <router-view></router-view>
    </div>
</template>

<script setup lang="ts">
    import { ref, reactive, onMounted } from 'vue'
    import {onResize} from "../../..";

    const style = ref<{
        height: string
    }>({
        height: '0'
    });

    onMounted(() => {
        computedViewHeight();
        // 注册视图大小改变事件
        onResize(() => {
            computedViewHeight();
        })
    });

    // 计算视图高度
    function computedViewHeight() {
        style.value.height = `${document.documentElement.clientHeight || document.body.clientHeight}px`;
    }


</script>

<style lang="scss">
    body {
        height: 100%;
    }
</style>
