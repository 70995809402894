<template>
  <template v-if="dictData">
    <span v-if="dictData.isTag">{{ dictData.value }}</span>
    <div v-else class="tag" :style="dictData.tagStyle">
      {{ dictData.value }}
    </div>
  </template>
  <!--<dict-tag type="TicketState" code="0"></dict-tag>-->
</template>

<script setup lang="ts">
// import { defineProps, nextTick, computed } from "vue";
import { nextTick, computed } from "vue";
import { initDict } from "./InitDictUtil";
import { useStore } from "../../..";

const store: any = useStore();

const dict = store.dict;

const props = defineProps<{
  type: string;
  code: any;
}>();

const dictData = computed(() => {
  const di = dict.values[props.type] && dict.values[props.type][props.code];
  if (di) {
    typeof di.tagStyle === "string" && (di.tagStyle = JSON.parse(di.tagStyle));
    console.log(di.tagStyle.background);
    if (di.tagStyle.background == "rgba(50, 201, 4, 1)") {
      di.tagStyle.background = "#dcf5e7";
      di.tagStyle.color = "#205237";
      di.tagStyle.border = "1px solid #45ba7b";
    } else if (di.tagStyle.background == "rgba(241, 56, 35, 1)") {
      di.tagStyle.background = "#fcd9e2";
      di.tagStyle.color = "#912741";
      di.tagStyle.border = "1px solid #fb013e85";
    } else if (di.tagStyle.background == "rgba(0, 206, 209, 1)") {
      di.tagStyle.background = "#ccecfd";
      di.tagStyle.color = "#005f94";
      di.tagStyle.border = "1px solid #3bb1f4fc";
    } else if (di.tagStyle.background == "rgba(9, 53, 248, 1)") {
      console.log(111, di.tagStyle.background);
      di.tagStyle.background = "#a08cff5c";
      di.tagStyle.color = "#6020ff";
      di.tagStyle.border = "1px solid #6020ff";
    } else if (di.tagStyle.background == "rgba(30, 55, 245, 1)") {
      console.log(111, di.tagStyle.background);
      di.tagStyle.background = "#ffc5a491";
      di.tagStyle.color = "#e45d12";
      di.tagStyle.border = "1px solid #ff5d00";
    } else if (di.tagStyle.background == "rgba(2, 72, 222, 1)") {
      di.tagStyle.background = "#ff474733";
      di.tagStyle.color = "#ff1414";
      di.tagStyle.border = "1px solid #ff1414";
    } else if (di.tagStyle.background == "rgba(1, 132, 255, 1)") {
      di.tagStyle.background = "#D4FFC9";
      di.tagStyle.color = "#15B45D";
      di.tagStyle.border = "1px solid #16A700B4";
    } else if (di.tagStyle.background == "rgba(4, 154, 200, 1)") {
      di.tagStyle.background = "#C9E9FF";
      di.tagStyle.color = "#2F61F8";
      di.tagStyle.border = "1px solid #1368F0";
    }
    else if (di.tagStyle.background == "rgba(0, 151, 215, 1)") {
      di.tagStyle.background = "#F5F4B3";
      di.tagStyle.color = "#949328";
      di.tagStyle.border = "1px solid #C4C100";
    }
    return di;
  } else {
    return null;
  }
});

nextTick(() => {
  initDict([props.type]);
});
</script>

<style scoped lang="scss">
.tag {
  min-width: 110px;
  display: inline-block;
  padding: 2px 7px;
  border-radius: 4px;
  margin-right: 10px;
  text-align: center;
}
</style>
