<template>
    <el-drawer
            v-model="showF12"
            size="95%"
            append-to-body
            close-on-click-modal
            close-on-press-escape
            :with-header="false"
            :z-index="99999"
            custom-class="verdure-f12"
            :title="$t('console.title')">
        <el-tabs
                v-model="activeName"
                @tab-click="clickTab"
                type="card"
        >
            <el-tab-pane label="控制台" name="console">
                <div class="console">
                    <div class="operate">
                        <el-button size="mini" type="primary" @click="ConsoleData.clearConsole">
                            清空控制台
                        </el-button>
                    </div>
                    <el-scrollbar style="height: calc(100vh - 140px)">
                        {{ useConfig.consoleLogs }}
                        <div v-for="item in useConfig.consoleLogs" class="display log-item">
                            <div class="level" :class="`le-${item.level}`">
                                {{ ConsoleData.logLevel[item.level] || '信息' }}
                            </div>
                            <div class="content">
                                <div class="title">{{ item.title }}</div>
                                <template v-if="item.content">
                                    {{ item.content.constructor === String ? item.content : item.content.content}}
                                </template>
                            </div>
                        </div>
                    </el-scrollbar>
                </div>
            </el-tab-pane>
            <el-tab-pane label="系统视图" name="systemView">
                <vxe-table
                        border
                        :height="mainHeight - 100"
                        size="mini"
                        :data="systemView.result">
                    <vxe-column field="code" title="标识" width="130px"></vxe-column>
                    <vxe-colgroup title="视图配置（为空的视图路由会出现 404 异常，请注意是否注入视图）">
                        <vxe-column field="viewName" title="名称" width="150px"></vxe-column>
                        <vxe-column field="viewType" title="类型" width="80px" align="center"></vxe-column>
                        <vxe-column field="viewScope" title="渲染等级" width="80px" align="center"></vxe-column>
                        <vxe-column field="component" title="视图组件" min-width="150px"></vxe-column>
                    </vxe-colgroup>
                    <vxe-colgroup title="路由配置（为空的路由当前账号无权限访问）">
                        <vxe-column field="routerName" title="名称" width="150px"></vxe-column>
                        <vxe-column field="routerPath" title="访问地址" min-width="120px"></vxe-column>
                        <vxe-column field="routerScope" title="渲染等级" width="80px" align="center"></vxe-column>
                    </vxe-colgroup>
                </vxe-table>
            </el-tab-pane>
        </el-tabs>
    </el-drawer>
</template>

<script setup lang="ts">
    import {$t, useVueRouter, useSystemRouterMap, useViews, isNullAndNew, onResize } from "@verdure/core"
    import { ref, Ref } from 'vue'
    import {F12Config, ViewCallback} from "../../../index";

    const showF12 = ref(false);
    const useConfig = ref({} as F12Config);
    const activeName = ref('console');
    const mainHeight:Ref<number> = ref(0);

    ViewCallback((config: F12Config) => {
        showF12.value = true;
        useConfig.value = config
    });

    function click(code: number) {
        switch (code) {
            // case 1: console.log(useVueRouter()); break;
            // case 2: console.log(useSystemRouter()); break;
        }
    }

    const ConsoleData: any = new (
        class Console {
            logLevel: {
                system: string
            } = {
                system: '系统'
            };

            // 清空控制台
            clearConsole(): void {
                useConfig.value.consoleLogs = [];
            }
        }
    );

    const systemView = new (
        class SystemView {
            result: any[];
            constructor() {
                this.result = []
            }
            // 获取路由配置
            init() {
                const routerMap: Record<string, {
                    scope: number,
                    router: any
                }> = useSystemRouterMap();
                const views: Record<string, any> = useViews();
                const result: Record<string, any> = {};
                for (let rouPath in routerMap) {
                    const router = routerMap[rouPath];
                    const route = router.router;
                    const code = route.code;
                    const view = views[code];
                    isNullAndNew(result, code, []);
                    const data: any = {
                        routerName: route.name,
                        id: route.id,
                        routerPath: rouPath,
                        routerScope: router.scope
                    };
                    if (view) {
                        data.viewName = view.name;
                        data.viewType = view.type;
                        data.viewScope = view.scope;
                        data.component = view.component.toString();
                    }
                    result[code].push(data)
                }
                for (let viewCode in views) {
                    if (result[viewCode] === undefined) {
                        isNullAndNew(result, viewCode, []);
                        result[viewCode].push({
                            viewName: views[viewCode].name,
                            viewType: views[viewCode].type,
                            viewScope: views[viewCode].scope,
                            component: views[viewCode].component.toString()
                        })
                    }
                }
                const res: any[] = []
                for (let code in result) {
                    for (let index in result[code]) {
                        res.push({
                            code,
                            ...result[code][index]
                        })
                    }
                }
                this.result = res;
            }
        }
    );

    onResize(() => {
        computedHeight();
    });
    computedHeight();

    function computedHeight() {
        mainHeight.value = (document.documentElement.clientHeight || document.body.clientHeight - 200);
    }

    function clickTab(tab: any) {
        switch (tab.paneName) {
            case "systemView":
                systemView.init();
                break
        }
    }
</script>

<style lang="scss">
    .verdure-f12 {
        .console{
            .operate {
                margin-bottom: 10px  ;
            }
            .log-item {
                margin-bottom: 5px;
                padding-bottom: 5px;
                border-bottom: 1px #CCC solid;
                &:last-child {
                    border-bottom: none;
                }
                .level {
                    width: 50px;
                    flex-shrink: 0;
                    justify-content: center;
                    display: flex;
                    margin-right: 10px;
                    border-radius: 8px;
                }
                .content {
                    width: 100%;
                    .title {
                        font-weight: bold;
                        border-bottom: 1px #CCC dashed;
                        padding-bottom: 5px;
                        margin-top: 5px;
                    }
                }
                .le-system {
                    background-color: #3983a1;
                    color: #fff;
                }
                .le-info {
                    background-color: #909da1;
                    color: #fff;
                }
            }
        }
    }
</style>
