import { useStore } from "../../..";
import {apiDict} from "../../../api/api";

/**
 * 初始化数据字典
 * @param types 类型列表
 */
export function initDict(types: string[] = []) {
    const store = useStore();
    const dict: Record<string, any> = store.dict;
    const data = dict.dict;
    types.forEach(type => {
        if (data[type] === undefined) {
            apiDict.dict({ type }).then(data => {
                dict.values[type] = data[type]; // 环志值与字典对应关系
                dict.dict[type] = [];
                dict.labels[type] = {};
                for (let ket in dict.values[type]) {
                    const item = dict.values[type][ket];
                    // 缓存列表
                    dict.dict[type].push(item);
                    dict.labels[type][item.value] = item;
                }
            })
        }
    })
}
