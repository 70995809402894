<template>
  <vxe-modal
    v-model="show"
    :width="width"
    show-footer
    :size="size.vxeTable"
    :class-name="modalClassName"
    :lock-view="lockView"
    :show-zoom="showZoom"
    :mask="mask"
    :esc-closable="escCloseable"
    :z-index="zIndex"
    draggable
    destroy-on-close
    :isFile="isFile"
    :closeModal="closeModal"
    :before-hide-method="beforeCloseable"
  >
    <template #title>
      <span :class="titleClass">{{ modalTitle }}</span>
    </template>
    <template #footer>
      <el-button
        :size="size.elementPlus"
        type="info"
        plain
        @click="clickCancel"
      >
        <fa icon="xmark" />&nbsp;
        {{ $t("formModal.cancel") }}
      </el-button>
      <el-button
        :size="size.elementPlus"
        type="primary"
        @click="clickSubmit"
        :disabled="submitLoading"
      >
        <fa icon="fcheck" />&nbsp;
        <template v-if="submitLoading">
          <el-icon><Loading /></el-icon>&nbsp;
        </template>
        {{ $t("formModal.submit") }}
      </el-button>
    </template>
    <template #default>
      <el-form
        ref="formIns"
        :model="form"
        :label-width="labelWidth"
        :rules="rules"
        :key="formKey"
      >
        <slot :form="form"></slot>
      </el-form>
    </template>
  </vxe-modal>
</template>

<script setup lang="ts">
import {
  ref,
  // defineProps,
  // withDefaults,
  watch,
  // defineExpose,
  Ref,
  // defineEmits,
  nextTick,
} from "vue";
import { $t, Result, TableCardInstance, useConfigKey } from "../../..";
import { FormRules, ElNotification } from "element-plus";
import { Loading } from "@element-plus/icons-vue";
import { submitForm } from "../../..";
import { VxeModalPropTypes } from "vxe-table";

const size = ref(useConfigKey("core").size);

const formIns = ref(null);
const tableRef = ref({} as TableCardInstance);

const formKey = ref(0);
const show = ref(false);
const submitLoading = ref(false);
const modalTitle = ref("");
const form: Ref<Record<string, any>> = ref({});
const props = withDefaults(
  defineProps<{
    isFile: boolean;
    closeModal: boolean;
    title: string; // ----------------------------------------------------------------------- 弹窗标题
    width: string; // ----------------------------------------------------------------------- 弹窗宽度
    showForm: boolean; // ------------------------------------------------------------------- 显示弹窗
    labelWidth: string; // ------------------------------------------------------------------ 弹窗表单的表单项宽度
    titleClass: string; // ------------------------------------------------------------------ 弹窗标题样式类名
    modalClassName: string; // ---------------------------------------------------------------弹窗样式类名
    lockView: boolean; // ------------------------------------------------------------------- 是否锁住界面，不允许弹窗外其他操作
    mask: boolean; // ----------------------------------------------------------------------- 是否显示遮罩层
    escCloseable: boolean; // --------------------------------------------------------------- 是否按ESC退出弹窗
    showZoom: boolean; // ------------------------------------------------------------------- 标题是否标显示最大化与还原按钮
    zIndex: number; // ---------------------------------------------------------------------- 层级
    rules: Ref<FormRules>; // --------------------------------------------------------------- 校验规则
    beforeCloseable: VxeModalPropTypes.BeforeHideMethod; // --------------------------------- 关闭弹窗前的回调
    reloadForm: () => any; // ---------------------------------------------------------------- 重新加载表单
    apiInsert: (data: any) => Promise<Result<any>>; // -------------------------------------- 添加接口
    apiUpdate: (data: any) => Promise<Result<any>>; // -------------------------------------- 修改接口
    beforeSave: (data: any) => void; // ----------------------------------------------------- 提交前的回调
    beforeInitForm: (row: any) => void; // -------------------------------------------------- 初始化表单之前的回调
    afterShowForm: (form: any) => void; // -------------------------------------------------- 在显示表单前的操作
  }>(),
  {
    showForm: false,
    lockView: true,
    mask: true,
    labelWidth: "100px",
    escCloseable: false,
    showZoom: true,
    zIndex: 2000,
    reloadForm: () => {},
    beforeInitForm: () => {},
    afterShowForm: () => {},
  }
);

const emits = defineEmits<{
  (event: "insert-done"): void;
  (event: "update-done"): void;
}>();

watch([() => props.showForm], () => {
  show.value = props.showForm;
});
watch([() => props.title], () => {
  modalTitle.value = props.title || $t("formModal.hint.modal");
});

/**
 * 点击取消
 */
function clickCancel() {
  show.value = false;
  form.value = props.reloadForm() || {};
}

/**
 * 提交事件
 */
function clickSubmit() {
  submitLoading.value = true;
  console.log(3,props.isFile,props.closeModal);
  try {
    props.beforeSave && props.beforeSave(form.value);
    const res = submitForm(formIns);
    !props.isFile &&
      res &&
      res
        .then(() => {
          if (form.value.id) {
            props
              .apiUpdate(form.value)
              .then((data: Result<any>) => {
                submitLoading.value = false;
                ElNotification.success($t("formModal.hint.editSuccess"));
                show.value = false;
                emits("update-done");
                tableRef.value.initListData && tableRef.value.initListData();
              })
              .catch((error: any) => {
                console.log($t("formModal.hint.editError"), error);
                submitLoading.value = false;
                ElNotification.error(
                  $t("formModal.hint.editError") + error.msg
                );
              });
          } else {
            props
              .apiInsert(form.value)
              .then((data: Result<any>) => {
                submitLoading.value = false;
                ElNotification.success($t("formModal.hint.insertSuccess"));
                show.value = false;
                emits("insert-done");
                tableRef.value.initListData && tableRef.value.initListData();
              })
              .catch((error: any) => {
                console.log($t("formModal.hint.insertError"), error);
                submitLoading.value = false;
                ElNotification.error(
                  $t("formModal.hint.insertError") + error.msg
                );
              });
          }
        })
        .catch((error) => {
          submitLoading.value = false;
        });
      console.log(3,props.isFile,props.closeModal);
    if (props.isFile &&props.closeModal) {
      console.log(props.isFile,props.closeModal);
      show.value = false;
    }
  } catch (e) {
    submitLoading.value = false;
  }
}

defineExpose({
  showForm: (title: string): void => {
    show.value = true;
    modalTitle.value = title;
    // 重载表单对象
    form.value = props.reloadForm();
    props.afterShowForm(form.value);
  },
  initForm: (row: any): void => {
    const editDorm = JSON.parse(JSON.stringify(row));
    props.beforeInitForm && props.beforeInitForm(editDorm);
    form.value = editDorm;
  },
  setForm(key: string, value: any) {
    form.value[key] = value;
  },
  connect: (ref: TableCardInstance): void => {
    tableRef.value = ref;
  },
});
</script>

<style>
.vxe-modal--header {
  display: flex;
}
</style>
