import {defineStore} from "pinia";

export default defineStore('core', {
    state: () => {
        return {
            showToLogin: false
        }
    },
    getters: {},
    actions: {}
})
