import {verdureCreateDone, defineModuleCreator} from "@verdure/core";
import { App } from 'vue';

import QrcodeVue from 'qrcode.vue'

import vueQr from 'vue-qr/src/packages/vue-qr.vue'
import {VerdureImage} from './components/verdure-image/index'

export default defineModuleCreator<any>({
    id: 'coupon-platform',
    enhanceVue(vue: any) {
        vue.component("qrcode-vue", QrcodeVue);
        vue.component("vueQr ", vueQr);
        vue.component("verdure-image", VerdureImage);
    }
})
