import { defineI18nModuleCreator } from "@verdure/core";

export default defineI18nModuleCreator<{
    minapp: string,
    initError: string,
    saveSuccess: string,
    saveError: string
}>('weChatConfig', {
    "zh-CN": {
        minapp: '微信小程序',
        initError: '配置加载失败',
        saveSuccess: '保存成功',
        saveError: '保存失败'
    }
})
