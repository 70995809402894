import {verdureCreateDone, defineModuleCreator} from "@verdure/core";
import { ref, Ref } from 'vue';
import {ConsoleLog, ConsoleLogLevel} from "./typings/index";
import viewSlot from './slot'

type SystemLog = {
    code?: string,
    content?: any
    title: string // 标题
    color?: string // 颜色
    background?:string // 背景颜色
}

export interface F12Config {
    init: boolean,
    initView: (config: F12Config) => void,
    consoleLogs: ConsoleLog[]
}

const initF12Config: Ref<F12Config> = ref({
    init: false, // 默认不初始化，节省加载速度,
    initView: (config: F12Config) => {},
    consoleLogs:[]
});

window.console.log = function( type: ConsoleLogLevel,
                               content: SystemLog) {
    initF12Config.value.consoleLogs.push({
        content: content ? content : type,
        title: content === undefined ? '日志' : content.title,
        level: content === undefined ? 'info' : type
    });
    console.info(type, content || '')
};

// window.logger = {
//     info(content: SystemLog) {

//     },
//     system(content: SystemLog) {
//         initF12Config.value.consoleLogs.push({
//             content: JSON.stringify(content),
//             title: content.title,
//             level: 'system'
//         });
//     }
// };

export const ViewCallback = (fun : (config: F12Config) => void) => {
    initF12Config.value.initView = fun;
};

export default defineModuleCreator<any>({
    id: 'pluginsConsole',
    viewSlot,
    enhanceVue() {
        console.log('system', { title: '注册插件', content: 'verdure-plugins-console' });
        document.onkeydown = (oEvent: any) => {
            var oEvent = oEvent || (window as any).oEvent;
            //获取ctrl 键对应的事件属性
            var bCtrlKeyCode = oEvent.ctrlKey || oEvent.metaKey;
            // F12
            if(oEvent.keyCode == 123 && bCtrlKeyCode) {
                if (initF12Config.value.init) {
                    initF12Config.value.initView(initF12Config.value);
                } else {
                    // @ts-ignore
                    import('./i18n/index.ts').then(() => {
                        // 加载完毕后才会有初始化函数
                        initF12Config.value.initView(initF12Config.value);
                    }) ;
                    // @ts-ignore
                    import('./slot/index.ts');
                }
            }
        }
    }
})
