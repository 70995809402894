import {
    useVueApp, guid
} from "../..";
import {
    I18NCreator,
    ModuleCreator,
    ModuleCreatorRouterAfterEach,
    ModuleCreatorRouterAfterEachConfig,
    ModuleCreatorRouterBeforeEach,
    ModuleCreatorRouterBeforeEachConfig,
    RouterCreator,
    StoreCreator,
    ViewSlotConfig,
    ViewSlotCreator
} from "../../typings";
import {
    __registerModule,
    __registryAppCreateDone, __registryAxiosBeforeApiConfig,
    __registryConfig, __registryI18ns, __registryRouterAfterSubscriber, __registryRouterBeforeSubscriber,
    __registryStore, __registrySystemRouter, __registryView, __registryViewSlot,
    __useVerdureApp
} from "../data/VerdureData";
import {Module} from "../../entity/VerdureModule";

// 定义模块创建者
export function defineModuleCreator<C>(option: ModuleCreator<C>): () => void {
    return function () {
        // 读取各种回调
        // Verdure 实例创建完成的回调
        __registryAppCreateDone(option.appCreateDone);
        // 创建模块
        const moduleInfo = new Module(option.id);
        // 注册模块
        __registerModule(option.id, moduleInfo);
        // 注册模块配置
        __registryConfig(option.id, option.config as Record<string, any>);
        // 设置路由回调
        setRouterBeforeEach(option.routerBeforeEach);
        setRouterAfterEach(option.routerAfterEach);
        // 读取国际化配置
        readI18nConfig(option.i18n);
        // 读取缓存配置
        readStoreConfig(option.store);
        // 读取路由配置设置路由
        readRouterConfig(option.router);
        // 读取插槽配置并注册
        readViewSlot(option.viewSlot);
        // 读取接口调用配置回调
        option.axiosBeforeApiConfig && __registryAxiosBeforeApiConfig(option.axiosBeforeApiConfig);
        // 调用函数对vue实例进行增强
        const vueApp = useVueApp();
        option.enhanceVue && option.enhanceVue(vueApp);
        // 执行模块注册完成的回调
        option.createDone && option.createDone();
    }
}

/**
 * 读取模块插槽配置并进行注册
 * @param slot
 */
export function readViewSlot(slot: (() => ViewSlotCreator) | undefined) {
    if (slot) {
        const slots = slot();
        for (let viewKey in slots) {
            for (let slotKey in slots[viewKey]) {
                const item = slots[viewKey][slotKey] as ViewSlotConfig;
                if (item.component) {
                    __registryViewSlot(viewKey, slotKey, item);
                } else {
                    __registryViewSlot(viewKey, slotKey, {
                        sort: 0,
                        component: slots[viewKey][slotKey]
                    });
                }
            }
        }
    }
}

/**
 * 处理路由前置操作
 * @param each 配置
 */
function setRouterBeforeEach<C>(each: ModuleCreatorRouterBeforeEach | undefined) {
    // 判断是否配置了路由前置操作
    if (each) {
        if (isRouterListenerBeforeEach(each)) {
            __registryRouterBeforeSubscriber(each)
        } else {
            __registryRouterBeforeSubscriber({
                time: 'done',
                scope: 0,
                callback: each
            })
        }
    }
}

/**
 * 处理路由后置操作
 * @param each
 */
function setRouterAfterEach(each: ModuleCreatorRouterAfterEach | undefined) {
    // 判断是否配置了路由前置操作
    if (each) {
        if (isRouterListenerAfterEach(each)) {
            __registryRouterAfterSubscriber(each)
        } else {
            __registryRouterAfterSubscriber({
                time: 'done',
                scope: 0,
                callback: each
            })
        }
    }
}

/**
 * 读取模块配置中的i18n配置
 * @param i18n
 */
function readI18nConfig(i18n: (() => I18NCreator<string, any>) | undefined) {
    if (i18n) {
        const i18nConfig: any = i18n();
        for (let code in i18nConfig) {
            __registryI18ns(i18nConfig[code].scope || 0, code, i18nConfig[code]);
        }
        const app = __useVerdureApp();
        app && app.reloadI18n();
    }
}

/**
 * 读取缓存配置对象
 * @param store
 */
function readStoreConfig(store: (() => StoreCreator) | undefined) {
    if (store) {
        __registryStore(store())
    }
}

/**
 * 读取路由配置
 * @param router 路由配置
 */
export function readRouterConfig(router: (() => RouterCreator) | undefined) {
    if (router) {
        const creator = router();
        for (let prop in creator) {
            const config = creator[prop];
            // 注册视图
            __registryView(prop, config.type, {
                name: config.title,
                scope: config.scope,
                component: config.component,
                children: config.children,
                redirect: config.redirect,
            });
            const route = config.router;
            // 注册路由
            if (route) {
                __registrySystemRouter(route.scope || 0, {
                    code: prop,
                    name: config.title,
                    id: route.id || guid(),
                    pid: route.pid || '0',
                    path: route.path,
                    children: {}
                });
                // console.log('system', { code: 'viewBind', title: '注册系统视图', content: { scope: config.scope, name: config.title, path: route.path } });
            } else {
                // console.log('system', { code: 'viewBind', title: '注册系统视图', content: { scope: config.scope, name: config.title } });
            }
        }
        // 重构路由对象
        const app = __useVerdureApp();
        app && app.reloadI18n();
    }
}

/**
 * 路由前置配置参数类型判断
 * @param config 配置
 */
function isRouterListenerBeforeEach(config: ModuleCreatorRouterBeforeEachConfig | ModuleCreatorRouterBeforeEach): config is ModuleCreatorRouterBeforeEachConfig {
    return !!(config as ModuleCreatorRouterBeforeEachConfig).callback
}
/**
 * 路由后置配置参数类型判断
 * @param config 配置
 */
function isRouterListenerAfterEach(config: ModuleCreatorRouterAfterEachConfig | ModuleCreatorRouterAfterEach): config is ModuleCreatorRouterAfterEachConfig {
    return !!(config as ModuleCreatorRouterAfterEachConfig).callback
}
