import {
    defineModuleCreator,
} from "@verdure/core";

import router from './router';
import i18n from './i18n';


import viewSlot from './slot/index'

export default defineModuleCreator<any>({
    id: 'systemWeChat',
    router,
    i18n,
    viewSlot
})

// 将视图导出为组件允许复用
export * from './component/component';
