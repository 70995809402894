import {defineI18nModuleCreator} from "../../utils/creator/I18nCreator";

export default defineI18nModuleCreator<{
    search: string,
    moreSearch: string,
    export: string,
    add: string,
    edit: string,
    delete: string,
    deleteHint: string,
    deleteConfirmButtonText: string,
    deleteCancelButtonText: string,
    hint: {
        deleteSuccess: string,
        deleteError: string,
        listInitError: string,
    },
    open: string,
    close: string,
    noData: string,
    operate: string,
}>('tableCard', {
    "zh-CN": {
        search: '搜索',
        moreSearch: '更多查询',
        export: '导出',
        add: '新增',
        edit: '编辑',
        delete: '删除',
        deleteHint: '您确定要删除这条数据吗？',
        deleteConfirmButtonText: '是',
        deleteCancelButtonText: '否',
        hint: {
            deleteSuccess: '删除成功',
            deleteError: '删除失败',
            listInitError: '列表数据加载失败',
        },
        open: '打开',
        close: '关闭',
        noData: '没有更多数据了',
        operate: '操作',
    },
    "en-US": {
        search: 'Search',
        moreSearch: 'More Search',
        export: 'Export',
        add: 'Add',
        edit: 'Edit',
        delete: 'Delete',
        deleteHint: 'Are you sure you want to delete this data?',
        deleteConfirmButtonText: 'Yes',
        deleteCancelButtonText: 'No',
        hint: {
            deleteSuccess: 'Delete successfully',
            deleteError: 'Fail to delete',
            listInitError: 'Failed to load list data',
        },
        open: 'Open',
        close: 'Close',
        noData: 'No more data',
        operate: 'Operate',
    }
})
