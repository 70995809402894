import {defineRouterCreator} from "@verdure/core";

export default defineRouterCreator({
    login: (import.meta as any).env.VITE_APP_LOGIN === 'user' ? {
        title: '登录页面',
        type: 'view',
        component: () => import('../views/login/Login2.vue'),
        router: {
            path: '/login'
        }
    }: {
        title: '登录页面',
        type: 'view',
        component: () => import('../views/login/Index.vue'),
        router: {
            path: '/login'
        }
    },
    error404: (import.meta as any).env.VITE_APP_LOGIN === 'user' ? {
        title: '404',
        type: 'view',
        component: () => import('../views/login/Login2.vue'),
        router: {
            path: '/404'
        }
    }: {
        title: '404',
        type: 'view',
        component: () => import('../views/login/Index.vue'),
        router: {
            path: '/404'
        }
    },
    error403: {
        title: '403',
        type: 'view',
        component: () => import('../views/error/Error403.vue'),
        router: {
            path: '/403'
        }
    },
    userCenter: {
        title: '个人中心',
        type: 'view',
        component: () => import('../views/userCenter/Index.vue'),
        router: {
            path: '/page/userCenter', pid: '10001'
        }
    },
    componentDemo: {
        title: '组件DEMO文档',
        type: 'view',
        component: () => import('../views/componentDemo/Index.vue'),
        router: {
            path: '/componentDemo', pid: '0'
        }
    },
    sysMenu: {
        title: '菜单管理',
        type: 'view',
        component: () => import('../views/menu/Index.vue'),
    },
    sysDept: {
        title: '单位管理',
        type: 'view',
        component: () => import('../views/dept/Index.vue'),
    },
    sysRole: {
        title: '角色管理',
        type: 'view',
        component: () => import('../views/role/Index.vue'),
    },
    sysUser: {
        title: '用户管理',
        type: 'view',
        component: () => import('../views/user/Index.vue'),
    },
    sysDict: {
        title: '数据字典',
        type: 'view',
        component: () => import('../views/dict/Index.vue'),
    },
    layout: {
        title: '主布局',
        type: 'layout',
        component: () => import('../views/layout/Index.vue'),
        router: {
            path: '/', pid: '-4'
        }
    },
    publicView: {
        title: '通用页面',
        type: 'view',
        component: () => import('../views/layout/Index.vue'),
        router: {
            path: '/page', pid: '0', id: '10001'
        }
    },
})
