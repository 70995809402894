import {
    AppCreateDone, AxiosBeforeApiConfig,
    CreateDoneListener,
    I18nCache,
    I18nType, ModuleCreatorRouterAfterEachConfig, ModuleCreatorRouterBeforeEachConfig,
    RouterListener,
    SystemRouter,
    ViewSlotConfig,
    OtherConfig
} from "../../typings";
import {Store, StoreDefinition} from "pinia";
import {VerdureApp, VerdureRouter} from "../../entity/VerdureApp";
import {Module, View, ViewType} from "../../entity/VerdureModule";
import { App, Ref, ref } from 'vue';
import {Router} from "vue-router";
import {I18nInstance} from "vue3-i18n/src/types";
import {deepCopy, isNullAndNew} from "..";

const VerdureData = new Proxy<{
    vue: App | null,
    // vueRouter实例
    vueRouter: Router | null,
    pinia: any,
    // 应用实例
    verdureApp: VerdureApp | null,
    // 国际化实例
    i18n: I18nInstance | null,
    // 模块
    module: Record<string, Module>,
    // 应用实例创建完成的回调
    appCreateDone: Array<AppCreateDone>,
    // 缓存对象
    store: Record<string, Store>,
    // 应用配置对象
    config: Record<string, Record<string, any>>
    // 视图对象
    views: Record<string, View>,
    // 系统路由配置对象
    systemRouter: SystemRouter,
    // 国际化数据对象
    i18nCache: I18nCache,
    // 实例创建完成后的回调（这个回调为匿名回调区别于模块的回调）允许在任意位置调用
    appCreateDoneCallback: Array<CreateDoneListener>,
    // 视图插槽 注册系统视图的动态组件，此处注册的组件将被绑定到各个页面中
    viewSlots: Record<string, Record<string, ViewSlotConfig>>,
    // 路由监听器
    routerListener: RouterListener,
    // 接口请求前请求配置回调
    axiosBeforeApiConfig: Array<AxiosBeforeApiConfig>,
    childrenRouterPath: {
        path: Record<string, {
            code: string,
            name: string
        }>,
        code: Record<string, {
            path: string,
            name: string
        }>
    }, // 路由配置 > 子路由列表，子路由不属于视图配置，缓存用于刷新页面时重载到页面防止白屏
    otherConfig: OtherConfig
}>({
    pinia: null,
    vue: null,
    vueRouter: null,
    verdureApp: null,
    i18n: null,
    module: {},
    appCreateDone: [],
    store: {},
    config: {},
    views: {},
    systemRouter: {},
    i18nCache: {},
    appCreateDoneCallback: [],
    viewSlots: {},
    routerListener: {
        before: {}, after: {}
    },
    axiosBeforeApiConfig: [],
    otherConfig: {
        viewSize: 'xl'
    },
    childrenRouterPath: {
        path: {}, code: {}
    }
}, {

});

export const registryPinia = function (pinia: any) {
    VerdureData.pinia = pinia;
}

/**
 * 缓存视图的子路由
 * @param code
 * @param name
 * @param path
 * @param basePath
 */
export const registryChildrenRouterPath = function (code: string, name: string, path: string, basePath: string) {
    const pa = `${basePath ? `${basePath}/` : ''}${path || code}`;
    VerdureData.childrenRouterPath.path[pa] = { code, name };
    VerdureData.childrenRouterPath.code[code] = { path: pa, name };
};
/**
 * 使用缓存的视图的子路由
 */
export const __useChildrenRouterPath = function () {
    return VerdureData.childrenRouterPath
};

// 获取其他配置
export const useOtherConfig = function () : OtherConfig{
    return VerdureData.otherConfig;
};

/**
 * 注册接口调用前配置的回调
 * @param callback 回调
 * @private
 */
export const __registryAxiosBeforeApiConfig = function (callback: AxiosBeforeApiConfig ) {
    VerdureData.axiosBeforeApiConfig.push(callback);
};

/**
 * 获取回调列表
 * @private
 */
export const __useAxiosBeforeApiConfig = function (): Array<AxiosBeforeApiConfig> {
    return VerdureData.axiosBeforeApiConfig;
};

/**
 * 注册路由前置订阅者
 * @param config 配置对象
 */
export const __registryRouterBeforeSubscriber = function(config: ModuleCreatorRouterBeforeEachConfig) {
    isNullAndNew(VerdureData.routerListener.before, config.time || 'done', []);
    VerdureData.routerListener.before[config.time || 'done'].push({
        scope: config.scope || 0,
        callback: config.callback
    });
}
/**
 * 注册路由后置订阅者
 * @param config 配置对象
 */
export const __registryRouterAfterSubscriber = function(config: ModuleCreatorRouterAfterEachConfig) {
    isNullAndNew(VerdureData.routerListener.after, config.time || 'done', []);
    VerdureData.routerListener.after[config.time || 'done'].push({
        scope: config.scope || 0,
        callback: config.callback
    });
};

/**
 * 获取路由回调监听器
 * @private
 */
export function __useRouterListener() : RouterListener{
    return VerdureData.routerListener;
}


/**
 * 注册视图插槽
 * @param viewKey 视图标识
 * @param slot 插槽
 * @param component 组件
 * @private
 */
export const __registryViewSlot = function(viewKey: string, slot: string, component: ViewSlotConfig) {
    isNullAndNew(VerdureData.viewSlots, viewKey, {});
    const slotData = VerdureData.viewSlots[viewKey][slot];
    if (slotData) {
        if ((component.sort || 0) > (slotData.sort || 0)) {
            VerdureData.viewSlots[viewKey][slot] = component
        }
    } else {
        VerdureData.viewSlots[viewKey][slot] = component
    }
}

/**
 * 获取视图的slot插槽注入对象
 * @param viewKey router视图缓存对象
 */
export const __useModuleViewSlot = function(viewKey: string) {
    if (VerdureData.viewSlots[viewKey] === undefined) {
        VerdureData.viewSlots[viewKey] = {};
    }
    return VerdureData.viewSlots[viewKey];
};

/**
 * 注册实例完成最终创建的回调
 * @param opt
 * @private
 */
export const __registryAppCreateDoneCallback = function (opt: CreateDoneListener) {
    VerdureData.appCreateDoneCallback.push(opt)
};

/**
 * 获取实例完成最终创建的回调
 * @private
 */
export const __useAppCreateDoneCallback = function() : Array<CreateDoneListener> {
    return VerdureData.appCreateDoneCallback;
};

/**
 * 获取国际化数据配置对象
 * @private
 */
export const __useI18nData = function (): I18nCache {
    return VerdureData.i18nCache;
};

/**
 * 注册国际化
 * @param scope 国际化配置的得分
 * @param code 代码
 * @param i18n 国际化配置
 */
export const __registryI18ns = function(scope: number, code: string, i18n: I18nType) {
    const data = VerdureData.i18nCache;
    const i18 = data[code];
    if (i18 === undefined) {
        data[code] = {
            scope,
            message: i18n
        }
    } else if (i18.scope <= scope) {
        deepCopy(data[code], {
            scope,
            message: i18n
        })
    } else {
        const target = JSON.parse(JSON.stringify({
            scope: i18.scope,
            message: i18n
        }));
        deepCopy(target, data[code])
        data[code] = target
    }
};

/**
 * 注册路由
 * @param scope 得分
 * @param router 路由配置
 */
export const __registrySystemRouter = function(scope: number, router: VerdureRouter) {
    const rou = VerdureData.systemRouter[router.path];
    if (rou === undefined || rou.scope < scope) {
        VerdureData.systemRouter[router.path] = {
            scope, router
        }
    }
};


/**
 * 使用系统路由地址配置列表
 */
export const __useSystemRouter = function(): VerdureRouter[] {
    const res: VerdureRouter[] = [];
    for (let valueKey in VerdureData.systemRouter) {
        res.push(VerdureData.systemRouter[valueKey].router)
    }
    return res;
};

/**
 * 获取系统路由map
 * @private
 */
export const __useSystemRouterMap = function(): Record<string, {
    scope: number,
    router: VerdureRouter
}> {
    return VerdureData.systemRouter;
};

/**
 * 注册视图
 * @param code 代码
 * @param type 视图类型
 * @param view 视图
 */
export const __registryView = function(code: string, type: ViewType, view: View) {
    const viewData = VerdureData.views[code];
    if (viewData === undefined || (viewData.scope || 0) < (view.scope || 0)) {
        view.type = type;
        VerdureData.views[code] = view
    }
};

/**
 * 获取视图配置
 * @private
 */
export const __useViews = function (): Record<string, View> {
    return VerdureData.views;
};

/**
 * 支持配置对象
 * @param moduleId 模块ID
 * @param config 配置对象
 */
export const __registryConfig = function (moduleId: string, config: Record<string, any>) {
    VerdureData.config[moduleId] = config;
};

/**
 * 使用配置
 */
export function __useConfig(): Record<string, any> {
    return VerdureData.config;
}

/**
 * 使用配置对象
 * @param key 模块标识
 */
export function __useConfigKey(key: string): Record<string, any> {
    return VerdureData.config[key];
}

/**
 * 支持国际化实例
 * @param i18n 国际化
 * @private
 */
export const __registryI18n = function(i18n: I18nInstance) {
    VerdureData.i18n = i18n;
};

/**
 * 获取国际化实例
 * @private
 */
export const __useI18n = function (): I18nInstance {
    return VerdureData.i18n!
};

/**
 * 注册 vueRouter 实例
 * @param router
 * @private
 */
export const __registryVueRouter = function (router: Router) {
    VerdureData.vueRouter = router;
};

/**
 * 获取vueRouter实例
 * @private
 */
export const __useVueRouter = function (): Router {
    return VerdureData.vueRouter!;
};

/**
 * 注册Vue实例
 * @param vue vue实例
 */
export const __registryVue = function(vue: App) {
    VerdureData.vue = vue;
};

/**
 * 获取vue实例
 * @private
 */
export const __useVueApp = function(): App {
    return VerdureData.vue!;
};

/**
 * 注册模块
 * @param key 模块标识
 * @param module 模块
 */
export const __registerModule = function (key: string, module: Module) {
    VerdureData.module[key] = module;
};

/**
 * 注册应用实例
 * @param app 应用实例
 * @private
 */
export const __registryVerdureApp = function (app: VerdureApp) {
    VerdureData.verdureApp = app;
};

/**
 * 获取应用实例
 * @private
 */
export const __useVerdureApp = function (): VerdureApp | null {
    return VerdureData.verdureApp;
};


/**
 * 注册缓存对象列表
 * @param stores 缓存对象
 */
export const __registryStore = function(stores: Array<StoreDefinition>) {
    stores.forEach(item => {
        VerdureData.store[item.$id] = item(VerdureData.pinia);
    })
};

/**
 * 获取 Store 对象
 */
export const __useStore = function (): Record<string, Store> {
    return VerdureData.store;
};

    /**
 * 注册应用实例创建完成的回调事件
 * @param done 回调事件
 */
export const __registryAppCreateDone = function (done: AppCreateDone | undefined) {
    done && VerdureData.appCreateDone.push(done);
};

/**
 * 回去注册的应用实例创建完成回调
 */
export const __useAppCreateDone = function (): Array<AppCreateDone> {
    return VerdureData.appCreateDone;
};

export {}
