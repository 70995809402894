export const backType = [
  {
    "value": "ICBC_DEBIT",
    "label": "工商银行（借记卡）"
  },
  {
    "value": "ICBC_CREDIT",
    "label": "工商银行（信用卡）"
  },
  {
    "value": "ABC_DEBIT",
    "label": "农业银行（借记卡）"
  },
  {
    "value": "ABC_CREDIT",
    "label": "农业银行（信用卡）"
  },
  {
    "value": "PSBC_CREDIT",
    "label": "邮储银行（信用卡）"
  },
  {
    "value": "PSBC_DEBIT",
    "label": "邮储银行（借记卡）"
  },
  {
    "value": "CCB_DEBIT",
    "label": "建设银行（借记卡）"
  },
  {
    "value": "CCB_CREDIT",
    "label": "建设银行（信用卡）"
  },
  {
    "value": "CMB_DEBIT",
    "label": "招商银行（借记卡）"
  },
  {
    "value": "CMB_CREDIT",
    "label": "招商银行（信用卡）"
  },
  {
    "value": "BOC_DEBIT",
    "label": "中国银行（借记卡）"
  },
  {
    "value": "BOC_CREDIT",
    "label": "中国银行（信用卡）"
  },
  {
    "value": "COMM_DEBIT",
    "label": "交通银行（借记卡）"
  },
  {
    "value": "COMM_CREDIT",
    "label": "交通银行（信用卡）"
  },
  {
    "value": "SPDB_DEBIT",
    "label": "浦发银行（借记卡）"
  },
  {
    "value": "SPDB_CREDIT",
    "label": "浦发银行（信用卡）"
  },
  {
    "value": "GDB_DEBIT",
    "label": "广发银行（借记卡）"
  },
  {
    "value": "GDB_CREDIT",
    "label": "广发银行（信用卡）"
  },
  {
    "value": "CMBC_DEBIT",
    "label": "民生银行（借记卡）"
  },
  {
    "value": "CMBC_CREDIT",
    "label": "民生银行（信用卡）"
  },
  {
    "value": "PAB_DEBIT",
    "label": "平安银行（借记卡）"
  },
  {
    "value": "PAB_CREDIT",
    "label": "平安银行（信用卡）"
  },
  {
    "value": "CEB_DEBIT",
    "label": "光大银行（借记卡）"
  },
  {
    "value": "CEB_CREDIT",
    "label": "光大银行（信用卡）"
  },
  {
    "value": "CIB_DEBIT",
    "label": "兴业银行（借记卡）"
  },
  {
    "value": "CIB_CREDIT",
    "label": "兴业银行（信用卡）"
  },
  {
    "value": "CITIC_DEBIT",
    "label": "中信银行（借记卡）"
  },
  {
    "value": "CITIC_CREDIT",
    "label": "中信银行（信用卡）"
  },
  {
    "value": "BOSH_DEBIT",
    "label": "上海银行（借记卡）"
  },
  {
    "value": "BOSH_CREDIT",
    "label": "上海银行（信用卡）"
  },
  {
    "value": "AHRCUB_CREDIT",
    "label": "安徽省农村信用社联合社（信用卡）"
  },
  {
    "value": "AHRCUB_DEBIT",
    "label": "安徽省农村信用社联合社（借记卡）"
  },
  {
    "value": "AIB_DEBIT",
    "label": "百信银行（借记卡）"
  },
  {
    "value": "ASCB_DEBIT",
    "label": "鞍山银行（借记卡）"
  },
  {
    "value": "ATRB_DEBIT",
    "label": "盘山安泰村镇银行（借记卡）"
  },
  {
    "value": "BCZ_CREDIT",
    "label": "沧州银行（信用卡）"
  },
  {
    "value": "BCZ_DEBIT",
    "label": "沧州银行（借记卡）"
  },
  {
    "value": "BDB_DEBIT",
    "label": "保定银行（借记卡）"
  },
  {
    "value": "BEEB_CREDIT",
    "label": "鄞州银行（信用卡）"
  },
  {
    "value": "BEEB_DEBIT",
    "label": "鄞州银行（借记卡）"
  },
  {
    "value": "BGZB_DEBIT",
    "label": "贵州银行（借记卡）"
  },
  {
    "value": "BHB_CREDIT",
    "label": "河北银行（信用卡）"
  },
  {
    "value": "BHB_DEBIT",
    "label": "河北银行（借记卡）"
  },
  {
    "value": "BJRCB_CREDIT",
    "label": "北京农商行（信用卡）"
  },
  {
    "value": "BJRCB_DEBIT",
    "label": "北京农商行（借记卡）"
  },
  {
    "value": "BNC_CREDIT",
    "label": "江西银行（信用卡）"
  },
  {
    "value": "BNC_DEBIT",
    "label": "江西银行（借记卡）"
  },
  {
    "value": "BOB_CREDIT",
    "label": "北京银行（信用卡）"
  },
  {
    "value": "BOB_DEBIT",
    "label": "北京银行（借记卡）"
  },
  {
    "value": "BOBBG_CREDIT",
    "label": "北部湾银行（信用卡）"
  },
  {
    "value": "BOBBG_DEBIT",
    "label": "北部湾银行（借记卡）"
  },
  {
    "value": "BOCD_DEBIT",
    "label": "成都银行（借记卡）"
  },
  {
    "value": "BOCDB_DEBIT",
    "label": "承德银行（借记卡）"
  },
  {
    "value": "BOCFB_DEBIT",
    "label": "中银富登村镇银行（借记卡）"
  },
  {
    "value": "BOCTS_DEBIT",
    "label": "焦作中旅银行（借记卡）"
  },
  {
    "value": "BOD_CREDIT",
    "label": "东莞银行（信用卡）"
  },
  {
    "value": "BOD_DEBIT",
    "label": "东莞银行（借记卡）"
  },
  {
    "value": "BOFS_DEBIT",
    "label": "抚顺银行（借记卡）"
  },
  {
    "value": "BOHN_DEBIT",
    "label": "海南银行（借记卡）"
  },
  {
    "value": "BOIMCB_CREDIT",
    "label": "内蒙古银行（信用卡）"
  },
  {
    "value": "BOIMCB_DEBIT",
    "label": "内蒙古银行（借记卡）"
  },
  {
    "value": "BOJN_DEBIT",
    "label": "济宁银行（借记卡）"
  },
  {
    "value": "BOJX_DEBIT",
    "label": "嘉兴银行（借记卡）"
  },
  {
    "value": "BOLB_DEBIT",
    "label": "洛阳银行（借记卡）"
  },
  {
    "value": "BOLFB_DEBIT",
    "label": "廊坊银行（借记卡）"
  },
  {
    "value": "BONX_CREDIT",
    "label": "宁夏银行（信用卡）"
  },
  {
    "value": "BONX_DEBIT",
    "label": "宁夏银行（借记卡）"
  },
  {
    "value": "BOPDS_DEBIT",
    "label": "平顶山银行（借记卡）"
  },
  {
    "value": "BOPJ_DEBIT",
    "label": "盘锦银行（借记卡）"
  },
  {
    "value": "BOQHB_CREDIT",
    "label": "青海银行（信用卡）"
  },
  {
    "value": "BOQHB_DEBIT",
    "label": "青海银行（借记卡）"
  },
  {
    "value": "BOSXB_DEBIT",
    "label": "绍兴银行（借记卡）"
  },
  {
    "value": "BOSZS_DEBIT",
    "label": "石嘴山银行（借记卡）"
  },
  {
    "value": "BOTSB_DEBIT",
    "label": "唐山银行（借记卡）"
  },
  {
    "value": "BOZ_CREDIT",
    "label": "张家口银行（信用卡）"
  },
  {
    "value": "BOZ_DEBIT",
    "label": "张家口银行（借记卡）"
  },
  {
    "value": "BSB_CREDIT",
    "label": "包商银行（信用卡）"
  },
  {
    "value": "BSB_DEBIT",
    "label": "包商银行（借记卡）"
  },
  {
    "value": "BYK_DEBIT",
    "label": "营口银行（借记卡）"
  },
  {
    "value": "CBHB_DEBIT",
    "label": "渤海银行（借记卡）"
  },
  {
    "value": "CCAB_CREDIT",
    "label": "长安银行（信用卡）"
  },
  {
    "value": "CCAB_DEBIT",
    "label": "长安银行（借记卡）"
  },
  {
    "value": "CDRCB_DEBIT",
    "label": "成都农商银行（借记卡）"
  },
  {
    "value": "CITIB_CREDIT",
    "label": "花旗银行（信用卡）"
  },
  {
    "value": "CITIB_DEBIT",
    "label": "花旗银行（借记卡）"
  },
  {
    "value": "CJCCB_DEBIT",
    "label": "江苏长江商业银行（借记卡）"
  },
  {
    "value": "CQB_CREDIT",
    "label": "重庆银行（信用卡）"
  },
  {
    "value": "CQB_DEBIT",
    "label": "重庆银行（借记卡）"
  },
  {
    "value": "CQRCB_CREDIT",
    "label": "重庆农村商业银行（信用卡）"
  },
  {
    "value": "CQRCB_DEBIT",
    "label": "重庆农村商业银行（借记卡）"
  },
  {
    "value": "CQTGB_DEBIT",
    "label": "重庆三峡银行（借记卡）"
  },
  {
    "value": "CRB_CREDIT",
    "label": "珠海华润银行（信用卡）"
  },
  {
    "value": "CRB_DEBIT",
    "label": "珠海华润银行（借记卡）"
  },
  {
    "value": "CSCB_CREDIT",
    "label": "长沙银行（信用卡）"
  },
  {
    "value": "CSCB_DEBIT",
    "label": "长沙银行（借记卡）"
  },
  {
    "value": "CSRCB_CREDIT",
    "label": "常熟农商银行（信用卡）"
  },
  {
    "value": "CSRCB_DEBIT",
    "label": "常熟农商银行（借记卡）"
  },
  {
    "value": "CSXB_DEBIT",
    "label": "三湘银行（借记卡）"
  },
  {
    "value": "CYCB_CREDIT",
    "label": "朝阳银行（信用卡）"
  },
  {
    "value": "CYCB_DEBIT",
    "label": "朝阳银行（借记卡）"
  },
  {
    "value": "CZB_CREDIT",
    "label": "浙商银行（信用卡）"
  },
  {
    "value": "CZB_DEBIT",
    "label": "浙商银行（借记卡）"
  },
  {
    "value": "CZCB_CREDIT",
    "label": "稠州银行（信用卡）"
  },
  {
    "value": "CZCB_DEBIT",
    "label": "稠州银行（借记卡）"
  },
  {
    "value": "CZCCB_DEBIT",
    "label": "长治银行（借记卡）"
  },
  {
    "value": "DANDONGB_CREDIT",
    "label": "丹东银行（信用卡）"
  },
  {
    "value": "DANDONGB_DEBIT",
    "label": "丹东银行（借记卡）"
  },
  {
    "value": "DBSB_DEBIT",
    "label": "星展银行（借记卡）"
  },
  {
    "value": "DCSFRB_DEBIT",
    "label": "大城舜丰村镇银行（借记卡）"
  },
  {
    "value": "DHDYB_DEBIT",
    "label": "德惠敦银村镇银行（借记卡）"
  },
  {
    "value": "DHRB_DEBIT",
    "label": "调兵山惠民村镇银行（借记卡）"
  },
  {
    "value": "DLB_CREDIT",
    "label": "大连银行（信用卡）"
  },
  {
    "value": "DLB_DEBIT",
    "label": "大连银行（借记卡）"
  },
  {
    "value": "DLRCB_DEBIT",
    "label": "大连农商行（借记卡）"
  },
  {
    "value": "DRCB_CREDIT",
    "label": "东莞农商银行（信用卡）"
  },
  {
    "value": "DRCB_DEBIT",
    "label": "东莞农商银行（借记卡）"
  },
  {
    "value": "DSB_DEBIT",
    "label": "大新银行（借记卡）"
  },
  {
    "value": "DTCCB_DEBIT",
    "label": "大同银行（借记卡）"
  },
  {
    "value": "DYB_CREDIT",
    "label": "东营银行（信用卡）"
  },
  {
    "value": "DYB_DEBIT",
    "label": "东营银行（借记卡）"
  },
  {
    "value": "DYCCB_DEBIT",
    "label": "长城华西银行（借记卡）"
  },
  {
    "value": "DYLSB_DEBIT",
    "label": "东营莱商村镇银行（借记卡）"
  },
  {
    "value": "DZB_DEBIT",
    "label": "德州银行（借记卡）"
  },
  {
    "value": "DZCCB_DEBIT",
    "label": "达州银行（借记卡）"
  },
  {
    "value": "EDRB_DEBIT",
    "label": "鼎业村镇银行（借记卡）"
  },
  {
    "value": "ESUNB_DEBIT",
    "label": "玉山银行（借记卡）"
  },
  {
    "value": "FBB_DEBIT",
    "label": "富邦华一银行（借记卡）"
  },
  {
    "value": "FDB_CREDIT",
    "label": "富滇银行（信用卡）"
  },
  {
    "value": "FDB_DEBIT",
    "label": "富滇银行（借记卡）"
  },
  {
    "value": "FJHXB_CREDIT",
    "label": "福建海峡银行（信用卡）"
  },
  {
    "value": "FJHXB_DEBIT",
    "label": "福建海峡银行（借记卡）"
  },
  {
    "value": "FJNX_CREDIT",
    "label": "福建农信银行（信用卡）"
  },
  {
    "value": "FJNX_DEBIT",
    "label": "福建农信银行（借记卡）"
  },
  {
    "value": "FUXINB_CREDIT",
    "label": "阜新银行（信用卡）"
  },
  {
    "value": "FUXINB_DEBIT",
    "label": "阜新银行（借记卡）"
  },
  {
    "value": "FXLZB_DEBIT",
    "label": "费县梁邹村镇银行（借记卡）"
  },
  {
    "value": "GADRB_DEBIT",
    "label": "贵安新区发展村镇银行（借记卡）"
  },
  {
    "value": "GDHX_DEBIT",
    "label": "广东华兴银行（借记卡）"
  },
  {
    "value": "GDNYB_CREDIT",
    "label": "南粤银行（信用卡）"
  },
  {
    "value": "GDNYB_DEBIT",
    "label": "南粤银行（借记卡）"
  },
  {
    "value": "GDRCU_DEBIT",
    "label": "广东农信银行（借记卡）"
  },
  {
    "value": "GLB_CREDIT",
    "label": "桂林银行（信用卡）"
  },
  {
    "value": "GLB_DEBIT",
    "label": "桂林银行（借记卡）"
  },
  {
    "value": "GLGMCB_DEBIT",
    "label": "桂林国民村镇银行（借记卡）"
  },
  {
    "value": "GRCB_CREDIT",
    "label": "广州农商银行（信用卡）"
  },
  {
    "value": "GRCB_DEBIT",
    "label": "广州农商银行（借记卡）"
  },
  {
    "value": "GSB_DEBIT",
    "label": "甘肃银行（借记卡）"
  },
  {
    "value": "GSNX_DEBIT",
    "label": "甘肃农信（借记卡）"
  },
  {
    "value": "GSRB_DEBIT",
    "label": "广阳舜丰村镇银行（借记卡）"
  },
  {
    "value": "GXNX_CREDIT",
    "label": "广西农信（信用卡）"
  },
  {
    "value": "GXNX_DEBIT",
    "label": "广西农信（借记卡）"
  },
  {
    "value": "GYCB_CREDIT",
    "label": "贵阳银行（信用卡）"
  },
  {
    "value": "GYCB_DEBIT",
    "label": "贵阳银行（借记卡）"
  },
  {
    "value": "GZCB_CREDIT",
    "label": "广州银行（信用卡）"
  },
  {
    "value": "GZCB_DEBIT",
    "label": "广州银行（借记卡）"
  },
  {
    "value": "GZCCB_CREDIT",
    "label": "赣州银行（信用卡）"
  },
  {
    "value": "GZCCB_DEBIT",
    "label": "赣州银行（借记卡）"
  },
  {
    "value": "GZNX_DEBIT",
    "label": "贵州农信（借记卡）"
  },
  {
    "value": "HAINNX_CREDIT",
    "label": "海南农信（信用卡）"
  },
  {
    "value": "HAINNX_DEBIT",
    "label": "海南农信（借记卡）"
  },
  {
    "value": "HANAB_DEBIT",
    "label": "韩亚银行（借记卡）"
  },
  {
    "value": "HBCB_CREDIT",
    "label": "湖北银行（信用卡）"
  },
  {
    "value": "HBCB_DEBIT",
    "label": "湖北银行（借记卡）"
  },
  {
    "value": "HBNX_CREDIT",
    "label": "湖北农信（信用卡）"
  },
  {
    "value": "HBNX_DEBIT",
    "label": "湖北农信（借记卡）"
  },
  {
    "value": "HDCB_DEBIT",
    "label": "邯郸银行（借记卡）"
  },
  {
    "value": "HEBNX_DEBIT",
    "label": "河北农信（借记卡）"
  },
  {
    "value": "HFB_CREDIT",
    "label": "恒丰银行（信用卡）"
  },
  {
    "value": "HFB_DEBIT",
    "label": "恒丰银行（借记卡）"
  },
  {
    "value": "HKB_CREDIT",
    "label": "汉口银行（信用卡）"
  },
  {
    "value": "HKB_DEBIT",
    "label": "汉口银行（借记卡）"
  },
  {
    "value": "HKBEA_CREDIT",
    "label": "东亚银行（信用卡）"
  },
  {
    "value": "HKBEA_DEBIT",
    "label": "东亚银行（借记卡）"
  },
  {
    "value": "HKUB_DEBIT",
    "label": "海口联合农商银行（借记卡）"
  },
  {
    "value": "HLDCCB_DEBIT",
    "label": "葫芦岛银行（借记卡）"
  },
  {
    "value": "HLDYB_DEBIT",
    "label": "和龙敦银村镇银行（借记卡）"
  },
  {
    "value": "HLJRCUB_DEBIT",
    "label": "黑龙江农信社（借记卡）"
  },
  {
    "value": "HMCCB_DEBIT",
    "label": "哈密银行（借记卡）"
  },
  {
    "value": "HNNX_DEBIT",
    "label": "河南农信（借记卡）"
  },
  {
    "value": "HRBB_CREDIT",
    "label": "哈尔滨银行（信用卡）"
  },
  {
    "value": "HRBB_DEBIT",
    "label": "哈尔滨银行（借记卡）"
  },
  {
    "value": "HRCB_DEBIT",
    "label": "保德慧融村镇银行（借记卡）"
  },
  {
    "value": "HRXJB_CREDIT",
    "label": "华融湘江银行（信用卡）"
  },
  {
    "value": "HRXJB_DEBIT",
    "label": "华融湘江银行（借记卡）"
  },
  {
    "value": "HSB_CREDIT",
    "label": "徽商银行（信用卡）"
  },
  {
    "value": "HSB_DEBIT",
    "label": "徽商银行（借记卡）"
  },
  {
    "value": "HSBC_DEBIT",
    "label": "恒生银行（借记卡）"
  },
  {
    "value": "HSBCC_CREDIT",
    "label": "汇丰银行（信用卡）"
  },
  {
    "value": "HSBCC_DEBIT",
    "label": "汇丰银行（借记卡）"
  },
  {
    "value": "HSCB_DEBIT",
    "label": "衡水银行（借记卡）"
  },
  {
    "value": "HUIHEB_DEBIT",
    "label": "新疆汇和银行（借记卡）"
  },
  {
    "value": "HUNNX_DEBIT",
    "label": "湖南农信（借记卡）"
  },
  {
    "value": "HUSRB_DEBIT",
    "label": "湖商村镇银行（借记卡）"
  },
  {
    "value": "HXB_CREDIT",
    "label": "华夏银行（信用卡）"
  },
  {
    "value": "HXB_DEBIT",
    "label": "华夏银行（借记卡）"
  },
  {
    "value": "HZB_CREDIT",
    "label": "杭州银行（信用卡）"
  },
  {
    "value": "HZB_DEBIT",
    "label": "杭州银行（借记卡）"
  },
  {
    "value": "HZCCB_DEBIT",
    "label": "湖州银行（借记卡）"
  },
  {
    "value": "IBKB_DEBIT",
    "label": "企业银行（借记卡）"
  },
  {
    "value": "JCB_DEBIT",
    "label": "晋城银行（借记卡）"
  },
  {
    "value": "JCBK_CREDIT",
    "label": "晋城银行（信用卡）"
  },
  {
    "value": "JDHDB_DEBIT",
    "label": "上海嘉定洪都村镇银行（借记卡）"
  },
  {
    "value": "JDZCCB_DEBIT",
    "label": "景德镇市商业银行（借记卡）"
  },
  {
    "value": "JHCCB_CREDIT",
    "label": "金华银行（信用卡）"
  },
  {
    "value": "JHCCB_DEBIT",
    "label": "金华银行（借记卡）"
  },
  {
    "value": "JJCCB_CREDIT",
    "label": "九江银行（信用卡）"
  },
  {
    "value": "JJCCB_DEBIT",
    "label": "九江银行（借记卡）"
  },
  {
    "value": "UPQUICKPASS_DEBIT",
    "label": "云闪付（借记卡）"
  },
  {
    "value": "JLB_CREDIT",
    "label": "吉林银行（信用卡）"
  },
  {
    "value": "JLB_DEBIT",
    "label": "吉林银行（借记卡）"
  },
  {
    "value": "JLNX_DEBIT",
    "label": "吉林农信（借记卡）"
  },
  {
    "value": "JNRCB_CREDIT",
    "label": "江南农商行（信用卡）"
  },
  {
    "value": "JNRCB_DEBIT",
    "label": "江南农商行（借记卡）"
  },
  {
    "value": "JRCB_CREDIT",
    "label": "江阴农商行（信用卡）"
  },
  {
    "value": "JRCB_DEBIT",
    "label": "江阴农商行（借记卡）"
  },
  {
    "value": "JSB_CREDIT",
    "label": "江苏银行（信用卡）"
  },
  {
    "value": "JSB_DEBIT",
    "label": "江苏银行（借记卡）"
  },
  {
    "value": "JSHB_CREDIT",
    "label": "晋商银行（信用卡）"
  },
  {
    "value": "JSHB_DEBIT",
    "label": "晋商银行（借记卡）"
  },
  {
    "value": "JSNX_CREDIT",
    "label": "江苏农信（信用卡）"
  },
  {
    "value": "JSNX_DEBIT",
    "label": "江苏农信（借记卡）"
  },
  {
    "value": "JUFENGB_DEBIT",
    "label": "临朐聚丰村镇银行（借记卡）"
  },
  {
    "value": "JXB_DEBIT",
    "label": "西昌金信村镇银行（借记卡）"
  },
  {
    "value": "JXNXB_DEBIT",
    "label": "江西农信（借记卡）"
  },
  {
    "value": "JZB_CREDIT",
    "label": "晋中银行（信用卡）"
  },
  {
    "value": "JZB_DEBIT",
    "label": "晋中银行（借记卡）"
  },
  {
    "value": "JZCB_CREDIT",
    "label": "锦州银行（信用卡）"
  },
  {
    "value": "JZCB_DEBIT",
    "label": "锦州银行（借记卡）"
  },
  {
    "value": "KCBEB_DEBIT",
    "label": "天津金城银行（借记卡）"
  },
  {
    "value": "KLB_CREDIT",
    "label": "昆仑银行（信用卡）"
  },
  {
    "value": "KLB_DEBIT",
    "label": "昆仑银行（借记卡）"
  },
  {
    "value": "KRCB_DEBIT",
    "label": "昆山农商（借记卡）"
  },
  {
    "value": "KSHB_DEBIT",
    "label": "梅州客商银行（借记卡）"
  },
  {
    "value": "KUERLECB_DEBIT",
    "label": "库尔勒市商业银行（借记卡）"
  },
  {
    "value": "LCYRB_DEBIT",
    "label": "陵城圆融村镇银行（借记卡）"
  },
  {
    "value": "LICYRB_DEBIT",
    "label": "历城圆融村镇银行（借记卡）"
  },
  {
    "value": "LJB_DEBIT",
    "label": "龙江银行（借记卡）"
  },
  {
    "value": "LLB_DEBIT",
    "label": "山东兰陵村镇银行（借记卡）"
  },
  {
    "value": "LLHZCB_DEBIT",
    "label": "柳林汇泽村镇银行（借记卡）"
  },
  {
    "value": "LNNX_DEBIT",
    "label": "辽宁农信（借记卡）"
  },
  {
    "value": "LPCB_DEBIT",
    "label": "凉山州商业银行（借记卡）"
  },
  {
    "value": "LPSBLVB_DEBIT",
    "label": "钟山凉都村镇银行（借记卡）"
  },
  {
    "value": "LSB_CREDIT",
    "label": "临商银行（信用卡）"
  },
  {
    "value": "LSB_DEBIT",
    "label": "临商银行（借记卡）"
  },
  {
    "value": "LSCCB_DEBIT",
    "label": "乐山市商业银行（借记卡）"
  },
  {
    "value": "LUZB_DEBIT",
    "label": "柳州银行（借记卡）"
  },
  {
    "value": "LWB_DEBIT",
    "label": "莱商银行（借记卡）"
  },
  {
    "value": "LYYHB_DEBIT",
    "label": "辽阳银行（借记卡）"
  },
  {
    "value": "LZB_CREDIT",
    "label": "兰州银行（信用卡）"
  },
  {
    "value": "LZB_DEBIT",
    "label": "兰州银行（借记卡）"
  },
  {
    "value": "LZCCB_DEBIT",
    "label": "泸州市商业银行（借记卡）"
  },
  {
    "value": "MHBRB_DEBIT",
    "label": "闵行上银村镇银行（借记卡）"
  },
  {
    "value": "MINTAIB_CREDIT",
    "label": "民泰银行（信用卡）"
  },
  {
    "value": "MINTAIB_DEBIT",
    "label": "民泰银行（借记卡）"
  },
  {
    "value": "MPJDRB_DEBIT",
    "label": "牟平胶东村镇银行（借记卡）"
  },
  {
    "value": "MYCCB_DEBIT",
    "label": "绵阳市商业银行（借记卡）"
  },
  {
    "value": "NBCB_CREDIT",
    "label": "宁波银行（信用卡）"
  },
  {
    "value": "NBCB_DEBIT",
    "label": "宁波银行（借记卡）"
  },
  {
    "value": "NCB_DEBIT",
    "label": "宁波通商银行（借记卡）"
  },
  {
    "value": "NCBCB_DEBIT",
    "label": "南洋商业银行（借记卡）"
  },
  {
    "value": "NCCB_DEBIT",
    "label": "四川天府银行（借记卡）"
  },
  {
    "value": "NJCB_CREDIT",
    "label": "南京银行（信用卡）"
  },
  {
    "value": "NJCB_DEBIT",
    "label": "南京银行（借记卡）"
  },
  {
    "value": "NJJDRB_DEBIT",
    "label": "宁津胶东村镇银行（借记卡）"
  },
  {
    "value": "NJXLRB_DEBIT",
    "label": "内江兴隆村镇银行（借记卡）"
  },
  {
    "value": "NMGNX_DEBIT",
    "label": "内蒙古农信（借记卡）"
  },
  {
    "value": "NNGMB_DEBIT",
    "label": "南宁江南国民村镇银行（借记卡）"
  },
  {
    "value": "NUB_DEBIT",
    "label": "辽宁振兴银行（借记卡）"
  },
  {
    "value": "NYCCB_DEBIT",
    "label": "南阳村镇银行（借记卡）"
  },
  {
    "value": "OCBCWHCB_DEBIT",
    "label": "华侨永亨银行（借记卡）"
  },
  {
    "value": "OHVB_DEBIT",
    "label": "鄂托克旗汇泽村镇银行（借记卡）"
  },
  {
    "value": "ORDOSB_CREDIT",
    "label": "鄂尔多斯银行（信用卡）"
  },
  {
    "value": "ORDOSB_DEBIT",
    "label": "鄂尔多斯银行（借记卡）"
  },
  {
    "value": "PBDLRB_DEBIT",
    "label": "平坝鼎立村镇银行（借记卡）"
  },
  {
    "value": "PJDWHFB_DEBIT",
    "label": "大洼恒丰村镇银行（借记卡）"
  },
  {
    "value": "PJJYRB_DEBIT",
    "label": "浦江嘉银村镇银行（借记卡）"
  },
  {
    "value": "PZHCCB_DEBIT",
    "label": "攀枝花银行（借记卡）"
  },
  {
    "value": "QDCCB_CREDIT",
    "label": "青岛银行（信用卡）"
  },
  {
    "value": "QDCCB_DEBIT",
    "label": "青岛银行（借记卡）"
  },
  {
    "value": "QHDB_DEBIT",
    "label": "秦皇岛银行（借记卡）"
  },
  {
    "value": "QHJDRB_DEBIT",
    "label": "齐河胶东村镇银行（借记卡）"
  },
  {
    "value": "QHNX_DEBIT",
    "label": "青海农信（借记卡）"
  },
  {
    "value": "QJSYB_DEBIT",
    "label": "衢江上银村镇银行（借记卡）"
  },
  {
    "value": "QLB_CREDIT",
    "label": "齐鲁银行（信用卡）"
  },
  {
    "value": "QLB_DEBIT",
    "label": "齐鲁银行（借记卡）"
  },
  {
    "value": "QLVB_DEBIT",
    "label": "青隆村镇银行（借记卡）"
  },
  {
    "value": "QSB_CREDIT",
    "label": "齐商银行（信用卡）"
  },
  {
    "value": "QSB_DEBIT",
    "label": "齐商银行（借记卡）"
  },
  {
    "value": "QZCCB_CREDIT",
    "label": "泉州银行（信用卡）"
  },
  {
    "value": "QZCCB_DEBIT",
    "label": "泉州银行（借记卡）"
  },
  {
    "value": "RHCB_DEBIT",
    "label": "长子县融汇村镇银行（借记卡）"
  },
  {
    "value": "RQCZB_DEBIT",
    "label": "任丘村镇银行（借记卡）"
  },
  {
    "value": "RXYHB_DEBIT",
    "label": "瑞信村镇银行（借记卡）"
  },
  {
    "value": "RZB_DEBIT",
    "label": "日照银行（借记卡）"
  },
  {
    "value": "SCB_CREDIT",
    "label": "渣打银行（信用卡）"
  },
  {
    "value": "SCB_DEBIT",
    "label": "渣打银行（借记卡）"
  },
  {
    "value": "SCNX_DEBIT",
    "label": "四川农信（借记卡）"
  },
  {
    "value": "SDEB_CREDIT",
    "label": "顺德农商行（信用卡）"
  },
  {
    "value": "SDEB_DEBIT",
    "label": "顺德农商行（借记卡）"
  },
  {
    "value": "SDRCU_DEBIT",
    "label": "山东农信（借记卡）"
  },
  {
    "value": "SHHJB_DEBIT",
    "label": "商河汇金村镇银行（借记卡）"
  },
  {
    "value": "SHINHAN_DEBIT",
    "label": "新韩银行（借记卡）"
  },
  {
    "value": "SHRB_DEBIT",
    "label": "上海华瑞银行（借记卡）"
  },
  {
    "value": "SJB_CREDIT",
    "label": "盛京银行（信用卡）"
  },
  {
    "value": "SJB_DEBIT",
    "label": "盛京银行（借记卡）"
  },
  {
    "value": "SNB_DEBIT",
    "label": "苏宁银行（借记卡）"
  },
  {
    "value": "SNCCB_DEBIT",
    "label": "遂宁银行（借记卡）"
  },
  {
    "value": "SPDYB_DEBIT",
    "label": "四平铁西敦银村镇银行（借记卡）"
  },
  {
    "value": "SRB_DEBIT",
    "label": "上饶银行（借记卡）"
  },
  {
    "value": "SRCB_CREDIT",
    "label": "上海农商银行（信用卡）"
  },
  {
    "value": "SRCB_DEBIT",
    "label": "上海农商银行（借记卡）"
  },
  {
    "value": "SUZB_CREDIT",
    "label": "苏州银行（信用卡）"
  },
  {
    "value": "SUZB_DEBIT",
    "label": "苏州银行（借记卡）"
  },
  {
    "value": "SXNX_DEBIT",
    "label": "山西农信（借记卡）"
  },
  {
    "value": "SXXH_DEBIT",
    "label": "陕西信合（借记卡）"
  },
  {
    "value": "SZRCB_CREDIT",
    "label": "深圳农商银行（信用卡）"
  },
  {
    "value": "SZRCB_DEBIT",
    "label": "深圳农商银行（借记卡）"
  },
  {
    "value": "TACCB_CREDIT",
    "label": "泰安银行（信用卡）"
  },
  {
    "value": "TACCB_DEBIT",
    "label": "泰安银行（借记卡）"
  },
  {
    "value": "TCRCB_DEBIT",
    "label": "太仓农商行（借记卡）"
  },
  {
    "value": "TJB_CREDIT",
    "label": "天津银行（信用卡）"
  },
  {
    "value": "TJB_DEBIT",
    "label": "天津银行（借记卡）"
  },
  {
    "value": "TJBHB_CREDIT",
    "label": "天津滨海农商行（信用卡）"
  },
  {
    "value": "TJBHB_DEBIT",
    "label": "天津滨海农商行（借记卡）"
  },
  {
    "value": "TJHMB_DEBIT",
    "label": "天津华明村镇银行（借记卡）"
  },
  {
    "value": "TJNHVB_DEBIT",
    "label": "天津宁河村镇银行（借记卡）"
  },
  {
    "value": "TLB_DEBIT",
    "label": "铁岭银行（借记卡）"
  },
  {
    "value": "TLVB_DEBIT",
    "label": "铁岭新星村镇银行（借记卡）"
  },
  {
    "value": "TMDYB_DEBIT",
    "label": "图们敦银村镇银行（借记卡）"
  },
  {
    "value": "TRCB_CREDIT",
    "label": "天津农商（信用卡）"
  },
  {
    "value": "TRCB_DEBIT",
    "label": "天津农商（借记卡）"
  },
  {
    "value": "TZB_CREDIT",
    "label": "台州银行（信用卡）"
  },
  {
    "value": "TZB_DEBIT",
    "label": "台州银行（借记卡）"
  },
  {
    "value": "UOB_DEBIT",
    "label": "大华银行（借记卡）"
  },
  {
    "value": "URB_DEBIT",
    "label": "联合村镇银行（借记卡）"
  },
  {
    "value": "VBCB_DEBIT",
    "label": "村镇银行（借记卡）"
  },
  {
    "value": "WACZB_DEBIT",
    "label": "武安村镇银行（借记卡）"
  },
  {
    "value": "WB_DEBIT",
    "label": "友利银行（借记卡）"
  },
  {
    "value": "WEB_DEBIT",
    "label": "微众银行（借记卡）"
  },
  {
    "value": "WEGOB_DEBIT",
    "label": "蓝海银行（借记卡）"
  },
  {
    "value": "WFB_CREDIT",
    "label": "潍坊银行（信用卡）"
  },
  {
    "value": "WFB_DEBIT",
    "label": "潍坊银行（借记卡）"
  },
  {
    "value": "WHB_CREDIT",
    "label": "威海商业银行（信用卡）"
  },
  {
    "value": "WHB_DEBIT",
    "label": "威海商业银行（借记卡）"
  },
  {
    "value": "WHRC_CREDIT",
    "label": "武汉农商行（信用卡）"
  },
  {
    "value": "WHRC_DEBIT",
    "label": "武汉农商行（借记卡）"
  },
  {
    "value": "WHRYVB_DEBIT",
    "label": "芜湖圆融村镇银行（借记卡）"
  },
  {
    "value": "WJRCB_CREDIT",
    "label": "吴江农商行（信用卡）"
  },
  {
    "value": "WJRCB_DEBIT",
    "label": "吴江农商行（借记卡）"
  },
  {
    "value": "WLMQB_CREDIT",
    "label": "乌鲁木齐银行（信用卡）"
  },
  {
    "value": "WLMQB_DEBIT",
    "label": "乌鲁木齐银行（借记卡）"
  },
  {
    "value": "WRCB_CREDIT",
    "label": "无锡农商行（信用卡）"
  },
  {
    "value": "WRCB_DEBIT",
    "label": "无锡农商行（借记卡）"
  },
  {
    "value": "WUHAICB_DEBIT",
    "label": "乌海银行（借记卡）"
  },
  {
    "value": "WZB_CREDIT",
    "label": "温州银行（信用卡）"
  },
  {
    "value": "WZB_DEBIT",
    "label": "温州银行（借记卡）"
  },
  {
    "value": "WZMSB_DEBIT",
    "label": "温州民商（借记卡）"
  },
  {
    "value": "XAB_CREDIT",
    "label": "西安银行（信用卡）"
  },
  {
    "value": "XAB_DEBIT",
    "label": "西安银行（借记卡）"
  },
  {
    "value": "XCXPB_DEBIT",
    "label": "许昌新浦村镇银行（借记卡）"
  },
  {
    "value": "XHB_DEBIT",
    "label": "大连鑫汇村镇银行（借记卡）"
  },
  {
    "value": "XHNMB_DEBIT",
    "label": "安顺西航南马村镇银行（借记卡）"
  },
  {
    "value": "XIB_DEBIT",
    "label": "厦门国际银行（借记卡）"
  },
  {
    "value": "XINANB_DEBIT",
    "label": "安徽新安银行（借记卡）"
  },
  {
    "value": "XJB_DEBIT",
    "label": "新疆银行（借记卡）"
  },
  {
    "value": "XJJDRB_DEBIT",
    "label": "夏津胶东村镇银行（借记卡）"
  },
  {
    "value": "XJRCCB_DEBIT",
    "label": "新疆农信银行（借记卡）"
  },
  {
    "value": "XMCCB_CREDIT",
    "label": "厦门银行（信用卡）"
  },
  {
    "value": "XMCCB_DEBIT",
    "label": "厦门银行（借记卡）"
  },
  {
    "value": "XRTB_DEBIT",
    "label": "元氏信融村镇银行（借记卡）"
  },
  {
    "value": "XTB_CREDIT",
    "label": "邢台银行（信用卡）"
  },
  {
    "value": "XTB_DEBIT",
    "label": "邢台银行（借记卡）"
  },
  {
    "value": "XWB_DEBIT",
    "label": "新网银行（借记卡）"
  },
  {
    "value": "XXCB_DEBIT",
    "label": "新乡银行（借记卡）"
  },
  {
    "value": "XXHZCB_DEBIT",
    "label": "兴县汇泽村镇银行（借记卡）"
  },
  {
    "value": "XXRB_DEBIT",
    "label": "新乡新兴村镇银行（借记卡）"
  },
  {
    "value": "XYPQZYCB_DEBIT",
    "label": "信阳平桥中原村镇银行（借记卡）"
  },
  {
    "value": "XZB_DEBIT",
    "label": "西藏银行（借记卡）"
  },
  {
    "value": "YACCB_DEBIT",
    "label": "雅安市商业银行（借记卡）"
  },
  {
    "value": "YBCCB_DEBIT",
    "label": "宜宾商业银行（借记卡）"
  },
  {
    "value": "YKCB_DEBIT",
    "label": "营口沿海银行（借记卡）"
  },
  {
    "value": "YLB_DEBIT",
    "label": "亿联银行（借记卡）"
  },
  {
    "value": "YNHTB_CREDIT",
    "label": "云南红塔银行（信用卡）"
  },
  {
    "value": "YNHTB_DEBIT",
    "label": "云南红塔银行（借记卡）"
  },
  {
    "value": "YNRCCB_CREDIT",
    "label": "云南农信（信用卡）"
  },
  {
    "value": "YNRCCB_DEBIT",
    "label": "云南农信（借记卡）"
  },
  {
    "value": "YQCCB_DEBIT",
    "label": "阳泉市商业银行（借记卡）"
  },
  {
    "value": "YQMYRB_DEBIT",
    "label": "玉泉蒙银村镇银行（借记卡）"
  },
  {
    "value": "YRRCB_CREDIT",
    "label": "黄河农商银行（信用卡）"
  },
  {
    "value": "YRRCB_DEBIT",
    "label": "黄河农商银行（借记卡）"
  },
  {
    "value": "YTB_DEBIT",
    "label": "烟台银行（借记卡）"
  },
  {
    "value": "YYBSCB_DEBIT",
    "label": "沂源博商村镇银行（借记卡）"
  },
  {
    "value": "ZCRB_DEBIT",
    "label": "遵义新蒲长征村镇银行（借记卡）"
  },
  {
    "value": "ZGB_DEBIT",
    "label": "自贡银行（借记卡）"
  },
  {
    "value": "ZGCB_DEBIT",
    "label": "北京中关村银行（借记卡）"
  },
  {
    "value": "ZHCB_DEBIT",
    "label": "庄河汇通村镇银行（借记卡）"
  },
  {
    "value": "ZHQYTB_DEBIT",
    "label": "沾化青云村镇银行（借记卡）"
  },
  {
    "value": "ZJB_DEBIT",
    "label": "紫金农商银行（借记卡）"
  },
  {
    "value": "ZJLXRB_DEBIT",
    "label": "兰溪越商银行（借记卡）"
  },
  {
    "value": "ZJRCUB_CREDIT",
    "label": "浙江农信（信用卡）"
  },
  {
    "value": "ZJRCUB_DEBIT",
    "label": "浙江农信（借记卡）"
  },
  {
    "value": "ZJTLCB_CREDIT",
    "label": "浙江泰隆银行（信用卡）"
  },
  {
    "value": "ZJTLCB_DEBIT",
    "label": "浙江泰隆银行（借记卡）"
  },
  {
    "value": "ZRCB_CREDIT",
    "label": "张家港农商行（信用卡）"
  },
  {
    "value": "ZRCB_DEBIT",
    "label": "张家港农商行（借记卡）"
  },
  {
    "value": "ZSXKCCB_DEBIT",
    "label": "中山小榄村镇银行（借记卡）"
  },
  {
    "value": "ZYB_CREDIT",
    "label": "中原银行（信用卡）"
  },
  {
    "value": "ZYB_DEBIT",
    "label": "中原银行（借记卡）"
  },
  {
    "value": "ZZB_CREDIT",
    "label": "郑州银行（信用卡）"
  },
  {
    "value": "ZZB_DEBIT",
    "label": "郑州银行（借记卡）"
  },
  {
    "value": "ZZCCB_DEBIT",
    "label": "枣庄银行（借记卡）"
  },
  {
    "value": "DINERSCLUD",
    "label": "DINERSCLUD"
  },
  {
    "value": "MASTERCARD",
    "label": "MASTERCARD"
  },
  {
    "value": "VISA",
    "label": "VISA"
  },
  {
    "value": "AMERICANEXPRESS_CREDIT",
    "label": "AMERICANEXPRESS"
  },
  {
    "value": "DISCOVER",
    "label": "DISCOVER"
  },
  {
    "value": "UPQUICKPASS_CREDIT",
    "label": "云闪付（信用卡）"
  },
  {
    "value": "OTHERS",
    "label": "其他（银行卡以外）"
  }
]
