import { defineI18nModuleCreator } from "@verdure/core";

export default defineI18nModuleCreator<{
    personalCenter: string,
    logout: string,
    operate: string,
    tabCloseAll: string,
    tabCloseLeft: string,
    tabCloseRight: string,
}>('layout', {
    "zh-CN": {
        personalCenter: '个人中心',
        logout: '退出登录',
        operate: '操作',
        tabCloseAll: '关闭所有页签',
        tabCloseLeft: '关闭当前页签左侧所有',
        tabCloseRight: '关闭当前页签右侧所有',
    },
    "en-US": {
        personalCenter: 'Personal Center',
        logout: 'Logout',
        operate: 'Operate',
        tabCloseAll: 'Close all tabs',
        tabCloseLeft: 'Close everything on the left side of the current TAB',
        tabCloseRight: 'Close everything on the right of the current TAB',
    }
})
