<template>
    <div class="v-json-viewer">
        <div class="main-content" :style="{ height: height === 0 ? 'auto' : `${height}px` }">
            <el-scrollbar v-if="edit" class="item-l" style="width: 50%">
                <div class="edit" @click="clickInput">
                    <div ref="inputContent"
                         class="edit-input"
                         placeholder="请在这输入内容"
                         @blur="blueInput"
                         contenteditable="true">
                        {{ json }}
                    </div>
                </div>
            </el-scrollbar>
            <el-scrollbar class="item-l" :style="{width: edit ? '50%' : '100%'}">
                <div class="preview">
                    <div v-if="error" class="error">{{ error }}</div>
                    <JsonBox v-else="error" :key="boxKey" :json="jsonData" :index="index" :level="1"></JsonBox>
                </div>
            </el-scrollbar>
        </div>
    </div>
</template>

<script setup lang="ts">
    // import {ref, defineProps, withDefaults, Ref} from 'vue'
    import { ref, Ref } from 'vue'
    // @ts-ignore
    import JsonBox from './JsonBox.vue'

    const props = withDefaults(defineProps<{
        json: any,
        index: string,
        height: number,
        edit: boolean
    }>(), {
        height: 100,
        edit: false
    });
    const inputContent: Ref<{
        focus: () => {},
        innerText: string
    }> = ref({} as any);
    const boxKey: Ref<number> = ref(0);

    const jsonData: Ref<any> = ref(props.json);
    const error: Ref<string | null> = ref(null);

    function clickInput() {
        inputContent.value.focus();
    }
    function blueInput() {
        const text = inputContent.value.innerText.replaceAll('\n', '').replaceAll(' ', '');
        try {
            jsonData.value = JSON.parse(text);
            boxKey.value = new Date().getTime();
            error.value = null;
        } catch (e: any) {
            error.value = e.toString();
        }
    }
</script>
<style lang="scss">
    .v-json-viewer {
        margin: 15px 0;
        .error {
            color: rgba(251,45,7,0.86);
            padding: 5px;
        }
        .main-content {
            position: relative;
            display: flex;
            border: 1px #999 solid;
            border-radius: 10px;
            .item-1 {
                flex-shrink: 0;
            }
            .el-scrollbar__view {
                height: 100%;
            }
            .edit {
                padding: 5px;
                min-width: 200px;
                min-height: 100%;
                padding-right: 10px;
                cursor: text;
                border-right: 1px #999 solid;
                .edit-input {
                    min-height: 100%;
                    &:focus-visible {
                        outline: none;
                    }
                }
                .edit-input[contenteditable]:empty:before {
                    content: attr(placeholder);
                    color: #cccccc;
                }
                .edit-input[contenteditable]:focus {
                    content: none;
                }
            }
            .preview {
                padding-left: 38px;
                padding-top: 5px;
                padding-bottom: 5px;
                &:after {
                    position: absolute;
                    content: "";
                    width: 25px;
                    height: 100%;
                    background: rgba(249, 249, 249, 0.75);
                    top: 0;
                    left: 0;
                }
            }
        }
    }
</style>


