import { defineI18nModuleCreator } from "@verdure/core";

export default defineI18nModuleCreator<{
    pageDoesNotExist: string,
    permissionDenied: string,
    returnIndex: string,
    yes: string,
    no: string,
}>('system', {
    "zh-CN": {
        pageDoesNotExist: '您访问的页面不存在',
        permissionDenied: '您没有访问权限',
        returnIndex: '返回首页',
        yes: '是',
        no: '否',
    },
    "en-US": {
        pageDoesNotExist: 'The page you visited does not exist',
        permissionDenied: 'You don\'t have access',
        returnIndex: 'Return Index',
        yes: 'Yes',
        no: 'No',
    }
})
