import {TableCard} from "./component/table-card";
import {FormModal} from "./component/form-modal";
import {RootRouterView} from "./component/root-router-view";
import {LocalSelect} from "./component/local-select";
import {FullContent} from "./component/full-content";
import {DictSelect, DictTag, DictRadio} from "./component/dict";
import {IconSelect} from "./component/icon-select";
import {JsonViewer} from "./component/json-viewer";
import { App } from 'vue';
import ElementPlus from 'element-plus'
import 'bootstrap-icons/font/bootstrap-icons.css'
import 'element-plus/dist/index.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'xe-utils'
import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'
// 导入图标库
import {library} from '@fortawesome/fontawesome-svg-core'
import {fas} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'

import {defineModuleCreator, readConfigToI8n, useConfigKey} from ".";

// 导入国际化
import i18n from './i18n'
// 导入缓存
import store from './store'
// 导入视图配置
import router from './router'
import {CoreConfig} from "./typings";

export default defineModuleCreator<CoreConfig>({
    id: 'core',
    config: {
        request: {
            baseUrl: '',
            publicKey: ''
        },
        size: {
            elementPlus: 'default',
            vxeTable: 'medium'
        },
        local: window.localStorage.getItem('outlier-local') || 'zh-CN',
        localStorePrefix: 'VERDURE-'
    },
    i18n,
    store,
    router,
    enhanceVue(vue: App) {
        library.add(fas);
        // 注册组件
        vue.component('fa', FontAwesomeIcon);
        vue.use(TableCard); // 注册表格组件
        vue.use(FormModal); // 注册表格组件
        vue.use(LocalSelect); // 注册国际化选择器
        vue.use(RootRouterView); // 注册根视图
        vue.use(FullContent); // 撑满主容器的一个容器（可自动计算高度）
        vue.use(IconSelect); // 图标选择器
        vue.use(ElementPlus); // 注册 ElementPlus
        vue.use(VXETable); // 注册 VXETable
        vue.use(JsonViewer); // JSON可视化
        vue.use(DictSelect); // 字典选择器
        vue.use(DictRadio); // 字典选择单选按钮
        vue.use(DictTag); // 字典选TAG
    },
    appCreateDone() {
        const config = useConfigKey('core');
        readConfigToI8n(config, 'systemName', 'system');
        readConfigToI8n(config, 'LoginSystemName', 'system');
    }
});
