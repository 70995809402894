import { defineI18nModuleCreator } from "@verdure/core";

export default defineI18nModuleCreator<{
    editPwd: string,
    editInfo: string,
    hint: {
        passwordOld: string,
        newPwd1: string,
        newPwd2: string,
        saveInfoError: string,
        saveInfoSuccess: string,
        savePasswordError: string,
        savePasswordSuccess: string,
        TwoPwdNoEq: string,
    },
    pwd: {
        passwordOld: string,
        newPwd1: string,
        newPwd2: string,
    },
    submitEdit: string
}>('userCenter', {
    "zh-CN": {
        editPwd: "修改密码",
        editInfo: "编辑信息",
        hint: {
            passwordOld: "请输入原密码",
            newPwd1: "请输入新密码",
            newPwd2: "请确认新密码",
            saveInfoError: '信息保存失败',
            saveInfoSuccess: '信息保存成功',
            savePasswordError: '密码修改失败',
            savePasswordSuccess: '密码修改成功',
            TwoPwdNoEq: '两密码不一致',
        },
        pwd: {
            passwordOld: '旧密码',
            newPwd1: '新密码',
            newPwd2: '确认密码',
        },
        submitEdit: '确认修改'
    },
    "en-US": {
        editPwd: "Edit Password",
        editInfo: "Edit User Info",
        hint: {
            passwordOld: "Please enter the old password",
            newPwd1: "Please enter a new password",
            newPwd2: "Please confirm the new password",
            saveInfoError: 'Information saving failed',
            saveInfoSuccess: 'Information saved successfully',
            savePasswordError: 'Password change failed',
            savePasswordSuccess: 'Password changed successfully',
            TwoPwdNoEq: 'The two passwords do not match',
        },
        pwd: {
            passwordOld: 'Old Password',
            newPwd1: 'New Password',
            newPwd2: 'Enter Password',
        },
        submitEdit: 'Update Acknowledge'
    }
})
