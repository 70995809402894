<template>
    <template v-if="dict.dict[type]">
        <el-radio-group v-model="sev" :size="size.elementPlus" @change="changeValue">
            <el-radio-button v-for="item in dict.dict[type]" :label="item.code">{{ item.value }}</el-radio-button>
        </el-radio-group>
    </template>
    <!--<dict-radio v-model="value" type="TicketState"></dict-select>-->
</template>

<script setup lang="ts">
    // import { defineProps, ref, nextTick, defineEmits, watch, withDefaults } from 'vue'
    import {ref, nextTick, watch, } from 'vue'
    import {initDict} from "./InitDictUtil";
    import {useConfigKey, useStore} from "../../..";

    const size = ref(useConfigKey('core').size);

    const store: any = useStore();

    const dict = store.dict;

    const props = withDefaults(defineProps<{
        value: any,
        placeholder: string,
        type: string,
        multiple: boolean // 是否多选
    }>(), {
        multiple: false
    });

    const emits = defineEmits<{
        (event: 'update:value', val: any): void
    }>();

    const sev = ref('');
    watch([() => props.value], () => {
        sev.value = props.value;
    });

    nextTick(() => {
        initDict([props.type])
    });

    function changeValue() {
       emits('update:value', sev.value)
    }
</script>

<style>
</style>
