<template>
    <div class="icon-box">
        <div class="item item-1" v-for="item in showIcons" :data-icon="item" :title="item" @click="clickIcon(item)">
            <fa :icon="item"/>
        </div>
    </div>
    <div style="display:flex;justify-content: flex-end">
        <el-pagination
                v-model:currentPage="page"
                :page-size="100"
                :small="size.elementPlus"
                background
                layout="total, prev, pager, next, jumper"
                :total="ic.length"
                @current-change="handleCurrentChange"
        ></el-pagination>
    </div>
</template>

<script setup lang="ts">
    // import {ref, defineEmits, Ref} from 'vue'
    import { ref, Ref } from 'vue'
    import {useConfigKey} from "../../..";
    import ic from './icon'

    const size = ref(useConfigKey('core').size);

    const page = ref(1);
    const showIcons: Ref<string[]> = ref([]);

    const emits = defineEmits<{
        (event: 'icon', icon: string): void
    }>();

    handleCurrentChange(page.value);

    function handleCurrentChange(v: number) {
        showIcons.value = ic.slice((v - 1) * 100, ((v) * 100))
    }

    function clickIcon(icon: string) {
        emits('icon', icon)
    }
</script>

<style lang="scss">
    .icon-box {
        display: flex;
        flex-wrap: wrap;

        .item {
            width: 70px;
            margin: 5px;
            background: rgba(231, 229, 231, 0.86);
            height: 70px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            font-size: 40px;
            &:hover {
                background: rgba(243,241,243,0.86);
            }
        }
    }
</style>
