import { defineI18nModuleCreator } from "@verdure/core";

export default defineI18nModuleCreator<{
    addNew: string,
    editForm: string,
    table: {
        name: string,
        cron: string,
        beanName: string,
        methodName: string,
        flag: string,
        init: string,
    },
    hint: {
        name: string,
        cron: string,
        beanName: string,
        methodName: string,
        openTask: string,
        closeTask: string,
        flagInfo: string,
        openError: string,
        closeError: string,
        openSuccess: string,
        closeSuccess: string,
    }
}>('scheduling', {
    "zh-CN": {
        addNew: '添加新的定时任务调度',
        editForm: '修改定时任务调度',
        table: {
            name: '名称',
            cron: 'cron 表达式',
            beanName: 'Bean 名称',
            methodName: '方法名称',
            flag: '状态',
            init: '初始化情况',
        },
        hint: {
            name: '请输入调度名称',
            cron: '请输入 cron 表达式',
            beanName: '请输入 Bean 名称',
            methodName: '请输入方法名称',
            openTask: '您确定要启动该定时任务调度吗？',
            closeTask: '您确定要关闭该定时任务调度吗？',
            flagInfo: '提示',
            openError: '启动定时调度发生错误',
            closeError: '关闭定时调度发生错误',
            openSuccess: '启动定时调度成功',
            closeSuccess: '关闭定时调度成功',
        }
    }
})
