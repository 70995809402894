import {defineI18nModuleCreator} from "../../utils/creator/I18nCreator";

export default defineI18nModuleCreator<{
    cancel: string,
    submit: string,
    hint: {
        editSuccess: string,
        editError: string,
        insertSuccess: string,
        insertError: string,
    },
    modal: string,
}>('formModal', {
    "zh-CN": {
        cancel: '取消',
        submit: '确定',
        hint: {
            editSuccess: '修改成功',
            editError: '修改失败',
            insertSuccess: '添加成功',
            insertError: '添加失败',
        },
        modal: '弹窗',
    },
    "en-US": {
        cancel: 'Cancel',
        submit: 'Submit',
        hint: {
            editSuccess: 'Modify successfully',
            editError: 'Fail to modify',
            insertSuccess: 'Add successfully',
            insertError: 'Fail to add',
        },
        modal: 'Modal',
    }
});
