import {createI18n} from "vue3-i18n";
import {useI18n, useI18nData, useSystemRouter, useVueApp, useVueRouter} from "..";
import BuilderRouterTree from "../utils/BuilderRouterTree";
import {__registryI18n, __useStore, __useSystemRouterMap, __useChildrenRouterPath} from "../utils/data/VerdureData";

export type I18nType = 'zh-CN' | 'en-US'

export type VerdureRouter = {
    id: string,
    pid: string,
    code: string,
    path: string, // 访问地址
    name?: string, // 若不填默认使用系统自带的名称
    meta?: Record<string, any>
    children: Record<string, any>
}


export class VerdureApp {
    constructor() {
    }

    // 使用组件或者模块
    use(fun: () => void) : VerdureApp{
        fun();
        return this;
    }

    /**
     * 重载路由
     * @param router 路由地址配置
     * @returns
     */
    reloadRouter(router: VerdureRouter[]): VerdureApp {
        console.log('system', { title: '路由重构' });
        const paths: string[] = [];
        router.forEach(item => paths.push(item.path));
        // 混合系统自定义的路由地址
        useSystemRouter().forEach(item => {
            if (!paths.includes(item.path)) {
                // 合并时如果该地址被后台定义，则忽略系统定义地址
                router.push(item);
            }
        });
        // 构建路由树，并注册路由
        const rou = new BuilderRouterTree(router).build();
        const pathsCache: any = {};
        rou && rou.forEach(item => {
            useVueRouter().addRoute(item);
        });
        useVueRouter().getRoutes().forEach(item => {
            pathsCache[item.path] = item;
        });
        // todo 这里由于方法会执行两次，第一次只注册了4个静态路径，忽略不进行后续判断，防止进入404 ！！！！！，若后续更改出现404 问题，则在此处打断点更改数字
        if (Object.keys(pathsCache).length === 4) {
            return this;
        }
        if (pathsCache[(window as any).document.location.hash.replace('#', '')] === undefined) {
            useVueRouter().replace('/page');
            return this;
        }
        let toPath = window.document.location.hash.replace('#', '').split('?')[0];
        const systemRouter = __useSystemRouterMap();
        const pathsRouterChildren = __useChildrenRouterPath();
        if (systemRouter[toPath] || (pathsRouterChildren && pathsRouterChildren.path[toPath])) {
            toPathFun()
        }

        function toPathFun() {
            // 系统路由缓存对象中有这个视图再进行跳转
            // vue router bug 需要进行跳转，默认打开无法识别路由
            useVueRouter().replace(window.document.location.hash.replace('#', ''));
            const { menu }: any = __useStore();
            if (!menu.cacheViews.includes(toPath)) {
                menu.cacheViews.push(toPath);
            }
            menu.nowOpenView = toPath;
        }
        // this.app && this.app.use(this.router);
        return this;
    }

    // 重载i18n国际化配置
    reloadI18n() {
        const vueApp = useVueApp();
        this._readI18n();
        // 注册国际化
        vueApp.use(useI18n());
    }

    /**
     * 读取i18n合并数据
     * @private
     */
    private _readI18n() {
        const i18nData = useI18nData();
        const res: Record<string, any> = {};
        for (let code in i18nData) {
            const item: any = i18nData[code];
            for (let ik in item.message) {
                if (res[ik] === undefined) res[ik] = {};
                if (res[ik][code] === undefined) res[ik][code] = {};
                Object.assign(res[ik][code], item.message[ik])
            }
        }
        // 注册i18n实例
        __registryI18n(createI18n({
            locale: 'zh-CN',
            messages: res
        }))
    }

    /**
     * 生成实例
     * @param rootContainer
     */
    create(rootContainer: string) {
        const vueApp = useVueApp();
        // 重载i18n国际化配置
        this.reloadI18n();
        // 绑定主容器
        vueApp.mount(rootContainer);
    }

}

