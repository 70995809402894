import { Get } from '..'

@Get({method: 'dict', url: '/api/system/dict/type'})
class ApiDict {

}

// 声明接口类的函数
interface ApiDict {
    dict(params: Record<string, any>): Promise<any>
}

export const apiDict = new ApiDict() as ApiDict;
