<template>
    <div class="box" :class="[index ? '' : 'jv-root']" v-if="typeof json === 'string'" >
            <span v-if="index">
                <span class="field">"</span>
                <span class="field">{{ index }}</span>
                <span class="field">"</span>
                <span>:&nbsp;</span>
            </span>
        <span class="str">"{{ json }}"</span>
        <span class="end">, </span>
    </div>
    <div class="box" :class="[index ? '' : 'jv-root']" v-if="typeof json === 'boolean'" >
            <span v-if="index">
                <span class="field">"</span>
                <span class="field">{{ index }}</span>
                <span>"</span>
                <span class="field">:&nbsp;</span>
            </span>
        <span class="boolean">{{ json }}</span>
        <span class="end">, </span>
    </div>
    <div class="box" :class="[index ? '' : 'jv-root']" v-if="typeof json === 'number'" >
            <span v-if="index">
                <span class="field">"</span>
                <span class="field">{{ index }}</span>
                <span class="field">"</span>
                <span>:&nbsp;</span>
            </span>
        <span class="number">{{ json }}</span>
        <span class="end">, </span>
    </div>
    <div class="box" :class="[index ? '' : 'jv-root']" v-if="json.constructor === Array" >
        <span>
            <span v-if="index">
                <span class="field">"</span>
                <span class="field">{{ index }}</span>
                <span class="field">"</span>
                <span>:&nbsp;</span>
            </span>
            [
            <span>
                <span class="close" onclick="clickClose(this)" :style="{ left: `-${(level === 1 ? 1 : level - 1) * 31}px` }" :data-level="level">
                    <fa icon="caret-left"></fa>
                </span>
                <span style="display: none">... ]</span>
            </span>
        </span>
        <div style="position: relative">
            <div class="json-viewer">
                <JsonBox v-for="(item, key) in json" :index="key" :json="item" :level="level + 1"></JsonBox>
            </div>
            <span>]</span>
            <span class="end">, </span>
        </div>
    </div>
    <div class="box" :class="[index ? '' : 'jv-root']" v-if="json.constructor === Object" >
        <span>
            <span v-if="index">
                <span class="field">"</span>
                <span class="field">{{ index }}</span>
                <span class="field">"</span>
                <span>:&nbsp;</span>
            </span>
            {
            <span>
                <div class="close" onclick="clickClose(this)" :style="{ left: `-${(level === 1 ? 1 : level - 1) * 31}px` }" :data-level="level">
                    <fa icon="caret-left"></fa>
                </div>
                <span style="display: none">... }</span>
            </span>
        </span>
        <div style="position: relative">
            <div class="json-viewer">
                <JsonBox v-for="(item, key) in json" :json="item" :index="key" :level="level + 1"></JsonBox>
            </div>
            <span>}</span>
            <span class="end">, </span>
        </div>
    </div>
</template>

<script setup lang="ts">
    // import {ref, defineProps} from 'vue'
    import { ref } from 'vue'

    const props = defineProps<{
        json: any,
        index: string | number,
        level: number,
    }>();

    (window as any).clickClose = function (e: any) {
        const display = e.parentElement.parentElement.nextElementSibling.style.display;
        if (display === "" || display === 'block') {
            e.parentElement.parentElement.nextElementSibling.style.display = 'none'
            e.childNodes[0].style.transform = 'rotate(-90deg)'
            e.nextElementSibling.style.display = 'inline-block';
        } else {
            e.parentElement.parentElement.nextElementSibling.style.display = 'block'
            e.childNodes[0].style.transform = 'rotate(0)'
            e.nextElementSibling.style.display = 'none';
        }
    }
</script>

<style lang="scss">
    .jv-root {
        position: relative;
        .number {
            color: rgba(46, 119, 243, 0.86);
        }
        .str {
            color: rgba(19,197,53,0.86);
        }
        .field {
            color: rgba(22, 22, 22, 0.86);
        }
        .boolean {
            color: rgba(193,4,79,0.86);
        }
        .json-viewer {
            margin-left: 5px;
            padding-left: 25px;
            border-left: 1px #CCC dashed;
            > .box:last-child {
                >.end {
                    display: none;
                }
                >div > .end {
                    display: none;
                }
            }
        }
        .close {
            display: inline-block;
            cursor: pointer;
            transform: rotate(-90deg);
            color: #787878;
            position: absolute;
            left: 10px;
            z-index: 10;
            &:hover {
                color: #444;
            }
        }
        > div > .end {
            display: none;
        }
    }
</style>
