import {Component, reactive, UnwrapNestedRefs} from 'vue';

export interface ExposeSysUser {
    slot: {
        formAfterEnd?: Component | null, // 在表单最后面追加自定义表单内容
        tableAfterEnd?: Component | null, // 在表格最后面追加自定义表单内容
        queryAfterEnd?: Component | null, // 在查询表单最后面追加自定义表单内容
    },
    beforeSave?: (form: any) => void
    beforeInitForm?: (form: any) => void
    beforeInitListData?: (form: any) => void
}

export const exposeUserConfig: UnwrapNestedRefs<ExposeSysUser> = reactive({
    slot: {
    },
    beforeSave: (form) => {
    },
    beforeInitForm: (form) => {
    },
});


/**
 * 拓展自定义用户管理
 * @param config 配置
 */
export function customSysUser(config: {
    formAfterEndSlot?: Component
    tableAfterEndSlot?: Component
    queryAfterEndSlot?: Component
}) {
    exposeUserConfig.slot.formAfterEnd = config.formAfterEndSlot;
    exposeUserConfig.slot.tableAfterEnd = config.tableAfterEndSlot;
    exposeUserConfig.slot.queryAfterEnd = config.queryAfterEndSlot;
}
