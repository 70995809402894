import {isNullAndNew} from "./utils";
import BuilderRouterTreeUtil from './utils/BuilderRouterTree'

import './styles/index.scss'
import {initDict} from "./component/dict/src/InitDictUtil";

export * from './utils/install';
export * from './utils/index'
export * from './entity/Result'
export * from './utils/api'
export * from './component/instance'
export * from './component/dict/src/InitDictUtil'
export const BuilderRouterTree = BuilderRouterTreeUtil;

export * from './utils/creator/ModuleCreator'
export * from './utils/creator/I18nCreator'
export * from './utils/creator/RouterCreator'
export * from './utils/creator/StoreCreator'
export * from './utils/creator/VerdureAppCreator'
export * from './utils/creator/ViewSlotCreator'
export * from './typings/index'

// 窗口大小改变订阅者
const windowOnResizeListener: ((object: OnResizeParams)=> void)[] = [];

// --------------------------------------------------------------------
import {
    __useI18n,
    useOtherConfig,
    __registryAppCreateDoneCallback,
    __useChildrenRouterPath, __useVueRouter, __useStore
} from './utils/data/VerdureData'
import {OnResizeParams, ViewType} from "./typings/index";
export {
    __useStore as useStore, // 导出使用缓存对象的方法
    __useVerdureApp as useVerdureApp, // 导出应用实例
    __useVueApp as useVueApp, // 导出vue实例
    __useVueRouter as useVueRouter,
    __useConfig as useConfig, // 获取配置
    __useConfigKey as useConfigKey, // 获取配置
    __useViews as useViews, // 获取视图配置对象
    __useSystemRouter as useSystemRouter,
    __useSystemRouterMap as useSystemRouterMap, // 系统路由
    __registrySystemRouter as registrySystemRouter,
    __useI18nData as useI18nData, // 获取国际化数据配置对象
    __registryAppCreateDoneCallback as verdureCreateDone,
    __useChildrenRouterPath as useChildrenRouterPath,
    __useModuleViewSlot as useModuleViewSlot, // 获取视图插槽
} from './utils/data/VerdureData'
// 导出国际化函数
export let $t: (key: string, params?: any) => string = (key: string, params?: any) => __useI18n().t(key, params);
export const useI18n = __useI18n;
// --------------------------------------------------------------------

/**
 * 通过视图标识key 进行路由跳转，该函数只支持子路由跳转，基本路由配置的key 跳转不被支持
 * @param childrenViewCode 子路由的标识
 * @param query 参数列表
 */
export function routerCodeTo(childrenViewCode: string, query?: Record<string, any>) {
    const item = __useChildrenRouterPath().code[childrenViewCode];
    const router = __useVueRouter();
    router.push({
        path: item.path,
        query
    });
    const { menu }: any = __useStore();
    const views: Array<any> = menu.cacheViews;
    !views.includes(item.path) && views.push(item.path);
    menu.nowOpenView = item.path
}

/**
 * 使用数据字典
 * @param types 类型列表
 */
export function useDict(types: string[]) {
    initDict(types);
}

/**
 * 监听窗口大小改变事件
 * @param fun
 */
export const onResize = function(fun: (object: OnResizeParams) => void): void {
    windowOnResizeListener.push(fun)
};

/**
 * 窗口改变的回调
 */
export const onresize = function() {
    // 计算视图大小
    const width = document.body.clientWidth;
    computedViewSize(width);
    // 调用订阅者
    for (let index in windowOnResizeListener) {
        windowOnResizeListener[index]({
            viewType: useOtherConfig().viewSize,
            width
        } as OnResizeParams);
    }
};
window.onresize = onresize;

function computedViewSize(width: number) {
    if (width < 768) {
        useOtherConfig().viewSize = 'xs'
    } else if (width >= 768 && width < 992) {
        useOtherConfig().viewSize = 'sm'
    } else if (width >= 992 && width < 1200) {
        useOtherConfig().viewSize = 'md'
    } else if (width >= 1200 && width < 1600) {
        useOtherConfig().viewSize = 'lg'
    } else if (width >= 1600 && width < 1920) {
        useOtherConfig().viewSize = 'xg'
    } else {
        useOtherConfig().viewSize = 'xl'
    }
}

export default {};
