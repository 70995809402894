<template>
    <template v-if="dict.dict[type]">
        <el-select  v-model="selectValue" :size="size.elementPlus" :placeholder="placeholder" clearable
                   @change="changeValue" :multiple="multiple">
            <template v-for="item in dict.dict[type]" >
                <el-option :value="item.code" :label="item.value" v-if="hideOption(item.code)"></el-option>
            </template>
        </el-select>
    </template>
    <!--<dict-select v-model="value" type="TicketState"></dict-select>-->
</template>

<script setup lang="ts">
    // import {defineProps, ref, nextTick, defineEmits, watch, withDefaults, Ref} from 'vue'
    import {ref, nextTick, watch, Ref} from 'vue'
    import {initDict} from "./InitDictUtil";
    import {useConfigKey, useStore} from "../../..";

    const size = ref(useConfigKey('core').size);

    const store: any = useStore();

    const dict = store.dict;

    const props = withDefaults(defineProps<{
        modelValue: any,
        placeholder: string,
        type: string,
        multiple: boolean, // 是否多选
        hideOption: (o: any) => boolean
    }>(), {
        placeholder: '',
        multiple: false,
        hideOption: (o: any) => true
    });

    const selectValue: Ref<any> = ref();

    const emits = defineEmits<{
        (event: 'update:modelValue', val: any): void
    }>();

    selectValue.value = props.modelValue;
    // const sev = ref();
    watch([() => props.modelValue], () => {
        selectValue.value = props.modelValue;
    });

    nextTick(() => {
        initDict([props.type])
    });

    function changeValue(v: any) {
        selectValue.value = v;
        emits('update:modelValue', v)
    }
</script>

<style>
.el-select .is-focus .el-input__wrapper {
    box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%) !important;
    border: 0.35rem solid rgb(13 110 253 / 25%) !important;
    border-radius: 0.4rem;
}
</style>
