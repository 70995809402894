
import axios from "axios";
import { useStore } from "@verdure/core";

function textToLabel(array) {
  for(let i = 0; i < array.length; i++) {
    array[i].label = array[i].text
    if(array[i].children) {
      textToLabel(array[i].children)
    }
  }
  return array
}

async function treeToObj() {
  let data = await getJSONData()
  return mapChildren(data,{})
}

function mapChildren (children,obj) {
  for(let a = 0; a < children.length; a++) {
    obj[`${children[a].value}`]=children[a].label
    if(children[a].children) {
      mapChildren(children[a].children,obj)
    }
  }
  return obj
}

function getJSONData() {
  return new Promise((resolve, reject) => {
    axios
      .get(
        "https://ssfq.obs.cn-north-4.myhuaweicloud.com/static/areaCode/app-code.json"
      )
      .then((res) => {
        resolve(textToLabel(res.data))
      })
      .catch((err) => {
        reject(err);
      });
  });
}

let regionData: any = await getJSONData();
let codeToText: any = await treeToObj();
console.log('codeToText',codeToText)
const areaData = function () {
  const { onlineUser }: any = useStore();
  let te = onlineUser.info?.expand?.areaCode;
  // 如果当前登陆用户areaCode无数据 默认为超级管理员 areaCode：13
  te = te ? te : "13-";
  if (te) {
    const tes = te.split("-");
    let res = [];
    for (let index in regionData) {
      const itemP = regionData[index];
      if (tes[0]) {
        if (itemP.value === tes[0]) {
          const resP: any = {
            label: itemP.label,
            value: itemP.value,
            children: [],
          };
          if (tes[1]) {
            itemP.children.forEach((itc: any) => {
              if (itc.value === tes[1]) {
                const resC: any = {
                  label: itc.label,
                  value: itc.value,
                  children: [],
                };

                if (tes[2]) {
                  itc.children.forEach((ita: any) => {
                    if (ita.value === tes[2]) {
                      const resA: any = {
                        label: ita.label,
                        value: ita.value,
                        children: [],
                      };
                      // console.log('111',tes[3],itc);
                      if (tes[3]) {
                        ita.children.forEach((itb: any) => {
                          if (itb.value === tes[3]) {
                            const resB: any = {
                              label: itb.label,
                              value: itb.value,
                              children: [],
                            };
                            // console.log('222',resB);
                            resA.children.push(resB);
                            // console.log('333',resA);
                          }
                        });
                      } else {
                        resA.children = ita.children;
                      }
                      resC.children.push(resA);
                      // console.log('444',resC);
                    }
                  });
                } else {
                  resC.children = itc.children;
                }

                resP.children.push(resC);
                // console.log('555',resP);
              }
            });
          } else {
            resP.children = itemP.children;
          }
          res.push(resP);
        }
      } else {
        res = regionData;
      }
    }
    // console.log("666666", res);
    return res;
  }
  console.log("787878", regionData);
  return regionData;
};

export { regionData, codeToText, areaData };
