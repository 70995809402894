import {defineStore} from "pinia";
import {apiAuth} from "../../api/api";
import {MenuInfo, ModuleInfo} from "../../entity/Response";
import {useConfigKey, registrySystemRouter, useVerdureApp, BuilderRouterTree, useViews} from '@verdure/core'
import {Menu} from '../type'

export default defineStore<string, Menu>('menu', {
    state: () => {
        return {
            loading_initModule: false,
            modules: null,
            nowModuleId: '',
            nowExpandMenu: true,
            moduleMenu: {},
            cacheViews: [],
            nowOpenView: '',
            changeView: false
        }
    },
    getters: {
        // 获取模块列表
        modules: (store: any) => {
            const state: Menu = store.$state;
            if (state.modules === null) {
                state.modules = {};
                store.initModule();
            }
            return store.$state.modules;
        },
        // 模块菜单
        moduleMenu: (store: any) => {
            const state: Menu = store.$state;
            return state.moduleMenu[state.nowModuleId!];
        },
    },
    actions: {
        changeViewFlag(flag: boolean) {
            const state: Menu = this.$state;
            state.changeView = flag;
        },
        async initModule() {
            const state: Menu = this.$state;
            if (state.loading_initModule) {
                return;
            }
            state.loading_initModule = true;
            const config: any = useConfigKey('core');
            if (state.modules) {
                // 如果已经拿到了，就不用重复获取了
                await (this as any).computedModule(state.modules, state, config)
            } else {
                apiAuth.modules().then(async (data: Record<string, ModuleInfo>) => {
                    state.modules = data;
                    await (this as any).computedModule(data, state, config)
                }).catch(error => {
                    console.log(error)
                    state.loading_initModule = false;
                })
            }
        },
        async computedModule(data: any, state: any, config: any) {
            // 把模块注册到视图对象中
            for (let dataKey in data) {
                registrySystemRouter(9999, {
                    id: dataKey,
                    pid: '0',
                    code: data[dataKey].code,
                    path: '/' + data[dataKey].path,
                    name: data[dataKey].name,
                    meta: {
                        icon: data[dataKey].icon
                    },
                    children: []
                });
            }
            const moduleIds = Object.keys(state.modules);
            // 获取当前选中的模块设置到 nowModuleId
            const nowSelectModuleId = window.localStorage.getItem(config.localStorePrefix + 'nowSelectModuleId') || null;
            if (nowSelectModuleId) {
                state.nowModuleId = nowSelectModuleId;
            } else if (moduleIds.length > 0) {
                state.nowModuleId = moduleIds[0];
                window.localStorage.setItem(config.localStorePrefix + 'nowSelectModuleId', state.nowModuleId);
            }
            await (this as any).loadModuleMenu();
        },
        // 加载模块菜单
        async loadModuleMenu() {
            const views = useViews();
            const state: Menu = this.$state;
            // 获取模块菜单，如果模块没有加载过菜单
            (state.nowModuleId && state.moduleMenu[state.nowModuleId!] === undefined) && apiAuth.menus({
                moduleId: state.nowModuleId
            }).then((da: MenuInfo[]) => {
                try{
                    console.log('system', {code: 'data-find', title: '设置用户菜单及用户权限', content: 'verdure-plugins-console'});
                    const routers: any[] = [];
                    if (da.constructor === Array) {
                        da && da.forEach((menu: MenuInfo) => {
                            routers.push({
                                id: menu.id,
                                pid: menu.pid,
                                code: menu.code,
                                path: menu.path,
                                name: menu.name,
                                meta: {
                                    hide: menu.hide,
                                    cache: menu.cache,
                                    icon: menu.icon
                                },
                                children: views[menu.code]?.children ||{}
                            })
                        });
                        // 注册路由
                        routers.forEach(rou => {
                            registrySystemRouter(9999, rou);
                        });
                        state.moduleMenu[state.nowModuleId!] = new BuilderRouterTree(routers).buildMenu().menus(state.nowModuleId!);
                        // 重构路由
                        const app = useVerdureApp();
                        app && app.reloadRouter(routers);
                        const loadingView = window.document.getElementById('windowLoading');
                        loadingView && (loadingView.style.display = 'none');
                    }
                    state.loading_initModule = false;
                } catch (e) {
                    console.error(e)
                }
            }).catch(error => {
                state.loading_initModule = false;
            })
        },
        clearLoginInfo() {
            this.$state.modules = null;
            this.$state.nowModuleId = '';
            this.$state.nowExpandMenu = true;
            this.$state.loading_initModule = false;
            this.$state.moduleMenu = {};
            this.$state.cacheViews = [];
        }
    }
})
