import { defineI18nModuleCreator } from "@verdure/core";

export default defineI18nModuleCreator<{
    table: {
        name: string,
    },
    form: {
        name: string,
        permission: string,
        loginDefaultModuleId: string,
        loginDefaultMenuId: string,
        level: string,
    },
    hint: {
        pleaseInputName: string,
        pleaseInputPermission: string,
        bindMenuSuccess: string,
        bindMenuError: string,
        pleaseInputLoginDefaultModuleId: string,
        pleaseLoginDefaultMenuId: string,
        pleaseLevel: string,
    },
    addNew: string,
    editForm: string,
    bindMenu: string,
    submitBindMenu: string,
    clearBind: string,
    noBindMenu: string,
    bindMenuListError: string,
}>('sysRole', {
    "zh-CN": {
        table: {
            name: '名称',
        },
        form: {
            name: '角色名称',
            permission: '权限标识',
            loginDefaultModuleId: '登录默认模块',
            loginDefaultMenuId: '登录默认菜单',
            level: '角色等级',
        },
        hint: {
            pleaseInputName: '请输入角色名称',
            pleaseInputPermission: '请输入权限标识',
            bindMenuSuccess: '为角色绑定菜单成功',
            bindMenuError: '为角色绑定菜单失败',
            pleaseInputLoginDefaultModuleId: '请输入登录后默认加载的模块',
            pleaseLoginDefaultMenuId: '请输入登录后默认加载的菜单',
            pleaseLevel: '请输入角色等级，等级越大，优先级越高',
        },
        addNew: '添加角色',
        editForm: '修改角色',
        bindMenu: '绑定菜单',
        submitBindMenu: '保存绑定',
        clearBind: '清空绑定',
        noBindMenu: '无可绑定的菜单',
        bindMenuListError: '获取角色绑定菜单列表失败',
    },
    "en-US": {
        table: {
            name: 'Name',
        },
        form: {
            name: 'Role Name',
            permission: 'Permission',
            loginDefaultModuleId: 'Logging Default Module',
            loginDefaultMenuId: 'Logging Default Menu',
            level: 'Level',
        },
        hint: {
            pleaseInputName: 'Please enter a role name',
            pleaseInputPermission: 'Please enter the permission',
            bindMenuSuccess: 'Binding the menu to the role succeeded',
            bindMenuError: 'Failed to bind the menu for the role',
            pleaseInputLoginDefaultModuleId: 'Please enter the default module loaded after login',
            pleaseLoginDefaultMenuId: 'Please enter the default menu to be loaded after login',
            pleaseLevel: 'Enter the role level. The larger the role level, the higher the priority',
        },
        addNew: 'Adding roles',
        editForm: 'Modify the role',
        bindMenu: 'Binding menu',
        submitBindMenu: 'Save binding',
        clearBind: 'Empty binding',
        noBindMenu: 'No menu for binding',
        bindMenuListError: 'Failed to get the role binding menu list',
    }
})

