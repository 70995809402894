<template>
    <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
        <el-form-item label="所属区域" label-width="100px">
            <el-cascader :options='regionData' v-model='query.areaCode' :size="size.elementPlus" class="pre100" clearable :props="{
                checkStrictly: true
            }"></el-cascader>
        </el-form-item>
    </el-col>
</template>

<script setup lang="ts">
    import { ref } from 'vue'
    import { exposeUserConfig, ExposeSysUser } from "@verdure/core-web";
    import { regionData, codeToText } from "../../../assets/area/area";
    import {useConfigKey} from "@verdure/core";

    const size = ref(useConfigKey('core').size);

    defineProps<{
        query: any
    }>();

    (exposeUserConfig as ExposeSysUser).beforeInitListData = function(query: any) {
        if (query.value.areaCode) {
            query.value.areaCode = query.value.areaCode.join('-');
        }
    }
</script>

<style>
</style>
