<template>
  <el-config-provider :locale="locale.data">
    <root-router-view></root-router-view>
  </el-config-provider>
  <component
    v-if="viewSlot && viewSlot.any"
    :is="viewSlot.any.component"
  ></component>
</template>

<script setup lang="ts">
import { reactive, Ref, ref } from "vue";
import zhCn from "element-plus/es/locale/lang/zh-cn";
import enUs from "element-plus/es/locale/lang/en";
import { useConfigKey, useModuleViewSlot } from ".";
import { CoreConfig } from "./typings";

const viewSlot: any = useModuleViewSlot("app");

const config: Ref<CoreConfig> = ref(useConfigKey("core"));
const local: Ref<Record<string, any>> = ref({
  "zh-CN": zhCn,
  "en-US": enUs,
});
const locale = reactive({
  data: local.value[config.value.local || "zh-CN"],
});
</script>

<style>
@import "styles/index.scss";
.body {
  user-select: auto;
  overflow-x:hidden;
}
.el-popper {
  z-index: 3500 !important;
}
</style>
