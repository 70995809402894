import {defineRouterCreator} from "@verdure/core";

export default defineRouterCreator({
    swaggerApiDoc: {
        title: 'SwaggerApiDoc',
        type: 'view', // @ts-ignore
        component: () => import('../views/swagger-api-doc/Index.vue'),
        redirect: 'card',
        children: {
            swaggerApiCard: {
                path: 'card',
                name: 'API文档',  // @ts-ignore
                component: () => import('../views/swagger-api-doc/ApiModuleCard.vue'),
            },
            swaggerApiDoc: {
                path: 'api-doc',
                name: 'API列表',  // @ts-ignore
                component: () => import('../views/swagger-api-doc/ApiDoc.vue'),
            }
        }
    },
});
