<template>
    <div>
        <h1>数据字典选择器</h1>
        <el-card shadow="hover">
            <div class="title">基础使用</div>
            <dict-select v-model="demo_1" type="UserState" placeholder="请选择用户状态"></dict-select>
            <el-tag style="margin-top: 15px">选择器选中值：{{ demo_1 }}</el-tag>
        </el-card>
        <br/>
        <el-card shadow="hover">
            <div class="title">启用复选</div>
            <dict-select v-model="demo_2" multiple type="UserState" placeholder="请选择用户状态"></dict-select>
            <el-tag style="margin-top: 15px">选择器选中值：{{ demo_2 }}</el-tag>
        </el-card>
        <br/>
        <el-card shadow="hover">
            <div class="title">属性</div>
            <el-table :data="attr" border size="small">
                <el-table-column prop="name" label="属性名" width="280" align="center"></el-table-column>
                <el-table-column prop="info" label="说明" min-width="180"></el-table-column>
                <el-table-column prop="type" label="类型" width="180" align="center"></el-table-column>
                <el-table-column prop="select" label="可选值" width="280" align="center"></el-table-column>
                <el-table-column prop="default" label="默认值" width="180" align="center"></el-table-column>
            </el-table>
        </el-card>
    </div>
</template>

<script setup lang="ts">
    import { ref, Ref } from 'vue';
    import { initDict } from "@verdure/core";

    initDict(['UserState']);

    const demo_1: Ref<string> = ref('');

    const demo_2: Ref<string[]> = ref([]);

    const attr: Ref<any[]> = ref([
        { name: 'v-model', info: '绑定的值', type: 'any', select: '-', default: '-' },
        { name: 'placeholder', info: '下拉框上显示的提示文本', type: 'string', select: '-', default: '-' },
        { name: 'type', info: '数据字典的类型', type: 'string', select: '-', default: '-' },
        { name: 'multiple', info: '是否启用复选功能', type: 'boolean', select: '-', default: 'false' },
    ])
</script>

<style>
</style>
