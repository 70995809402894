import {defineRouterCreator} from "@verdure/core";

export default defineRouterCreator({
    wePayOrder: {
        title: '微信模块-微信支付订单',
        type: 'view', // @ts-ignore
        component: () => import('../views/pay-order/OrderList.vue'),
    },
    wePayOrderRecord: {
        title: '微信模块-微信支付通知日志',
        type: 'view', // @ts-ignore
        component: () => import('../views/pay-order/OrderNationList.vue'),
    },
    wePayOrderBill: {
        title: '微信模块-微信支付账单',
        type: 'view', // @ts-ignore
        component: () => import('../views/pay-order/OrderPayBillList.vue'),
    },
});
