import { Ref, ref } from 'vue';
import {RouteComponent} from "vue-router";
import {ViewChildren} from "../typings/index";

type Lazy<T> = () => Promise<T>;
type RawRouteComponent = RouteComponent | Lazy<RouteComponent>;
export type View = { // 视图列表
    name: string,
    type?: ViewType,
    scope?: number, // 路由等级 等级大者得 否则 后入者得
    component: RawRouteComponent
    redirect?: string,
    children?: ViewChildren
}

export type ViewType = 'view' | 'layout';

export class Module {
    key: string = '';
    _config: Ref<any> = ref({}); // 配置对象

    __beforeChangeConfig: (config: Record<string, any>) => void = () => {}; // 在改变配置之前执行操作
    __afterChangeConfig: (config: Record<string, any>) => void = () => {};  // 在改变配置之前执行操作

    constructor(key: string) {
        this.key = key;
    }

    /**
     * 改变模块默认配置
     */
    changeConfig<C>(config: C & Record<string, any>): Module {
        this.__beforeChangeConfig(config);
        // this.config(config);
        this.__afterChangeConfig(config);
        return this;
    }

    // /**
    //  * 设置模块配置
    //  * @param config 配置对象
    //  */
    // config<C>(config: C | Record<string, any>): Module {
    //     if (this._config.value === undefined) {
    //         debugger
    //         if (useConfigKey(this.key) === undefined) {
    //             this._config.value = {};
    //         } else {
    //             this._config.value = useConfigKey(this.key)
    //         }
    //     }
    //     useConfig()
    //     debugger
    //     deepCopy(this._config.value, config);
    //     return this;
    // }

}

