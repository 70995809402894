<template>
  <div class="table-card" :style="style">
    <slot name="header" :query="query"></slot>
    <div class="query" v-show="showSearchForm" v-if="!hideQuery">
      <div
        class="card"
        style="
          border: none;
          height: 100%;
          box-shadow: -1px 2px 10px #d3d3d3;
          padding: 12px 35px 12px 15px;
          margin-bottom: 10px;
        "
      >
        <form
          class="form-inline"
          role="form"
          :model="query"
          :size="size.elementPlus"
        >
          <div class="row">
            <slot name="query" :query="query"></slot>
            <div class="col-md-2" style="float: right">
              <button
                type="button"
                class="btn btn-primary"
                :size="size.elementPlus"
                @click="clickSearch"
                style="margin-left: 20px"
              >
                <fa icon="magnifying-glass"></fa>&nbsp;{{
                  $t("tableCard.search")
                }}
              </button>
              <button
                type="button"
                class="btn btn-primary"
                v-if="showMoreSearch"
                :size="size.elementPlus"
                @click="showMoreSearchAlert = true"
                style="margin-left: 10px"
              >
                <fa icon="magnifying-glass-arrow-right" />&nbsp;{{
                  $t("tableCard.moreSearch")
                }}
              </button>
              <button
                type="button"
                class="btn btn-primary"
                v-if="showExport"
                :size="size.elementPlus"
                style="margin-left: 10px"
              >
                <fa icon="magnifying-glass-arrow-right" />&nbsp;{{
                  $t("tableCard.export")
                }}
              </button>
            </div>
            <slot name="query-end" :query="query"></slot>
          </div>
        </form>
      </div>
    </div>
    <div class="tool card">
      <vxe-toolbar ref="xToolbar" custom :size="size.vxeTable">
        <template #buttons>
          <button
            type="button"
            class="btn btn-outline-primary btn-fw"
            v-if="!hideAdd"
            @click="clickAdd"
            :size="size.elementPlus"
            :disabled="disabledAdd"
          >
            <fa icon="plus" />&nbsp;{{ $t("tableCard.add") }}
          </button>
          <slot name="operate" :query="query"></slot>
        </template>
        <template #tools>
          <slot name="tool" :query="query"></slot>
          <div
            class="btn-group"
            role="group"
            aria-label="Basic example"
            :size="size.elementPlus"
          >
            <slot name="tool-item"></slot>
            <button
              type="button"
              v-if="isTree"
              @click="showExpendTree"
              round
              :size="size.elementPlus"
            >
              <span v-if="treeNowIsExpend">{{ $t("tableCard.close") }}</span>
              <span v-else>{{ $t("tableCard.open") }}</span>
            </button>
            <button
              type="button"
              class="btn btn-primary tool-btn"
              v-if="!hideToolQuery"
              @click="clickShowSearchForm"
              round
              :size="size.elementPlus"
            >
              <fa icon="magnifying-glass-arrow-right" />
            </button>
            <button
              type="button"
              class="btn btn-primary tool-btn"
              @click="clickRefresh"
              round
              :size="size.elementPlus"
            >
              <fa icon="rotate" />
            </button>
          </div>
        </template>
      </vxe-toolbar>
    </div>
    <div
      class="card"
      style="
        border: none;
        height: 100%;
        padding: 0 20px 10px 0;
        box-shadow: -1px 2px 10px #d3d3d3;
      "
    >
      <vxe-table
        style="margin: 20px"
        ref="xTable"
        border
        round
        stripe
        show-header-overflow
        :show-overflow="showOverflow"
        :column-config="{
          resizable: true,
        }"
        :size="size.vxeTable"
        :loading="tableLoading"
        :data="tableData"
        @cell-click="clickCellRow"
        @toggle-row-expand="(o: any) => toggleRowExpand(o)"
        class="table-card-scrollbar"
        :row-class-name="rowClassName"
        :row-config="{ isHover: true }"
        :cell-class-name="cellClassName"
        :tree-config="{ rowField: 'id', parentField: 'pid' }"
      >
        <!--单选多选列-->
        <vxe-column :type="checkType" width="50" align="center"></vxe-column>
        <!--序号列-->
        <vxe-column
          v-if="!hideColumnSeq"
          type="seq"
          title="序号"
          width="60"
          align="center"
        ></vxe-column>
        <!--允许外部自定义列-->
        <slot name="column" :query="query"></slot>
        <!--默认的操作列-->
        <vxe-column
          fixed="right"
          max-width="250"
          min-width="150"
          align="center"
          v-if="!hideOperateColumn"
          :title="$t('tableCard.operate')"
        >
          <template #default="{ row }">
            <slot name="column-operate-edit-left" :row="row"></slot>
            <button
              type="button"
              class="btn btn-inverse-primary btn-sm"
              style="background-color: #ffc100; display: inline-block"
              v-if="
                hideColumnEdit.constructor === Boolean
                  ? !hideColumnEdit
                  : hideColumnEdit(row)
              "
              :size="size.elementPlus"
              @click="clickEdit(row)"
            >
              {{ $t("tableCard.edit") }}&nbsp;
              <fa icon="edit" />
            </button>

            <slot name="column-operate-edit-right" :row="row"></slot>
            <div style="margin-top: 5px" v-if="operateColumnBr"></div>
            <el-popconfirm
              :title="$t('tableCard.deleteHint')"
              :confirm-button-text="$t('tableCard.deleteConfirmButtonText')"
              :cancel-button-text="$t('tableCard.deleteCancelButtonText')"
              width="250px"
              @confirm="clickSubmitDelete(row)"
            >
              <template #reference>
                <button
                  type="button"
                  class="btn btn-danger btn-sm"
                  v-if="!hideColumnDelete"
                  :size="size.elementPlus"
                  :disabled="(isTree && row.children.length > 0) || row.isDel"
                >
                  <fa icon="trash" />&nbsp;{{ $t("tableCard.delete") }}
                </button>
              </template>
            </el-popconfirm>
            <slot name="column-operate-delete-right" :row="row"></slot>
          </template>
        </vxe-column>
        <!--当列表中没有数据时-->
        <template v-slot:empty>
          <slot name="empty" v-if="customEmptyText"></slot>
          <template v-else>{{ emptyText }}</template>
        </template>
      </vxe-table>
      <div class="pagination-ilr" v-if="!isTree">
        <el-pagination
          v-model:currentPage="query.page"
          v-model:page-size="query.limit"
          :page-sizes="[10, 20, 30]"
          :small="size.elementPlus"
          background
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
    </div>
    <el-drawer
      v-model="showMoreSearchAlert"
      :show-close="false"
      :size="moreSearchSize"
      :z-index="moreSearchIndex"
    >
      <template #header="{ close, titleId, titleClass }">
        <div :id="titleId" :class="moreSearchHeaderClass">
          <template v-if="customMoreSearchHeader">
            <slot name="more-search-header"></slot>
          </template>
          <span v-else>{{ $t("tableCard.moreSearch") }}</span>
        </div>
      </template>
      <el-scrollbar>
        <slot name="more-search" :query="query"></slot>
      </el-scrollbar>
      <template #footer>
        <button
          type="button"
          class="btn btn-primary"
          :size="size.elementPlus"
          @click="clickSearch"
          style="margin-left: 20px"
        >
          <fa icon="magnifying-glass"></fa>&nbsp;{{ $t("tableCard.search") }}
        </button>
      </template>
    </el-drawer>
  </div>
</template>

<script setup lang="ts">
import {
  ref,
  // defineProps,
  // withDefaults,
  nextTick,
  // defineEmits,
  Ref,
  // defineExpose,
  onMounted,
  watch,
} from "vue";
import {
  VxeColumnPropTypes,
  VxeToolbarInstance,
  VxeTableInstance,
} from "vxe-table";
import { Page, Result } from "../../../entity/Result";
import { $t, useConfigKey, onResize } from "../../..";
import { FormModalInstance } from "../../instance";
import { ElNotification } from "element-plus";
import { filterTree } from "../../../utils";

const size = ref(useConfigKey("core").size);

const emptyText = ref("");

const props = withDefaults(
  defineProps<{
    hideColumnSeq: boolean; // --------------------------------------------------------------- 是否隐藏序号列
    hideColumnCheckInput: boolean; // -------------------------------------------------------- 是否隐藏表格的选择列
    customEmptyText: boolean; // ------------------------------------------------------------- 是否自定义无数据时的表格提示内容
    checkType: VxeColumnPropTypes.Type; // --------------------------------------------------- 行的选择方式
    emptyText: string; // -------------------------------------------------------------------- 空数据提示
    disabledAdd: boolean; // ----------------------------------------------------------------- 禁用添加按钮
    hideAdd: boolean; // --------------------------------------------------------------------- 隐藏添加按钮
    hideOperateColumn: boolean; // ----------------------------------------------------------- 隐藏表格操作列
    hideColumnDelete: boolean; // ------------------------------------------------------------ 隐藏删除按钮
    hideColumnEdit: any; // -------((row: any) => void) | boolean----------------------------- 隐藏编辑按钮
    hideToolQuery: boolean; // --------------------------------------------------------------- 隐藏工具条控制显示隐藏搜索表单的按钮
    isTree: boolean; // ---------------------------------------------------------------------- 是否显示为树形表格
    defaultInit: boolean; // ----------------------------------------------------------------- 是否默认初始化列表数据
    apiListData: (query: Record<string, any>) => Promise<Page<any> | any[]>; // -------------- 列表查询API
    apiDelete: (query: Record<string, any>) => Promise<any>; // ------------------------------ 列表查询API
    filterTableTree: (item: any, query: Record<string, any>) => boolean; // ------------------ 用于树形表格数据过滤
    titleAdd: string; // --------------------------------------------------------------------- 添加弹窗的标题
    titleEdit: string; // -------------------------------------------------------------------- 修改弹窗的标题
    defaultQuery: Record<string, any>; // ---------------------------------------------------- 默认的查询条件
    showOverflow: boolean; // ---------------------------------------------------------------- 表格显示内容是否溢出隐藏
    operateColumnWidth: string; // ----------------------------------------------------------- 操作列宽度
    beforeInitListData: (query: Record<string, any>) => void; // ----------------------------- 列表查询前置操作
    operateColumnBr: boolean; // ------------------------------------------------------------- 操作列操作按钮是否进行换行显示
    showMoreSearch: boolean; // -------------------------------------------------------------- 是否显示更多查询
    showExport: boolean; // ------------------------------------------------------------------ 是否导出按钮
    customMoreSearchHeader: boolean; // ------------------------------------------------------ 是否自定义更多查询弹窗的标题
    moreSearchHeaderClass: string; // -------------------------------------------------------- 更多查询弹窗的头部自定义类名
    moreSearchSize: string; // --------------------------------------------------------------- 更多查询弹窗的大小
    moreSearchIndex: number; // -------------------------------------------------------------- 更多查询弹窗的z-index
    hideQuery: boolean; // ------------------------------------------------------------------- 隐藏查询表单
    rowClassName: ({ row, rowIndex, $rowIndex }: any) => any; // ---------------------------------- 自定义行样式
    cellClassName: ({
      row,
      rowIndex,
      $rowIndex,
      column,
      columnIndex,
      $columnIndex,
    }: any) => any; //自定义单元格样式
    toggleRowExpand: ({
      expanded,
      row,
      rowIndex,
      $rowIndex,
      column,
      columnIndex,
      $columnIndex,
      $event,
    }: any) => any; //展开行事件
  }>(),
  {
    showOverflow: true,
    hideColumnSeq: false,
    disabledAdd: false,
    hideOperateColumn: false,
    hideColumnCheckInput: false,
    customEmptyText: false,
    hideToolQuery: false,
    isTree: false,
    hideAdd: false,
    hideColumnDelete: false,
    hideColumnEdit: false,
    defaultInit: true,
    checkType: "checkbox",
    emptyText: "",
    operateColumnWidth: "400",
    moreSearchHeaderClass: "",
    showMoreSearch: false,
    showExport: false,
    customMoreSearchHeader: false,
    moreSearchSize: "500px",
    moreSearchIndex: 2200,
    hideQuery: false,
    rowClassName: () => "",
    cellClassName: () => "",
  }
);

const emits = defineEmits<{
  (event: "click-add"): void;
  (event: "click-edit", row: any): void;
  (event: "list-data-init-done", data: any[]): void; // ------------------------------------- 列表数据加载完成
  (event: "click-cell", row: any): void; // ------------------------------------------------- 行点击事件
}>();

const xTable = ref({} as VxeTableInstance);
const xToolbar = ref({} as VxeToolbarInstance);
// 表格数据
const tableData: Ref<any[]> = ref([]);
const tableDataCache: Ref<any[]> = ref([]); // 树形搜索会破坏原有数据结构
// 列表总数
const total = ref(0);
// 加载中
const tableLoading = ref(false);
// 显示隐藏搜索表单
const showSearchForm = ref(true);
// 树形表格当前是否展开
const treeNowIsExpend = ref(false);
// 组件样式
const style = ref({
  minHeight: "",
});
// 禁用loading
const loadingDelete: Ref<Record<string, boolean>> = ref({});
// 搜索表单
const query = ref<{
  page?: number;
  limit?: number;
  [key: string]: any;
}>({
  ...props.defaultQuery,
});
// 表单对象的实例
const formModalRef: Ref<FormModalInstance> = ref({} as FormModalInstance);
// 显示更多查询
const showMoreSearchAlert = ref(false);

onMounted(() => {
  computedViewHeight();
  // 注册视图大小改变事件
  onResize(() => {
    computedViewHeight();
  });
});

watch([() => props.emptyText], () => {
  emptyText.value = props.emptyText || $t("tableCard.noData");
});

// 计算视图高度
function computedViewHeight() {
  style.value.minHeight = `${
    (document.documentElement.clientHeight || document.body.clientHeight) - 128
  }px`;
}

// 关联表格与工具栏
nextTick(() => {
  const $table = xTable.value;
  const $toolbar = xToolbar.value;
  $table.connect($toolbar);
  if (props.defaultInit) {
    clickRefresh();
  }
});

/**
 * 单元格点击事件
 */
function clickCellRow(obj: any) {
  emits("click-cell", obj.row);
}

/**
 * 点击添加按钮的事件
 */
function clickAdd() {
  emits("click-add");
  formModalRef.value.showForm && formModalRef.value.showForm(props.titleAdd);
}

/**
 * 点击显示隐藏搜索表单
 */
function clickShowSearchForm() {
  showSearchForm.value = !showSearchForm.value;
}

/**
 * 点击展开或者收起树形表格
 */
function showExpendTree() {
  if (!treeNowIsExpend.value) {
    xTable.value.setAllTreeExpand(true);
  } else {
    xTable.value.clearTreeExpand();
  }
  treeNowIsExpend.value = !treeNowIsExpend.value;
}

/**
 * 点击刷新表格数据
 */
function clickRefresh() {
  if (!props.isTree) {
    query.value.page = 1;
    query.value.limit = 10;
  }
  initListData();
}

/**
 * 每页数量改变事件
 */
function handleSizeChange(size: number) {
  query.value.page = 1;
  query.value.limit = size;
  initListData();
}

/**
 * 页码改变事件
 */
function handleCurrentChange(page: number) {
  query.value.page = page;
  initListData();
}

/**
 * 初始化列表数据
 */
function initListData() {
  tableLoading.value = true;
  const params: Ref<any> = ref(JSON.parse(JSON.stringify(query.value)));
  props.beforeInitListData && props.beforeInitListData(params);
  props.apiListData &&
    props
      .apiListData(params.value)
      .then((data: Page<any> | any[]) => {
        if (props.isTree) {
          tableData.value = data as any[];
          tableDataCache.value = data as any[];
        } else {
          const da: Page<any> = data as Page<any>;
          tableData.value = da.pageList;
          total.value = da.total;
        }
        tableLoading.value = false;
        if (props.isTree) {
          // 默认保持展开或者关闭
          treeNowIsExpend.value = !treeNowIsExpend.value;
          showExpendTree();
        }
        emits("list-data-init-done", tableData.value);
      })
      .catch((error: any) => {
        console.error($t("tableCard.hint.listInitError"), error);
        ElNotification.error($t("tableCard.hint.listInitError") + error.msg);
        tableLoading.value = false;
      });
}

/**
 * 点击删除按钮
 * @param row 删除的数据
 */
function clickSubmitDelete(row: any) {
  if (loadingDelete.value[row.id]) return;
  loadingDelete.value[row.id] = true;
  props.apiDelete &&
    props
      .apiDelete({ id: row.id })
      .then((data: Result<any>) => {
        ElNotification.success($t("tableCard.hint.deleteSuccess"));
        clickRefresh();
        loadingDelete.value[row.id] = false;
      })
      .catch((error: any) => {
        loadingDelete.value[row.id] = false;
        console.log($t("tableCard.hint.deleteError"), error);
        ElNotification.error($t("tableCard.hint.deleteError") + error.msg);
      });
}

/**
 * 点击编辑
 * @param row 编辑
 */
function clickEdit(row: any) {
  emits("click-edit", JSON.parse(JSON.stringify(row)));
  formModalRef.value.showForm && formModalRef.value.showForm(props.titleEdit);
  formModalRef.value.initForm && formModalRef.value.initForm(row);
}

function clickSearch() {
  if (props.isTree) {
    tableData.value = filterTree(
      JSON.parse(JSON.stringify(tableDataCache.value)),
      (item: any) => {
        return props.filterTableTree(item, query.value);
      },
      []
    );
  } else {
    query.value.page = 1;
    initListData();
  }
}

const exposeData = {
  connect: (ref: FormModalInstance): void => {
    formModalRef.value = ref;
    formModalRef.value.connect(exposeData);
  },
  initListData: (que: Record<string, any> = {}): void => {
    query.value = {
      ...query.value,
      ...que,
    };
    clickRefresh();
  },
  getTableData: (): any[] => {
    return tableData.value;
  },
  getCheckBoxSelect() {
    return xTable.value.getCheckboxRecords();
  },
};
defineExpose(exposeData);
</script>

<style lang="scss">
@import "styles/index";

/*表格头右侧的提示按钮*/
.info-cell {
  display: flex;
  align-items: center;
  justify-content: center;

  .info-tb {
    border-radius: 100%;
    border: 1px #666 solid;
    width: 13px;
    height: 13px;
    background: rgba(40, 42, 44, 0.47);
    margin-left: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    svg {
      width: 9px;
      height: 9px;
      fill: white;
      color: white;
    }
  }
}

.pagination-ilr {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}
</style>
