import { defineI18nModuleCreator } from "@verdure/core";

export default defineI18nModuleCreator<{
    name: string,
    addNewDept: string,
    editDept: string,
    deptName: string,
    pid: string,
    pleaseInputName: string,
    pleaseSelectPid: string,
    topDept: string
}>('sysDept', {
    "zh-CN": {
        name: '名称',
        addNewDept: '添加单位',
        editDept: '修改单位',
        deptName: '单位名称',
        pid: '父单位',
        pleaseInputName: '请输入单位名称',
        pleaseSelectPid: '请选择父单位',
        topDept: '顶部菜单'
    },
    "en-US": {
        name: 'Name',
        addNewDept: 'Add dept',
        editDept: 'Modify the dept',
        deptName: 'Dept Name',
        pid: 'Parent Dept',
        pleaseInputName: 'Please enter a dept name',
        pleaseSelectPid: 'Please select the parent dept',
        topDept: 'Top Dept'
    }
})
