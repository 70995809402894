import { Api, Post, BaseApi, Get, Result, Put } from '@verdure/core'
import {LoginUserInfo, MenuInfo, ModuleInfo} from "../entity/Response";

@Api({ url: '/api/system/user' })
@Put({method: 'editPassword', url: '/api/system/user/editPassword'})
@Post({method: 'resetPasswords', url: '/api/system/user/resetPasswords'})
@Get({method: 'userInfo', url: '/api/system/user/userInfo'})
class ApiUser {}
interface ApiUser {
    editPassword(params: any): Promise<any>;
    resetPasswords(params: any): Promise<any>;
    userInfo(): Promise<any>;
}
export const apiUser = new ApiUser() as BaseApi & ApiUser;

@Post({method: 'login', url: '/api/system/auth/login'})
@Get({method: 'getLoginCode', url: '/api/system/auth/code'})
@Get({method: 'modules', url: '/api/system/auth/modules'})
@Get({method: 'menus', url: '/api/system/auth/menus'})
@Get({method: 'info', url: '/api/system/auth/info'})
class ApiAuth {}
// 声明接口类的函数
interface ApiAuth {
    login(params: Record<string, any>): Promise<LoginUserInfo>;
    // 获取用户的模块列表
    modules(): Promise<Record<string, ModuleInfo>>;
    /**
     * 获取模块的菜单
     * @param params 模块ID
     */
    menus(params: { moduleId: string }): Promise<MenuInfo[]>
    // 获取当前登录用户信息
    info(): Promise<any>
    getLoginCode(): Promise<any>
}
export const apiAuth = new ApiAuth() as ApiAuth;

@Api({ url: '/api/system/menu' })
class ApiMenu {}
export const apiMenu = new ApiMenu() as BaseApi;

@Api({ url: '/api/system/dept' })
class ApiDept {}
export const apiDept = new ApiDept() as BaseApi;

@Api({ url: '/api/system/dict' })
@Get({method: 'reload', url: '/api/system/dict/reload'})
class ApiDict {}
interface ApiDict {
    reload(): any;
}
export const apiDict = new ApiDict() as BaseApi & ApiDict;

@Api({ url: '/api/system/dictItem' })
class ApiDictItem {}
export const apiDictItem = new ApiDictItem() as BaseApi;

@Api({ url: '/api/system/role' })
@Get({method: 'menuRelevance', url: '/api/system/role/menuRelevance'})
@Post({method: 'menuRelevanceBind', url: '/api/system/role/menuRelevance'})
class ApiRole {}
// 声明接口类的函数
interface ApiRole {
    // 获取菜单绑定
    menuRelevance(params: Record<string, any>): Promise<number[]>;
    // 绑定菜单
    menuRelevanceBind(params: Record<string, any>): Promise<any>;
}
export const apiRole = new ApiRole() as BaseApi & ApiRole;



