<template>
    <el-form-item label="所属区域" label-width="100px">
        <el-cascader :options='regionData' v-model='form.areaCode' :size="size.elementPlus" class="pre100" clearable :props="{ checkStrictly: true }"></el-cascader>
    </el-form-item>
</template>

<script setup lang="ts">
    import { ref } from 'vue'
    import { exposeUserConfig, ExposeSysUser } from "@verdure/core-web";
    import { regionData, codeToText } from "../../../assets/area/area";
    import {useConfigKey} from "@verdure/core";

    const size = ref(useConfigKey('core').size);

    defineProps<{
        form: any
    }>();

    (exposeUserConfig as ExposeSysUser).beforeSave = function (row: any) {
        if (typeof row.areaCode != 'string') {
            let areaName = []
            for (let i = 0; i < row.areaCode.length; i++) {
                areaName.push(codeToText[row.areaCode[i]])
            }
            row.areaName = areaName.join('-')
            row.areaCode = row.areaCode.join('-');
        }
    };

    (exposeUserConfig as ExposeSysUser).beforeInitForm = function (row: any) {
        if (typeof row.areaCode == 'string') {
            row.areaCode = row.areaCode.split('-')
        }
    }
</script>

<style>
</style>
