import {defineStore} from "pinia";

export default defineStore('dict', {
    state: () => {
        return {
            dict: {},
            labels: {},
            values: {}
        }
    },
    getters: {},
    actions: {}
})
