import {defineRouterCreator} from "@verdure/core";

export default defineRouterCreator({
    Tenant: {
        title: '商户列表',
        type: 'view', // @ts-ignore
        component: () => import('../views/Tenant/TenantList.vue'),
    },
    TenantAudit: {
        title: '商户审核',
        type: 'view', // @ts-ignore
        component: () => import('../views/Tenant/TenantAudit.vue'),
    },
    TenantWarn: {
        title: '商户预警',
        type: 'view', // @ts-ignore
        component: () => import('../views/Tenant/TenantWarnList.vue'),
    },
    Rider: {
        title: '骑手列表',
        type: 'view', // @ts-ignore
        component: () => import('../views/Rider/RiderList.vue'),
    },
    RiderAudit: {
        title: '骑手审核',
        type: 'view', // @ts-ignore
        component: () => import('../views/Rider/RiderAuditList.vue'),
    },
    RiderWarn: {
        title: '骑手预警',
        type: 'view', // @ts-ignore
        component: () => import('../views/Rider/RiderWarnList.vue'),
    },
    Order: {
        title: '订单列表',
        type: 'view', // @ts-ignore
        component: () => import('../views/Order/Index.vue'),
    },
    Equipment: {
        title: '设备列表',
        type: 'view', // @ts-ignore
        component: () => import('../views/Equipment/EquipmentList.vue'),
    },
    WeChatUserList: {
        title: '微信用户列表',
        type: 'view', // @ts-ignore
        component: () => import('../views/User/UserList.vue'),
    },
    PolicyList: {
        title: '政策列表',
        type: 'view', // @ts-ignore
        component: () => import('../views/Policy/PolicyList.vue'),
    },
    PolicyCreate: {
        title: '发布政策',
        type: 'view', // @ts-ignore
        component: () => import('../views/Policy/PolicyCreate.vue'),
    },
    AnnouncementList: {
        title: '公告列表',
        type: 'view', // @ts-ignore
        component: () => import('../views/Announcement/AnnouncementList.vue'),
    },
    AnnouncementCreate: {
        title: '公告发布',
        type: 'view', // @ts-ignore
        component: () => import('../views/Announcement/AnnouncementCreate.vue'),
    },
    PayRecharge: {
        title: '商户用户充值',
        type: 'view', // @ts-ignore
        component: () => import('../views/Tenant/PayRecharge.vue'),
    },
    CutPayment: {
        title: '商户用户扣费',
        type: 'view', // @ts-ignore
        component: () => import('../views/Tenant/CutPayment.vue'),
    },
    Dashboard: {
        title: '通用仪表盘',
        type: 'view', // @ts-ignore
        component: () => import('../views/dashboard/Index.vue'),
    },
    Config: {
        title: '配置',
        type: 'view', // @ts-ignore
        component: () => import('../views/config/Config.vue'),
    },
    FriendlyReminderList: {
        title: '温馨提示',
        type: 'view', // @ts-ignore
        component: () => import('../views/FriendlyReminder/FriendlyReminderList.vue'),
    },
    DashboardPublic: {
        title: '静态通用仪表盘',
        type: 'view', // @ts-ignore
        component: () => import('../views/dashboard/Index.vue'),
        router: {
            path: '/dashboard', pid: '10001'
        }
    },
    Apk: {
        title: 'Apk管理',
        type: 'view', // @ts-ignore
        component: () => import('../views/apk/ListData.vue'),
    },
    PaperSign: {
        title: '商户电子订单',
        type: 'view', // @ts-ignore
        component: () => import('../views/PaperSign/PaperSign.vue'),
    },    
   RiderPaperSign: {
        title: '骑手电子订单',
        type: 'view', // @ts-ignore
        component: () => import('../views/PaperSign/RiderPaperSign.vue'),
    },
    DevelopSupport: {
        title: '技术支持',
        type: 'view', // @ts-ignore
        component: () => import('../views/DevelopSupport/DevelopSupport.vue'),
    },
    PaperSetting: {
        title: '纸签扣费',
        type: 'view', // @ts-ignore
        component: () => import('../views/PaperSetting/PaperSetting.vue'),
    },
    OrderComplain: {
        title: '订单投诉',
        type: 'view', // @ts-ignore
        component: () => import('../views/Complain/orderComplain.vue'),
    },
    MyComplain: {
        title: '封签监督',
        type: 'view', // @ts-ignore
        component: () => import('../views/Complain/myComplain.vue'),
    },
    MySuggest: {
        title: '封签建议',
        type: 'view', // @ts-ignore
        component: () => import('../views/Complain/mySuggest.vue'),
    },
    RiderComplain: {
        title: '骑手投诉',
        type: 'view', // @ts-ignore
        component: () => import('../views/Complain/riderComplain.vue'),
    },
    // JSON: {
    //     title: 'JSON管理',
    //     type: 'view', // @ts-ignore
    //     component: () => import('../views/JSON/JSON.vue'),
    // },
    PosterAudit: {
        title: '海报审核',
        type: 'view', // @ts-ignore
        component: () => import('../views/HuiMin/posterAuditList.vue'),
    },
    PosterList: {
        title: '海报列表',
        type: 'view', // @ts-ignore
        component: () => import('../views/HuiMin/posterList.vue'),
    },
    // PaperFlowAuditList: {
    //     title: '发货列表',
    //     type: 'view', // @ts-ignore
    //     component: () => import('../views/HuiMin/paperFlowAuditList.vue'),
    // },
    // OrderFlowList: {
    //     title: '已发货订单',
    //     type: 'view', // @ts-ignore
    //     component: () => import('../views/HuiMin/OrderFlowList.vue'),
    // },
    MediaAd: {
        title: '媒体广告',
        type: 'view', // @ts-ignore
        component: () => import('../views/HuiMin/mediaAd.vue'),
    },
    Member: {
        title: '会员管理',
        type: 'view', // @ts-ignore
        component: () => import('../views/HuiMin/member.vue'),
    },
    // RiderAdConfig: {
    //     title: '骑手广告',
    //     type: 'view', // @ts-ignore
    //     component: () => import('../views/AdConfig/RiderAd.vue'),
    // },
    // TenantAdConfig: {
    //     title: '商户广告',
    //     type: 'view', // @ts-ignore
    //     component: () => import('../views/AdConfig/TenantAd.vue'),
    // }
    WithDrawList: {
        title: '提现列表',
        type: 'view', // @ts-ignore
        component: () => import('../views/WithDraw/WithDrawList.vue'),
    },
    WithDrawAudit: {
        title: '提现审核',
        type: 'view', // @ts-ignore
        component: () => import('../views/WithDraw/WithDrawAudit.vue'),
    },
    TenantComplain: {
        title: '投诉反馈',
        type: 'view', // @ts-ignore
        component: () => import('../views/Tenant/Complain.vue'),
    },
    TenantStar: {
        title: '五星好评',
        type: 'view', // @ts-ignore
        component: () => import('../views/Tenant/Star.vue'),
    },
    BrightList: {
        title: '明厨亮灶已审核',
        type: 'view', // @ts-ignore
        component: () => import('../views/Bright/BrightList.vue'),
    },
    BrightAudit: {
        title: '明厨亮灶待审核',
        type: 'view', // @ts-ignore
        component: () => import('../views/Bright/BrightAudit.vue'),
    },
    LetterList: {
        title: '承诺书已审核',
        type: 'view', // @ts-ignore
        component: () => import('../views/Bright/LetterList.vue'),
    },
    LetterAudit: {
        title: '承诺书待审核',
        type: 'view', // @ts-ignore
        component: () => import('../views/Bright/LetterAudit.vue'),
    },
});
