import {defineStore} from "pinia";
import {apiAuth} from "../../api/api";
import { registrySystemRouter, useVerdureApp, BuilderRouterTree } from '@verdure/core'
import { OnlineUser } from '../type'

export default defineStore<string, OnlineUser>('onlineUser', {
    state: () => {
        return {
            info: null,
        }
    },
    getters: {
        // 获取登录用户信息
        info: (store: any) => {
            const state: OnlineUser = store.$state;
            if (state.info === null) {
                store.initInfo();
            }
            return store.$state.info;
        },
    },
    actions: {
        setInfo(info: any) {
            const state: OnlineUser = this.$state;
            state.info = info
        },
        async initInfo() {
            const state: OnlineUser = this.$state;
            if (state.info) {
                return;
            }
            apiAuth.info().then(async (data: any) => {
                state.info = data;
            }).catch(error => {
                console.log(error)
            })
        },
        clearLoginInfo() {
            this.$state.info = null;
        }
    }
})
