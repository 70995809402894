<template>
    <div class="display user-box">
        <table-card ref="table"
                    style="width: 100%"
                    hide-column-seq
                    :api-list-data="apiPayOrder.listData"
                    hide-add
                    :default-init="defaultInit"
                    show-more-search
                    hide-operate-column
        >
            <template #query="{ query }">
                <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                    <el-form-item label="商品描述">
                        <el-input v-model="query.description" placeholder="商品描述" clearable></el-input>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                    <el-form-item label="订单ID">
                        <el-input v-model="query.id" placeholder="订单ID" clearable></el-input>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                    <el-form-item label="微信订单ID">
                        <el-input v-model="query.transactionId" placeholder="微信订单ID" clearable></el-input>
                    </el-form-item>
                </el-col>
            </template>
            <template #more-search="{ query }">
                <el-form-item label="预支付时间" label-width="120px">
                    <el-date-picker
                            v-model="query.prepayIdTime"
                            type="date"
                            placeholder="预支付时间"
                            value-format="YYYY-MM-DD"
                            :size="size.elementPlus"
                            class="pre100"
                    ></el-date-picker>
                </el-form-item>
                <el-form-item label="货币类型" label-width="120px">
                    <dict-select v-model="query.amountCurrency" type="WxAmountCurrency" placeholder="货币类型"></dict-select>
                </el-form-item>
                <el-form-item label="交易状态" label-width="120px">
                    <dict-select v-model="query.tradeState" type="WxTradeState" placeholder="交易状态"></dict-select>
                </el-form-item>
                <el-form-item label="交易类型" label-width="120px">
                    <dict-select v-model="query.tradeType" type="WxTradeType" placeholder="交易类型"></dict-select>
                </el-form-item>
                <el-form-item label="支付对账状态" label-width="120px">
                    <dict-select v-model="query.successBill" type="WxPayOrderBillStatus" placeholder="支付对账状态"></dict-select>
                </el-form-item>
                <el-form-item label="支付银行" label-width="120px">
                    <el-select-v2 v-model="query.backType" :size="size.elementPlus" placeholder="支付银行" clearable class="pre100" :options="backJson" filterable></el-select-v2>
                </el-form-item>
                <el-form-item label="微信退款单" label-width="120px">
                    <el-input v-model="query.refundId" placeholder="微信退款单ID" clearable></el-input>
                </el-form-item>
                <el-form-item label="退款状态" label-width="120px">
                    <dict-select v-model="query.refundStatus" type="WxRefundStatus" placeholder="退款状态"></dict-select>
                </el-form-item>
            </template>
            <template #column>
                <vxe-column field="description" title="商品描述" min-width="170"></vxe-column>
                <vxe-column field="prepayIdTime" title="预支付时间" width="150" align="center">
                    <template #default="{ row }">
                        <span v-if="row.prepayIdTime">{{ dayjs(row.prepayIdTime).format('YYYY-MM-DD HH:mm:ss') }}</span>
                    </template>
                </vxe-column>
                <vxe-column field="prepayIdTime" title="金额" width="70" align="center">
                    <template #default="{ row }">
                        <span v-if="row.amountTotal">{{ row.amountTotal / 100 }} 元</span>
                    </template>
                </vxe-column>
                <vxe-column field="amountCurrency" title="货币类型" width="100" align="center">
                    <template #default="{ row }">
                        <dict-tag type="WxAmountCurrency" :code="row.amountCurrency"></dict-tag>
                    </template>
                </vxe-column>
                <vxe-column field="closeTime" title="订单关闭时间" width="150" align="center">
                    <template #default="{ row }">
                        <span v-if="row.closeTime">{{ dayjs(row.closeTime).format('YYYY-MM-DD HH:mm:ss') }}</span>
                    </template>
                </vxe-column>
                <vxe-colgroup title="支付信息" align="center">
                    <vxe-column field="transactionId" title="微信支付订单号" width="180" align="center"></vxe-column>
                    <vxe-column field="tradeType" title="交易类型" width="100" align="center">
                        <template #default="{ row }">
                            <dict-tag type="WxTradeType" :code="row.tradeType"></dict-tag>
                        </template>
                    </vxe-column>
                    <vxe-column field="tradeState" title="交易状态" width="100" align="center">
                        <template #default="{ row }">
                            <dict-tag type="WxTradeState" :code="row.tradeState"></dict-tag>
                        </template>
                    </vxe-column>
                    <vxe-column field="bankType" title="支付银行" width="180" align="center">
                        <template #default="{ row }">
                            <span v-if="row.bankType">{{ backTypeMap[row.bankType] }}</span>
                        </template>
                    </vxe-column>
                    <vxe-column field="paySuccessTime" title="支付时间" width="150" align="center">
                        <template #default="{ row }">
                            <span v-if="row.paySuccessTime">{{ dayjs(row.paySuccessTime).format('YYYY-MM-DD HH:mm:ss') }}</span>
                        </template>
                    </vxe-column>
                    <vxe-column field="payerTotal" title="支付金额" width="100" align="center">
                        <template #default="{ row }">
                            <span v-if="row.payerTotal">{{ row.payerTotal / 100 }} 元</span>
                        </template>
                    </vxe-column>
                    <vxe-column field="payerCurrency" title="支付币种" width="100" align="center">
                        <template #default="{ row }">
                            <dict-tag type="WxAmountCurrency" :code="row.payerCurrency"></dict-tag>
                        </template>
                    </vxe-column>
                </vxe-colgroup>
                <vxe-colgroup title="支付账单信息" align="center">
                    <vxe-column field="successBill" title="对账状态" width="100" align="center">
                        <template #default="{ row }">
                            <dict-tag type="WxPayOrderBillStatus" :code="row.successBill"></dict-tag>
                        </template>
                    </vxe-column>
                    <vxe-column field="payEquipmentCode" title="支付设备号" width="180" align="center"></vxe-column>
                    <vxe-column field="amountPayable" title="应付订单金额" width="120" align="center"></vxe-column>
                    <vxe-column field="voucherPayable" title="代金券金额" width="100" align="center"></vxe-column>
                    <vxe-column field="commission" title="手续费" width="70" align="center"></vxe-column>
                    <vxe-column field="commissionPre" title="手续费费率" width="100" align="center"></vxe-column>
                    <vxe-column field="commissionPreRemark" title="手续费费率备注" min-width="130" align="center"></vxe-column>
                    <vxe-column field="userDataPackage" title="商户数据包" min-width="100" align="center"></vxe-column>
                </vxe-colgroup>
                <vxe-colgroup title="退款信息" align="center">
                    <vxe-column field="refundId" title="微信退款单号" width="180" align="center"></vxe-column>
                    <vxe-column field="refundChannel" title="退款渠道" width="100" align="center">
                        <template #default="{ row }">
                            <dict-tag type="WxRefundChannel" :code="row.refundChannel"></dict-tag>
                        </template>
                    </vxe-column>
                    <vxe-column field="refundStatus" title="退款状态" width="100" align="center">
                        <template #default="{ row }">
                            <dict-tag type="WxRefundStatus" :code="row.refundStatus"></dict-tag>
                        </template>
                    </vxe-column>
                    <vxe-column field="refundApplyTime" title="退款申请时间" width="150" align="center"></vxe-column>
                    <vxe-column field="refundTime" title="退款时间" width="150" align="center"></vxe-column>
                    <vxe-column field="refundCreateTime" title="微信退款创建时间" width="150" align="center"></vxe-column>
                    <vxe-column field="userReceivedAccount" title="退款账户" width="150" align="center">
                        <template #default="{ row }">
                            <span v-if="row.userReceivedAccount">{{ row.userReceivedAccount }}</span>
                        </template>
                    </vxe-column>
                    <vxe-column field="refundAmount" title="退款金额" width="100" align="center">
                        <template #default="{ row }">
                            <span v-if="row.refundAmount">{{ row.refundAmount / 100 }} 元</span>
                        </template>
                    </vxe-column>
                    <vxe-column field="settlementRefund" title="应结退款金额" width="100" align="center">
                        <template #default="{ row }">
                            <span v-if="row.settlementRefund">{{ row.settlementRefund / 100 }} 元</span>
                        </template>
                    </vxe-column>
                    <vxe-column field="discountRefund" title="优惠券退款金额" width="100" align="center">
                        <template #default="{ row }">
                            <span v-if="row.discountRefund">{{ row.discountRefund / 100 }} 元</span>
                        </template>
                    </vxe-column>
                    <vxe-column field="rechargeRefundAmmount" title="充值券退款金额" width="100" align="center">
                        <template #default="{ row }">
                            <span v-if="row.rechargeRefundAmmount">{{ row.rechargeRefundAmmount / 100 }} 元</span>
                        </template>
                    </vxe-column>
                    <vxe-column field="payerRefund" title="实际退款金额" width="100" align="center">
                        <template #default="{ row }">
                            <span v-if="row.payerRefund">{{ row.payerRefund / 100 }} 元</span>
                        </template>
                    </vxe-column>
                    <vxe-column field="refundFee" title="手续费" width="100" align="center">
                        <template #default="{ row }">
                            <span v-if="row.refundFee">{{ row.refundFee / 100 }} 元</span>
                        </template>
                    </vxe-column>
                    <vxe-column field="refundCurrency" title="退款币种" width="100" align="center">
                        <template #default="{ row }">
                            <dict-tag type="WxAmountCurrency" :code="row.refundCurrency"></dict-tag>
                        </template>
                    </vxe-column>
                    <vxe-column field="refundFundsAccount" title="退款资金账户" width="150" align="center"></vxe-column>
                    <vxe-column field="refundCause" title="退款原因" min-width="200"></vxe-column>
                    <vxe-column field="refundReason" title="退款理由" min-width="200"></vxe-column>
                </vxe-colgroup>
                <vxe-column fixed="right" title="操作" width="110" align="center">
                    <template #default="{ row }">
                        <el-button type="primary" :size="size.elementPlus" @click="clickShowRecord(row)">
                            订单日志
                        </el-button>
                    </template>
                </vxe-column>
            </template>
        </table-card>

        <el-drawer
                v-model="orderRecord.show"
                title="订单记录"
                size="70%"
                direction="rtl">
            <el-scrollbar>
                <PayOrderLog ref="payOrderLog"></PayOrderLog>
            </el-scrollbar>
        </el-drawer>
    </div>
</template>

<script setup lang="ts">
    // import { ref, Ref, nextTick, defineProps, withDefaults, defineExpose } from 'vue';
    import { ref, Ref, nextTick } from 'vue';
    import { apiPayOrder } from '../../../api/api'
    import {TableCardInstance, useConfigKey} from "@verdure/core";
    import dayjs from 'dayjs';
    import { backType as backJson} from '../../../assets/data/back'
    import {OrderLogInstance, WechatOrderListInitListParams} from "../../instance";
    // @ts-ignore
    import PayOrderLog from '../../order-log/src/Index.vue'

    const props = withDefaults(defineProps<{
        defaultInit: boolean, // 是否默认加载订单列表数据
    }>(), {
        defaultInit: true
    });

    const size = ref(useConfigKey('core').size);
    const table: Ref<TableCardInstance> = ref({} as TableCardInstance);
    const payOrderLog: Ref<OrderLogInstance> = ref({} as OrderLogInstance);
    const orderRecord: Ref<{
        show: boolean,
        row: any
    }> = ref({
        show: false,
        row: {}
    });
    const backTypeMap: Ref<Record<string, string>> = ref({});
    (backJson as any[]).forEach(item => {
       backTypeMap.value[item.value] = item.label;
    });

    /**
     * 点击日志记录
     * @param row 行
     */
    function clickShowRecord(row: any) {
        orderRecord.value.show = true;
        orderRecord.value.row = row;
        nextTick(() => {
            payOrderLog.value.init(row.id);
        })
    }

    defineExpose({
        initList(obj: WechatOrderListInitListParams) {
            table.value.initListData(obj);
        }
    })
</script>

<style lang="scss">
</style>
