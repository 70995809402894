import { defineI18nModuleCreator } from "@verdure/core";

export default defineI18nModuleCreator<{
    name: string,
    type: string,
    code: string,
    path: string,
    hide: string,
    icon: string,
    cache: string,
    addNewMenu: string,
    editMenu: string,
    menuType: string,
    menuName: string,
    menuCode: string,
    menuPermission: string,
    menuPath: string,
    menuPid: string,
    menuOrderNumber: string,
    menuHide: string,
    menuCache: string,
    pleaseSelectType: string,
    pleaseInputName: string,
    pleaseSelectCode: string,
    pleaseInputPermission: string,
    pleaseInputPath: string,
    pleaseSelectPid: string,
    pleaseInputOrderNumber: string,
    typeModule: string,
    typeMenuGroup: string,
    typeMenu: string,
    typeButton: string,
    topDept: string,
    menuPathNoHove: string,
    selectIcon: string,
    pleaceSelectIcon: string
}>('sysMenu', {
    "zh-CN": {
        name: '名称',
        type: '类型',
        code: '标识',
        path: '页面地址',
        hide: '隐藏',
        icon: '图标',
        cache: '缓存视图',
        addNewMenu: '添加菜单',
        editMenu: '修改菜单',
        menuType: '菜单类型',
        menuName: '菜单名称',
        menuCode: '菜单标识',
        menuPermission: '按钮标识',
        menuPath: '菜单地址',
        menuPid: '父菜单',
        menuOrderNumber: '排序',
        menuHide: '隐藏菜单',
        menuCache: '缓存视图',
        pleaseSelectType: '请选择菜单类型',
        pleaseInputName: '请输入菜单名称',
        pleaseSelectCode: '请选择菜单标识',
        pleaseInputPermission: '请输入按钮标识',
        pleaseInputPath: '请输入页面地址',
        pleaseSelectPid: '请选择父菜单',
        pleaseInputOrderNumber: '请输入排序顺序',
        typeModule: '模块',
        typeMenuGroup: '菜单组',
        typeMenu: '菜单',
        typeButton: '按钮',
        topDept: '顶级菜单',
        menuPathNoHove: '菜单配置的地址不允许包含 “\\”和 “/”',
        selectIcon: '选择图标',
        pleaceSelectIcon: '请选择图标'
    },
    "en-US": {
        name: 'Name',
        type: 'Type',
        code: 'Code',
        path: 'Page Address',
        hide: 'Hide',
        icon: 'Icon',
        cache: 'Cache View',
        addNewMenu: 'Add menu',
        editMenu: 'Modify the menu',
        menuType: 'Menu Type',
        menuName: 'Menu Name',
        menuCode: 'Menu Code',
        menuPermission: 'Button Code',
        menuPath: 'Menu Path',
        menuPid: 'Parent Menu',
        menuOrderNumber: 'Order Number',
        menuHide: 'Hide',
        menuCache: 'Cache View',
        pleaseSelectType: 'Please select the menu type',
        pleaseInputName: 'Please enter a menu name',
        pleaseSelectCode: 'Please select the menu identifier',
        pleaseInputPermission: 'Please enter the button identifier',
        pleaseInputPath: 'Please enter the page address',
        pleaseSelectPid: 'Please select the parent menu',
        pleaseInputOrderNumber: 'Please enter the sort order',
        typeModule: 'Module',
        typeMenuGroup: 'Menu Group',
        typeMenu: 'Menu',
        typeButton: 'Button',
        topDept: 'Top menu',
        menuPathNoHove: 'Menu configuration addresses are not allowed to contain "\\" and "/"',
        selectIcon: 'Select Icon',
        pleaceSelectIcon: 'Please select icon'
    }
})
