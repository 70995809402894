import i18n from './message/i18n'
import system from './message/system'
import tableCard from './message/tableCard'
import formModal from './message/formModal'
import {defineI18nCreate} from "../utils/creator/I18nCreator";

export default defineI18nCreate([
    i18n,
    system,
    tableCard,
    formModal
]);
