import {withInstall} from "../../utils/install";
// @ts-ignore
import TDictSelect from './src/DictSelect.vue'
// @ts-ignore
import TDictRadio from './src/DictRadio.vue'
// @ts-ignore
import TDictTag from './src/DictTag.vue'

TDictSelect.name = 'dict-select';
export const DictSelect = withInstall(TDictSelect);

TDictTag.name = 'dict-tag';
export const DictTag = withInstall(TDictTag);

TDictRadio.name = 'dict-radio';
export const DictRadio = withInstall(TDictRadio);

export default DictSelect;

