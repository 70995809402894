import {
    defineModuleCreator,
} from "@verdure/core";

import router from './router';
import i18n from './i18n';

export default defineModuleCreator<any>({
    id: 'systemMonitoring',
    router,
    i18n
})
