import {VerdureApp} from "../../entity/VerdureApp";
import {
    bubbleSort,
    deepCopy, readRouterConfig, readViewSlot,
    useConfigKey, useStore
} from "../..";
import {
    createRouter,
    createWebHashHistory,
    NavigationFailure,
    NavigationGuardNext,
    RouteLocationNormalized, Router
} from "vue-router";
import {createPinia} from "pinia";
import { createApp } from 'vue';
// @ts-ignore
import AppView from '../../App.vue'
import {VerdureAppCreater} from "../../typings";
import {
    __registryConfig,
    __registryVerdureApp,
    __registryVue,
    __registryVueRouter,
    __useAppCreateDone, __useAppCreateDoneCallback, __useRouterListener, registryPinia
} from "../data/VerdureData";
import coreModule from "../../main";
import {viewChangeCallback} from "../../../../core-web/src/utils/data";

export async function defineVerdureAppCreator(option: VerdureAppCreater) {
    // 导入核心模块主文件
    await import('../../main');
    // 创建实例
    // 创建一个实例
    const app = new VerdureApp();
    // 向全局对象注册应用实例
    __registryVerdureApp(app);
    const router = createRouter({
        history: createWebHashHistory(),
        routes: []
    });
    // 注册路由实例
    __registryVueRouter(router);
    const vue = createApp(AppView);
    // 注册vue实例
    __registryVue(vue);
    // vue实例 创建缓存
    const pinia = createPinia();
    registryPinia(pinia);
    vue.use(pinia);
    // vue实例 创建路由
    vue.use(router);
    // 绑定路由前置后置事件
    bindRouterEach(router);
    // 使用模块，插件
    option.modules && option.modules.forEach(item => app.use(item));
    // 使用插件
    option.plugins && option.plugins.forEach(item => app.use(item));
    // 创建核心模块
    coreModule();
    // 合并客户端配置
    if (option.config) {
        for (let moduleId in option.config) {
            const moduleConfig = useConfigKey(moduleId);
            if (moduleConfig) {
                deepCopy(moduleConfig, option.config[moduleId])
            } else {
                __registryConfig(moduleId, option.config[moduleId])
            }
        }
    }
    // 注册应用路由
    readRouterConfig(option.router);
    // 读取插槽配置并注册
    readViewSlot(option.viewSlot);
    // 执行应用创建完毕的回调
    __useAppCreateDone().forEach(callback => {
        callback()
    });
    // 重载国际化配置
    app.reloadI18n();
    // 重载路由
    app.reloadRouter([]);
    // 执行实例创建完毕的回调
    const callback = __useAppCreateDoneCallback();
    bubbleSort(callback);
    // 触发回调
    for (let index in callback) {
        callback[index].fun();
    }
    app.create('#app');
}


function bindRouterEach(router: Router) {
    const routerListener = __useRouterListener();
    /**
     * 路由后置操作
     */
    router.beforeEach(async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
        const dom: any = document.getElementById("initLoading");
        if (dom) {
            dom.style.display = "flex"
        }
        // 调用订阅者监听器
        const done = routerListener.before.done;
        if (done) {
            for (let i = 0; i < routerListener.before.done.length; i++) {
                const config = routerListener.before.done[i];
                await config.callback(to, from, next);
            }
        }
        next();
    });
    /**
     * 后置路由操作
     */
    router.afterEach(async (to: RouteLocationNormalized, from: RouteLocationNormalized, failure?: NavigationFailure | void) => {
        const done = routerListener.after.done;
        if (done) {
            for (let i = 0; i < done.length; i++) {
                const config = routerListener.after.done[i];
                await config.callback(to, from, failure);
            }
        }
        const dom: any = document.getElementById("initLoading");
        if (dom) {
            dom.style.display = "none"
        }
    });
}
