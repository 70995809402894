import {I18NCreator, I18nModule, Local} from "../../typings";

export function defineI18nCreator<C, I>(option: I18NCreator<C, I>): () => I18NCreator<C, I> {
    return () => option;
}

export function defineI18nCreate(module: Array<I18nModule<any>>): () => I18NCreator<string, any> {
    const option: any = {};
    module.forEach(item => {
        option[item.key] = item.message;
    });
    return () => option;
}

export function defineI18nModuleCreator<I>(key: string, local: Local<I>): I18nModule<I> {
    return {
        key,
        message: local
    }
}
