import { defineI18nModuleCreator } from "@verdure/core";

export default defineI18nModuleCreator<{
    addNew: string,
    editForm: string,
    table: {
        code: string,
        value: string,
        isTag: string,
        tagStyle: string,
    },
    hint: {
        pleaseInputCode: string,
        pleaseInputValue: string,
    },
    color:{
        background: string,
        color: string,
        test: string
    },
    dictTitle: string
}>('sysDictItem', {
    "zh-CN": {
        addNew: '添加字典项',
        editForm: '修改字典项',
        table: {
            code: '标识',
            value: '字典值',
            isTag: '显示为标签',
            tagStyle: '标签样式',
        },
        hint: {
            pleaseInputCode: '请输入项标识',
            pleaseInputValue: '请输入字典值',
        },
        color:{
            background: '背景颜色',
            color: '字体颜色',
            test: '测试文本'
        },
        dictTitle: '字典项'
    },
    "en-US": {
        addNew: 'Adding dictionary items',
        editForm: '',
        table: {
            code: 'Code',
            value: 'Value',
            isTag: 'Show Tag',
            tagStyle: 'Tag Style',
        },
        hint: {
            pleaseInputCode: 'Please enter the item ID',
            pleaseInputValue: 'Please enter a dictionary value',
        },
        color:{
            background: 'Background',
            color: 'Font Color',
            test: 'Test Text',
        },
        dictTitle: 'Dict Items'
    }
})
