import { defineI18nModuleCreator } from "@verdure/core";

export default defineI18nModuleCreator<{
    addNew: string,
    editForm: string,
    table: {
        type: string,
        name: string,
    },
    hint: {
        pleaseInputType: string,
        pleaseInputName: string,
    },
    dictTitle: string,
    reloadDict: string,
    reloadDictSuccess: string,
    reloadDictError: string,
}>('sysDict', {
    "zh-CN": {
        addNew: '添加字典类型',
        editForm: '修改字典类型',
        table: {
            type: '类型标识',
            name: '名称',
        },
        hint: {
            pleaseInputType: '请输入类型标识',
            pleaseInputName: '请输入字典名称',
        },
        dictTitle: '字典类型',
        reloadDict: '重载缓存',
        reloadDictSuccess: '数据字典重载缓存成功',
        reloadDictError: '数据字典重载缓存失败',
    },
    "en-US": {
        addNew: 'Adding a dictionary type',
        editForm: 'Changing the dictionary Type',
        table: {
            type: 'Type Code',
            name: 'Name',
        },
        hint: {
            pleaseInputType: 'Please enter a type identifier',
            pleaseInputName: 'Please enter a dictionary name',
        },
        dictTitle: 'Dict Type',
        reloadDict: 'Reload Cache',
        reloadDictSuccess: 'The data dictionary overloaded the cache successfully',
        reloadDictError: 'The data dictionary failed to reload the cache',
    }
})
