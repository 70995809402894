import { App } from 'vue'
import {
    defineModuleCreator,
} from "@verdure/core";

import 'highlight.js/styles/atom-one-dark.css'
import 'highlight.js/lib/common'
import hljsVuePlugin from '@highlightjs/vue-plugin'

import router from './router/index'

export default defineModuleCreator<any>({
    id: 'expandCodeCreate',
    router,
    enhanceVue(vue: App) {
        vue.use(hljsVuePlugin);
    }
})
