import { Api, Post, BaseApi, Get, Result, Put } from '@verdure/core'

@Get({ method: 'listData', url: '/api/system/wechat/order' })
class ApiPayOrder {}
interface ApiPayOrder {
    // 获取列表数据
    listData(params: Record<string, any>): Promise<any>
}
export const apiPayOrder = new ApiPayOrder();

@Get({ method: 'log', url: '/api/system/wechat/order/log' })
class ApiPayOrderRecord {}
interface ApiPayOrderRecord {
    // 日志
    log(params: Record<string, any>): Promise<any>
}
export const apiPayOrderRecord = new ApiPayOrderRecord();

@Get({ method: 'listData', url: '/api/system/wechat/orderNation' })
class ApiPayOrderNation {}
interface ApiPayOrderNation {
    // 获取列表数据
    listData(params: Record<string, any>): Promise<any>
}
export const apiPayOrderNation = new ApiPayOrderNation();

@Get({ method: 'listData', url: '/api/system/wechat/bill' })
@Get({ method: 'analysis', url: '/api/system/wechat/bill/day/analysis' })
@Get({ method: 'dayBill', url: '/api/system/wechat/bill/day' })
@Get({ method: 'monthStatus', url: '/api/system/wechat/bill/calendar/status' })
class ApiPayBill {}
interface ApiPayBill {
    // 获取列表数据
    listData(params: Record<string, any>): Promise<any>
    // 获取账单文件
    dayBill(params: { day: string, type: string }): Promise<any>
    // 重新解析账单文件
    analysis(params: { day: string, type: string }): Promise<any>
    // 获取指定月份的账单状态
    monthStatus(params: { month: string }): Promise<any>
}
export const apiPayBill = new ApiPayBill();
