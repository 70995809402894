import { AxiosResponse } from 'axios'
import request from './request'
import {Page, Result} from "../entity/Result";

export function get (url: string, params?: any): Promise<Result<any>> {
    return new Promise((res, reg) => {
        request({
            url,
            method: 'GET',
            params
        }).then((data: AxiosResponse) => {
            res(data?.data || {});
        }).catch((error: any) => {
            if (error !== 'ERR_NETWORK') {
                reg(error)
            } else {
                reg({ msg: '接口请求失败：网络超时' });
            }
        })
    })
}

export function post (url: string, data: any): Promise<Result<any>> {
    return new Promise((res, reg) => {
        request({
            url,
            method: 'post',
            data
        }).then((data: AxiosResponse) => {
            res(data?.data || {});
        }).catch((error: any) => {
            if (error !== 'ERR_NETWORK') {
                reg(error)
            } else {
                reg({ msg: '接口请求失败：网络超时' });
            }
        })
    })
}

export function del (url: string, data: any): Promise<Result<any>> {
    return new Promise((res, reg) => {
        request({
            url,
            method: 'delete',
            data
        }).then((data: AxiosResponse) => {
            res(data?.data || {});
        }).catch((error: any) => {
            if (error !== 'ERR_NETWORK') {
                reg(error)
            } else {
                reg({ msg: '接口请求失败：网络超时' });
            }
        })
    })
}

export function put (url: string, data: any): Promise<Result<any>> {
    return new Promise((res, reg) => {
        request({
            url,
            method: 'put',
            data
        }).then((data: AxiosResponse) => {
            res(data?.data || {});
        }).catch((error: any) => {
            if (error !== 'ERR_NETWORK') {
                reg(error)
            } else {
                reg({ msg: '接口请求失败：网络超时' });
            }
        })
    })
}

/**
 * 基础API类型
 */
export interface BaseApi {
    url: string
    listData(query: Record<string, any>): Promise<Page<any>>
    insert(data: Record<string, any>): Promise<any>
    update(data: Record<string, any>): Promise<any>
    delete(query: Record<string, any>): Promise<any>
}

/**
 * 类装饰器，用于声明API接口类
 * @param url 请求地址
 * @constructor
 */
export function Api({ url }: { url: string }) {
    return function enhancer(target: any) {
        // 获取列表数据
        target.prototype.listData = function(query: Record<string, any>) {
            return get(url, query)
        };
        target.prototype.insert = function(data: Record<string, any>) {
            return post(url, data)
        };
        target.prototype.update = function(data: Record<string, any>) {
            return put(url, data)
        };
        target.prototype.delete = function(query: Record<string, any>) {
            return del(url, query)
        };
    }
}

export type ApiAnnoParamsType = {
    url: string,
    method: string
}

function annoApi({ url, method }: ApiAnnoParamsType, type: (url: string, data: any) => Promise<Result<any>>) {
    return function enhancer(target: any) {
        // 获取列表数据
        target.prototype[method] = function(params: Record<string, any>) {
            return type(url, params)
        };
    }
}


/**
 * post接口
 * @param url 接口地址
 * @param method 调用接口的函数
 * @constructor
 */
export function Post({ url, method }: ApiAnnoParamsType) {
    return annoApi({ url, method }, post)
}

/**
 * get接口
 * @param url 接口地址
 * @param method 调用接口的函数
 * @constructor
 */
export function Get({ url, method }: ApiAnnoParamsType) {
    return annoApi({ url, method }, get)
}

/**
 * put接口
 * @param url 接口地址
 * @param method 调用接口的函数
 * @constructor
 */
export function Put({ url, method }: ApiAnnoParamsType) {
    return annoApi({ url, method }, put)
}

/**
 * delete接口
 * @param url 接口地址
 * @param method 调用接口的函数
 * @constructor
 */
export function Delete({ url, method }: ApiAnnoParamsType) {
    return annoApi({ url, method }, del)
}
