import { NOOP } from '@vue/shared'
import { Plugin } from 'vue'
import { guid } from '.'

export type SFCWithInstall<T> = T & Plugin

/**
 * 注册组件
 * @param main 主组件
 * @param extra 额外的组件
 */
export const withInstall = <T, E extends Record<string, any>>(
    main: T,
    extra?: E
) => {
    (main as SFCWithInstall<T>).install = (app): void => {
        for (const comp of [main, ...Object.values(extra || {})]) {
            app.component(comp.name, comp)
        }
    };

    if (extra) {
        for (const [key, comp] of Object.entries(extra)) {
            (main as any)[key] = comp
        }
    }
    return main as SFCWithInstall<T> & E
};

export const withInstallFunction = <T>(fn: T, name: string) => {
    (fn as SFCWithInstall<T>).install = (app) => {
        app.config.globalProperties[name] = fn
    };

    return fn as SFCWithInstall<T>
};

export const withNoopInstall = <T>(component: T) => {
    (component as SFCWithInstall<T>).install = NOOP;

    return component as SFCWithInstall<T>
};


