import { defineI18nModuleCreator } from "@verdure/core";

export default defineI18nModuleCreator<{
    table: {
        detail: string,
        code: string,
        source: string,
        className: string,
        method: string,
        contentType: string,
        locale: string,
        requestUrl: string,
        serverName: string,
        apiType: string,
        ip: string,
        time: string,
        resultCode: string,
        resultStatus: string,
        message: string
    }
}>('requestLog', {
    "zh-CN": {
        table: {
            detail: '操作说明',
            code: '操作类型',
            source: '操作来源',
            className: '请求类',
            method: '方法名称',
            contentType: '请求类型',
            locale: '语言',
            requestUrl: '请求地址',
            serverName: '服务IP',
            apiType: '请求类型',
            ip: 'IP',
            time: '时长',
            resultCode: '状态码',
            resultStatus: '状态码II',
            message:'异常信息'
        }
    },
    "en-US": {
        table: {
            detail: 'Title',
            code: 'Type',
            source: 'Source',
            className: 'Class',
            method: 'Method',
            contentType: 'Request Type',
            locale: 'Locle',
            requestUrl: 'Request URL',
            serverName: 'Server Name',
            apiType: 'Api Type',
            ip: 'IP',
            time: 'Time',
            resultCode: 'Code',
            resultStatus: 'Code II',
            message: 'Error Message',
        }
    }
})
