import { defineI18nModuleCreator } from "@verdure/core";

export default defineI18nModuleCreator<{
    deptNoData: string,
    addNew: string,
    editForm: string,
    table: {
        name: string,
        account: string,
        simulationAccount: string,
        phone: string,
    },
    form: {
        name: string,
        dept: string,
        email: string,
        role: string,
    },
    hint: {
        pleaseInputName: string,
        pleaseInputDept: string,
        pleaseInputRole: string,
        pleaseInputEmail: string,
        pleaseInputSimulationAccount: string,
        pleaseInputPhone: string
    }
}>('sysUser', {
    "zh-CN": {
        deptNoData: '暂无可选单位',
        addNew: '添加用户',
        editForm: '修改用户',
        table: {
            name: '名称',
            account: '账号',
            simulationAccount: '模拟账号',
            phone: '手机号',
        },
        form: {
            name: '用户名称',
            dept: '单位',
            email: '邮箱',
            role: '角色',
        },
        hint: {
            pleaseInputName: '请输入用户名称',
            pleaseInputDept: '请选择所在部门',
            pleaseInputRole: '请选择所拥有的角色',
            pleaseInputEmail: '请输入邮箱',
            pleaseInputSimulationAccount: '请输入模拟账号，模拟账号可替换系统生成账号用于登录',
            pleaseInputPhone: '请输入手机号（手机号可用于登录）'
        }
    },
    "en-US": {
        deptNoData: 'No units are available',
        addNew: 'Add User',
        editForm: 'Modify the user',
        table: {
            name: 'Name',
            account: 'Account',
            simulationAccount: 'Simulation Account',
            phone: 'Phone',
        },
        form: {
            name: 'User Name',
            dept: 'Dept',
            email: 'Email',
            role: 'Role',
        },
        hint: {
            pleaseInputName: 'Please enter a user name',
            pleaseInputDept: 'Please select your department',
            pleaseInputRole: 'Please select the role you have',
            pleaseInputEmail: 'Please enter email address',
            pleaseInputSimulationAccount: 'Please enter the simulated account. The simulated account can replace the account generated by the system for login',
            pleaseInputPhone: 'Please enter mobile phone number (Mobile phone number can be used for login)'
        }
    }
})

