import {defineI18nModuleCreator} from "../../utils/creator/I18nCreator";

export default defineI18nModuleCreator<{
    'zh-CN': string,
    'en-US': string,
}>('i18n', {
    "zh-CN": {
        'zh-CN': '中文',
        'en-US': '英文',
    },
    "en-US": {
        'zh-CN': 'Chinese',
        'en-US': 'English',
    }
})
