import { Ref, UnwrapRef, unref } from 'vue'
import { ElMessage } from 'element-plus'
import JSEncrypt from 'jsencrypt'
import {useConfig, useI18nData} from "..";

/**
 * 判断是否为空
 * @param v
 */
export function isNull(v: string) {
    return v === undefined || v === null || v === ''
}

/**
 * 生成UUID
 * @returns UUID
 */
 export const guid = function () {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random()*16|0, v = c == 'x' ? r : (r&0x3|0x8);
        return v.toString(16);
    });
};

/**
 * 进行文本加密
 * @param txt 文本
 * @param publicKey 公钥
 */
export const encrypt = (txt: string, publicKey: string) => {
    const encryptor = new JSEncrypt();
    encryptor.setPublicKey(publicKey); // 设置公钥
    return encryptor.encrypt(txt) // 对需要加密的数据进行加密
};

/**
 * 进行文本加密
 * @param txt 文本
 * @param publicKey 公钥
 */
export function decrypt(txt: string) {
    const encryptor = new JSEncrypt()
    encryptor.setPrivateKey("MIIBVgIBADANBgkqhkiG9w0BAQEFAASCAUAwggE8AgEAAkEAm7+CZyxR/IEeHvyvJdnog+w1jgXGHhhbMgfp7MPIblcqGE6ks4R8Bd/tKm8p/aeOYgFWksPRy4defaw3bpNT7QIDAQABAkBbeACEyVGpIsdm2W2fpDda6umU7C0rVZCRdHZcl6rpqZqhfa4f3eG7WXTVcYarrtiwv00I8tTkVXjg9KA2efcdAiEAzo1Ma9l/QWCl1ooXRS63Hh6aqZfGibSxzrfaLg5/JWsCIQDBCKfH6poZnR/aySPEZTo5myNF+/D/b700DJRrFpFqBwIhAIuShWp3TPtjkIx5QaI5H/oVWhNxgPI6jNAQAjmPCDHDAiEAjGJtEvlNJP+eYRETEAvGcoqe9pCpzjtHUIktj0wUaRUCIQCqwnuSJVzVs8ekYsCHFIlefIvOjN5xFtAjvQEyfCnnsA==") // 设置私钥
    return encryptor.decrypt(txt) // 对数据进行解密
  }



/**
 *
 * @param object 判断对象是否包含某个属性，如果不包含则初始化
 * @param key 属性的key
 * @param value 初始化的值
 */
export const isNullAndNew = (object: any, key: string, value: any) => {
    if (isNull(object[key])) {
        object[key] = value;
    }
};

/**
 * 进行表单校验
 * @param ruleFormRef 表单ref
 * @returns
 */
export const submitForm = (ruleFormRef: Ref<UnwrapRef<null>>): Promise<any> | null => {
    const form: any = unref(ruleFormRef);
    if (!form) return null;
    return new Promise((resolve, reject) => {
        form.validate((valid: boolean) => {
            if (valid) {
                resolve(true);
            } else {
                ElMessage({message: '请检查输入是否有误', type: 'warning',});
                reject(false);
            }
        })
    })
};

/**
 * 深度拷贝
 * @param target 目标
 * @param source 源
 */
export function deepCopy(target: any, source: any) {
    if (source.constructor === Object) {
        for (let key in source) {
            if (source[key]) {
                const item = JSON.parse(JSON.stringify(source[key]));
                if (item.constructor === Object || typeof item === 'object') {
                    if (target[key] === undefined) {
                        target[key] = {};
                    }
                    deepCopy(target[key], item)
                } else {
                    target[key] = source[key];
                }
            }
        }
    }
}

/**
 * 树形结构过滤
 * @param tree 树
 * @param fun 过滤函数
 * @param arr 数组
 */
export function filterTree(tree: any[], fun: (item: any) => boolean, arr: any[] = []) {
    if (!tree.length) return [];
    for (let item of tree) {
        // 实例化一个新的节点
        let node = {...item};
        // 如果有子节点，则追加
        if (item.children && item.children.length) {
            const children: any[] = [];
            filterTree(item.children, fun, children);
            if (children.length > 0) {
                node.children = children;
                arr.push(node)
            }
        } else {
            // 如果没有子节点
            if (fun(item)) {
                node.children = [];
                arr.push(node)
            }
        }
    }
    return arr
}

export function bubbleSort (arr: { scope: number, [key: string]: any }[]) {
    var max = arr.length - 1;
    for (var j = 0; j < max; j++) {
        // 声明一个变量，作为标志位
        var done = true;
        for (var i = 0; i < max - j; i++) {
            if (arr[i].scope > arr[i + 1].scope) {
                var temp = arr[i];
                arr[i] = arr[i + 1];
                arr[i + 1] = temp;
                done = false;
            }
        }
        if (done) {
            break;
        }
    }
    return arr;
}

/**
 * 读取配置写入I18N中
 * @param config 配置
 * @param keyName 配置的key
 * @param i18nModuleKey i18n模块key
 */
export function readConfigToI8n(config: Record<string, any>, keyName: string, i18nModuleKey: string) {
    const { core } = useConfig();
    const i18nData: any = useI18nData();
    // 解析登录页系统名称
    const value = config[keyName];
    if (typeof value === 'string') {
        // 如果是字符串忙活去当前的系统语言
        const local = core.local;
        isNullAndNew(i18nData.system.message, local, {});
        i18nData[i18nModuleKey].message[local][keyName] = value; // 修改国际化数据
    } else {
        for (let local in value) {
            isNullAndNew(i18nData.system.message, local, {});
            i18nData[i18nModuleKey].message[local][keyName] = value[local]; // 修改国际化数据
        }
    }
}

/**
 * 检测密码等级
 * @param password
 * @constructor
 */
export function AnalyzePasswordSecurityLevel(password: string) {
    var securityLevelFlag = 0;
    if (password.length < 6) {
        return 0;
    }
    else {
        if (/[a-z]/.test(password)){
            securityLevelFlag++;    //lowercase
        }
        if (/[A-Z]/.test(password)){
            securityLevelFlag++;    //uppercase
        }
        if(/[0-9]/.test(password)){
            securityLevelFlag++;    //digital
        }
        if(containSpecialChar(password)){
            securityLevelFlag++;    //specialcase
        }
        return securityLevelFlag;
    }
}
function containSpecialChar(str: string) {
    var containSpecial = RegExp(/[(\ )(\~)(\!)(\@)(\#)(\$)(\%)(\^)(\&)(\*)(\()(\))(\-)(\_)(\+)(\=)(\[)(\])(\{)(\})(\|)(\\)(\;)(\:)(\')(\")(\,)(\.)(\/)(\<)(\>)(\?)(\)]+/);
    return (containSpecial.test(str));
}
