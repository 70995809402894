import {VerdureRouter} from "../entity/VerdureApp";
import {RouteRecordRaw} from "vue-router";
import {useViews} from "..";
import {registryChildrenRouterPath} from "./data/VerdureData";

// 构建路由树
export default class BuilderRouterTree {
    source: VerdureRouter[];
    pidCache: Record<string, RouteRecordRaw[]> = {}; // 更据pid缓存

    constructor(source: VerdureRouter[]) {
        this.source = source;
    }

    /**
     * 根据pid获取菜单
     * @param pid pid
     */
    menus(pid:string): RouteRecordRaw[] {
        return this.pidCache[pid];
    }

    build(): RouteRecordRaw[] {
        return this.buildMenu().pidCache["0"];
    }

    buildMenu(): BuilderRouterTree {
        this.source.forEach(item => {
            if (this.pidCache[item.pid] === undefined) {
                this.pidCache[item.pid] = []; // 初始化
            }
            let component = useViews()[item.code];
            if (!component) {
                component = useViews().error404;
            }
            const name = item.name || component.name;
            const rou: any = {
                path: item.path,
                component: component.component,
                children: [],
                name: item.name,
                meta: {
                    ...item.meta,
                    name,
                    id: item.id,
                    pid: item.pid
                }
            };
            registryChildrenRouterPath(item.code, item.name || '', item.path, '');
            if (component.redirect) {
                rou.redirect = item.path + '/' + component.redirect;
            }
            this.pidCache[item.pid].push(rou);
            if (item.children && Object.values(item.children).length > 0) {
                rou.meta.view = true; // 声明它是个视图
                this.pidCache[item.id] = [];
                for (let key in item.children) {
                    // 缓存路由
                    const it = item.children[key];
                    registryChildrenRouterPath(key, it.name, it.path, item.path);
                    // 追加路由
                    this.pidCache[item.id].push({
                        path: item.path + '/' + (it.path || key),
                        component: it.component,
                        children: [],
                        name: it.name,
                        meta: {
                            nop: true, // 不校验菜单权限，但是需要鉴定授权情况
                            ...it.meta,
                            name: it.name,
                            pid: item.id
                        }
                    });
                }
            }
        });
        for (let pid in this.pidCache) {
            this.pidCache[pid].forEach(item => {
                const children = this.pidCache[item.meta!.id as number];
                if (children) {
                    item.children = children;
                }
            })
        }
        return this
    }
}
