import {defineRouterCreator} from "@verdure/core";

export default defineRouterCreator({
    apiRequestLog: {
        title: '接口请求日志',
        type: 'view', // @ts-ignore
        component: () => import('../views/requestLog/Index.vue'),
    },
    timeTask: {
        title: '定时任务调度',
        type: 'view', // @ts-ignore
        component: () => import('../views/timeTask/Index.vue'),
    },
    systemConfig: {
        title: '系统配置',
        type: 'view', // @ts-ignore
        component: () => import('../views/systemConfig/Index.vue'),
    },
});
