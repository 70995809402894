import {defineViewSlotCreator} from "@verdure/core";
// @ts-ignore
import DictSelect from './component-demo/DictSelect.vue'

export default defineViewSlotCreator({
    'component-demo': {
        dictSelect: {
            name: '数据字典选择器',
            component: DictSelect
        }
    }
})
