import axios, {AxiosRequestConfig, AxiosResponse} from 'axios'
import {ElMessageBox, ElNotification} from 'element-plus'
import {isNullAndNew} from ".";
import {useConfigKey, useStore, useVueRouter} from "..";
import {__useAxiosBeforeApiConfig} from "./data/VerdureData";

// 创建axios实例
const service = axios.create({
    timeout: 30000 // 请求超时时间
});
// request拦截器
service.interceptors.request.use(
    (configAxios: AxiosRequestConfig) => {
        const core = useConfigKey('core');
        // @ts-ignore
        configAxios.baseURL = core.request?.baseUrl; // api 的 base_url
        isNullAndNew(configAxios, 'headers', {});
        if (configAxios.headers) {
            configAxios.headers['content-type'] = 'application/json; charset=utf-8' ;
            configAxios.headers.Authorization = `Bearer ${window.localStorage.getItem(core.localStorePrefix + 'token')}`;
        }
        // 触发回调
        __useAxiosBeforeApiConfig().forEach(item =>{
            item(configAxios);
        })
        return configAxios;
    },
    (error: any) => {
        Promise.reject(error);
    }
);

// response 拦截器
service.interceptors.response.use(
    (response: AxiosResponse) => {
        // 先判断HTTP请求的状态码
        const code = response.status;
        if (code !== 200) {
            return Promise.reject(response.data)
        }
        const {data} = response;
        if (data.code === '000000') {
            return data
        } else if (data.code === 'B00001') {
            if (data.status === '001') {
                // @ts-ignore
                const router = useVueRouter();
                const route: any = router.currentRoute.value || router.currentRoute;
                if (location.hash !== '#/login') {
                    const {core}: any = useStore();
                    if (!core.showToLogin) {
                        core.showToLogin = true;
                        router.replace('/login')
                    }
                }
            } else {
                return Promise.reject(data)
            }
        } else {
            return Promise.reject(data)
        }
    },
    (error: any) => {
        if (error.code === "ERR_NETWORK") {
            console.error(`接口请求超时：${error.config.baseURL}${error.config.url}`);
            return Promise.reject('ERR_NETWORK')
        }
        return Promise.reject(error)
    }
);

export default service
