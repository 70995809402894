<template>
    <div class="timeline-pay-log">
        <el-timeline>
            <el-timeline-item
                    v-for="(item, index) in res"
                    :key="index"
                    :timestamp="item.time"
            >
                <div class="title">{{ item.title }}</div>
                <json-viewer v-if="item.type !== 'TITLE'" :json="item" height="auto"></json-viewer>
            </el-timeline-item>
        </el-timeline>
    </div>
</template>

<script lang="ts" setup>
    // import { ref, Ref, defineExpose } from 'vue'
    import { ref, Ref } from 'vue'
    import {apiPayOrderRecord} from "../../../api/api";
    import { ElNotification } from 'element-plus'

    const res: Ref<any[]> = ref([]);

    defineExpose({
        init(id: string): void {
            apiPayOrderRecord.log({id}).then(data => {
                if (data.constructor === String) {
                    const rows = data.split('\n');
                    rows.forEach(item => {
                        if (item) {
                            try {
                                const re = JSON.parse(item);
                                re.data && (re.data = JSON.parse(re.data))
                                item && res.value.push(re)
                            } catch (e) {
                                res.value.push({ TYPE: 'EI', text: item + "" })
                            }
                        }
                    })
                }
            }).catch(error => {
                console.error('订单日志加载失败', error);
                ElNotification.error('订单日志加载失败：' + error.msg);
            })
        }
    })
</script>

<style scoped lang="scss">
    .timeline-pay-log {
        .title {
            font-size: 18px;
            font-weight: bold;
        }
    }
</style>
