<template>
    <div class="verdure-image" :class="preview ? 'view' : ''" @click="clickShowPreview">
        <template v-if="src">
            <img v-if="initError" class="now-img" :src="src" :crossorigin="crossorigin" @error="initImageError"/>
            <div v-else class="error">{{ errorImage }}</div>
        </template>
        <div v-else class="error">{{ noImage }}</div>
    </div>
</template>

<script setup lang="ts">
    import { ref } from 'vue'

    const props = withDefaults(defineProps<{
        src: string,
        noImage: string
        errorImage: string,
        preview: boolean,
        zIndex: number,
        crossorigin: "" | 'anonymous' | "use-credentials" | undefined
    }>(), {
        noImage: '暂无图片',
        errorImage: '加载失败',
        preview: true,
        zIndex: 3000,
        crossorigin: 'anonymous' as any
    });

    const initError = ref(true);
    let nowPreview = false;
    let nowPreviewId: any = null;

    function initImageError() {
        initError.value = false
    }

    function clickShowPreview() {
        if (props.preview) {
            if (nowPreview) {
                return
            }
            nowPreview = true;
            let verdureBox = document.getElementById("verdure-box");
            if (!verdureBox) {
                verdureBox = document.createElement('div');
                verdureBox.id = 'verdure-box';
                document.getElementsByTagName("body")[0].appendChild(verdureBox);
            }
            if (nowPreviewId == null) {
                nowPreviewId = new Date().getTime();
            }
            const id = `v-img-${nowPreviewId}`;
            let nowImage: any = document.getElementById(id);
            // if (!nowImage) {
                nowImage = document.createElement('div');
                nowImage.id = id;
                nowImage.style.zIndex = `${props.zIndex}`;
                nowImage.className = 'verdure-image-preview';
                verdureBox.appendChild(nowImage);
                const img: any = document.createElement('img');
                img.src = props.src;
                img.crossOrigin = props.crossorigin;
                img.className = 'image';
                nowImage.appendChild(img);
                const close = document.createElement('div');
                close.className = 'close';
                close.innerText = "×";
                for (let i = 0; i < document.getElementsByClassName('el-popper is-light el-popover').length; i++) {
                    document.getElementsByClassName('el-popper is-light el-popover')[i]['style'].display = 'none'
                }
                close.onclick = function() {
                    nowImage.style.display = 'none';
                    nowPreview = false;
                };
                nowImage.appendChild(close);
            // } else {
            //     nowImage.style.display = 'block';
            // }
        }
    }

</script>

<style lang="scss">
    .verdure-image {
        position: relative;
        background-color: rgba(200, 200, 200, .1);
        display: flex;
        align-items: center;
        justify-content: center;
        .image-own {
            z-index: 999
        }
        &.view {
            .now-img {
                cursor: pointer;
            }
        }
        .now-img {
            width: 95%;
            height: 95%;
            object-fit: contain;
        }
        .error {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #999;
        }
    }
    .verdure-image-preview {
        position: absolute;
        width: 100%;
        height: 100vh;
        background-color: rgba(100, 100, 100, .7);
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 99;
        .image {
            width: 95%;
            height: 95%;
            object-fit: contain;
        }
        .close {
            position: absolute;
            top: 20px;
            right: 40px;
            font-size: 39px;
            color: #FFF;
            border-radius: 100%;
            border: 3px #FFF solid;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            height: 40px;
            cursor: pointer;
            &:hover {
                color: red;
                border-color: red;
            }
        }
    }
</style>
