import { defineI18nModuleCreator } from "@verdure/core";

export default defineI18nModuleCreator<{
  login: string;
  loadingLogin: string;
  account: string;
  pleaseInputAccount: string;
  password: string;
  pleaseInputPassword: string;
  rememberPassword: string;
  code: string;
  pleaseInputCode: string;
}>("login", {
  "zh-CN": {
    login: "登录",
    loadingLogin: "登录中",
    account: "账号",
    pleaseInputAccount: "请输入账号",
    password: "密码",
    pleaseInputPassword: "请输入密码",
    rememberPassword: "记住密码",
    code: "验证码",
    pleaseInputCode: "请输入验证码",

  },
  "en-US": {
    login: "Login",
    loadingLogin: "Loading Login",
    account: "Account",
    pleaseInputAccount: "Please enter account number",
    password: "Password",
    pleaseInputPassword: "Please enter your password",
    rememberPassword: "Remember Password",
    code: "verification Code",
    pleaseInputCode: 'Please enter verification code'
  },
});
