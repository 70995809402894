import login from './message/login'
import system from './message/system'
import layout from './message/layout'
import sysMenu from './message/sysMenu'
import sysDept from './message/sysDept'
import sysRole from './message/sysRole'
import sysUser from './message/sysUser'
import sysDict from './message/sysDict'
import sysDictItem from './message/sysDictItem'
import userCenter from './message/userCenter'
import {defineI18nCreate} from "@verdure/core";

export default defineI18nCreate([
    login,
    system,
    layout,
    sysMenu,
    sysDept,
    sysRole,
    sysUser,
    sysDict,
    sysDictItem,
    userCenter,
]);










