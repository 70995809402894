import {defineRouterCreator} from "../utils/creator/RouterCreator";

export default defineRouterCreator({
    view: {
        title: '空白视图', /* @ts-ignore */
        component: () => import('../views/View.vue'),
        type: 'layout',
    }
});

