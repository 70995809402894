import {defineI18nModuleCreator} from "../../utils/creator/I18nCreator";

export default defineI18nModuleCreator<{
    selectLocal: string,
    systemName: string
}>('system', {
    "zh-CN": {
        selectLocal: '选择语言',
        systemName: '电子“食安封签”数据管理平台'
    },
    "en-US": {
        selectLocal: 'Select Language',
        systemName: 'Verdure Framework'
    }
})
