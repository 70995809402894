import {RouteLocationNormalized} from "vue-router";
import { ref } from 'vue';
import {
    useSystemRouterMap,
    useStore,
    useVerdureApp,
    defineModuleCreator,
} from "@verdure/core";

import i18n from './i18n/index'
import store from './store/index'
import router from './router/index'
import {viewChangeCallback} from "./utils/data";

import viewSlot from './slot/index'
import {WebConfig} from "./typings/index";

export default defineModuleCreator<WebConfig>({
    id: 'coreSystem',
    i18n,
    store,
    router,
    viewSlot,
    routerBeforeEach: {
        callback: async (to: RouteLocationNormalized, from: RouteLocationNormalized) => {
            const {menu, onlineUser}: any = useStore();
            viewChangeCallback.value.forEach(item => item(true));
            if (to.path !== '/login') {
                menu && await menu.initModule();
                onlineUser && onlineUser.initInfo();
            }
        }
    },
    routerAfterEach: () => {
        window.setTimeout(() => {
            viewChangeCallback.value.forEach(item => item(false));
        }, 100)
    }
});

export * from './expose'
