<template>
    <div class="local-select">
        <el-dropdown @command="command">
            <span class="text">
                {{ $t('system.selectLocal') }}：{{ message[local].i18n[local] }}
            </span>
            <template #dropdown>
                <el-dropdown-menu>
                    <el-dropdown-item v-for="(item, key) in selects" :command="key">{{ item }}</el-dropdown-item>
                </el-dropdown-menu>
            </template>
        </el-dropdown>
    </div>
</template>

<script setup lang="ts">
    import { ref, Ref } from 'vue'
    import {useI18n, $t, useConfigKey} from "../../..";
    import {CoreConfig} from "../../../typings";

    const config: Ref<CoreConfig> = ref(useConfigKey('core'));
    const i18n = useI18n();
    const local = ref(i18n.getLocale());
    if (config.value.local && local.value != config.value.local) {
        // 当前切换的与默认的不一致
        i18n.setLocale(config.value.local);
        local.value = i18n.getLocale();
    }

    const message = i18n.messages;
    const selects = ref(message[local.value].i18n);

    function command(commend: string) {
        i18n.setLocale(commend);
        local.value = commend;
        selects.value = message[local.value].i18n;
        window.localStorage.setItem(config.value.localStorePrefix + 'local', commend);
        config.value.local = commend;
    }
</script>

<style lang="scss" scoped>
    .text {
        font-size: 14px;
        cursor: pointer;
    }
</style>
